import { Box, Button, Paper } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import React, { useState,useEffect} from "react"
import { Col, Container, Row } from "react-grid-system"
import agiStyles from "../../styles"
import cardStyles from "../../styles/CardStyles"
import measurementControlStyles from "../../styles/MeasurementControlStyles"
import MeasurementResultRes from "./MeasurementResultRes"
import MeasurementSettings, { defaultData } from "./MeasurementSettings"
import { PubSub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import { saveAs } from "file-saver";

const MeasurementControl = ({ selectedThing,props }) => {
 
  const styles = measurementControlStyles()
  const stylesAGI = agiStyles()
  const stylesCard = cardStyles()
  const [advancedSettings, setAdvancedSettings] = useState(false)
  const [settings, setSettings] = useState(defaultData)
  const [measurementId, setMeasurementId] = useState(1)
  const [msg, setMsg] = useState({});
  const [messages, setMessages] = useState([]);
  
  let topicToPublish = "devices/sn01231BF5A00ED84A01/commands";
  let topicToSubscribe = "devices/sn01231BF5A00ED84A01/responses";

  let payload = {
    "header": {
      "type": "startMeasurement",
      "apiVersion": 1.0,
      "datetime": "2021-11-11T15:26:06.076Z",
      "source": {
        "id": "webApp",
        "serialNumber": "0123456789"
      },
      "target": {
        "id": "MMReceiver",
        "serialNumber": "DFA01"
      }
    },
    "payload": {
      "measurementType": "res",
      "measurementId": 1,
      "filename": "Test_RES!",
      "ax": 1.000000E+00,
      "ay": 0.000000E+00,
      "az": 0.000000E+00,
      "bx": 0.000000E+00,
      "by": 0.000000E+00,
      "bz": 0.000000E+00,
      "mx": 2.000000E+00,
      "my": 0.000000E+00,
      "mz": 0.000000E+00,
      "nx": 3.000000E+00,
      "ny": 0.000000E+00,
      "nz": 0.000000E+00,
      "commandNumber": 1,
      "cycles": 2,
      "measureTimeS": 7.2,
      "maxError": 2.0,
      "maxRepeat": 1,
      "txMaxHV": 400,
      "txMaxCurrentmA": 10,
      "abmnConfig": "1A2B3M4N"
    }
  }

  const commandHeader = {
   
    
    datetime: new Date(),
    source: {
     
      serialNumber: ""
    },
    target: {
      id: selectedThing?.thingName || "Shazbot",
      serialNumber: selectedThing?.thingArn || "Nanu nanu"
    }
  }

  const onClickMeasure = data => {
   
    // if (selectedThing && selectedThing.thingName) {
    //   setMeasurementId(measurementId + 1)
    //   data.measurementId = measurementId
    //   const commandStartMeasurement = {
    //     header: commandHeader,
    //     payload: data
    //   }
    PubSub.publish(topicToPublish , payload);
  }
  
  const handleDownload = async () => {
    try {
      const fileToSave = new Blob([JSON.stringify(msg, null, 3)], {
        type: "application/json",
      });

      saveAs(fileToSave, settings.filename);
    } catch (err) {
      console.log(err);
    }
  };
  
  PubSub.subscribe(topicToSubscribe).subscribe({
    next: (data) =>setMsg(data.value.payload),
    error: (error) => console.error("Error Occured in mqtt: ", error),
    complete: () => console.log("Done"),
  });

 

  const handleAdvancedSettingsChange = () => {
    setAdvancedSettings(!advancedSettings)
  }

  const getSettings = async data => {
    setSettings(data)
    onClickMeasure(data)
  }
   
 

  useEffect(() => {
   
    let temp = [msg, ...messages];
   
    setMessages(temp);
  }, [msg]);


  
    return (
      <div>
        <Card className={stylesCard.card}>
          <Paper className={stylesCard.header}>
            <Typography
              className={stylesCard.headerText}
              variant="h5"
              component="h2"
              align="left"
            >
             Measurement Control Center
            </Typography>
          </Paper>

          <Box border={1}>
            <Container className={styles.paddingTop15}>
              <Box className={styles.paddingBottom15}>
                <Row>
                  <Col>
                    <MeasurementResultRes measurementData={msg}  />
                    <Row>
                      <Col md={5}>
                        {!advancedSettings ? (
                          <Button
                            variant="contained"
                            size="small"
                            color="inherit"
                            className={stylesAGI.standardButton}
                            onClick={e => onClickMeasure(e)}
                            // disabled={!selectedThing}
                          >
                           One-Click Measurement
                          </Button>
                        ) : null}
                      </Col>
                      <Col md={3}>
                        <Button
                          variant="contained"
                          size="small"
                          color="inherit"
                          className={stylesAGI.standardButton}
                          onClick={handleDownload}
                          // disabled={!selectedThing}
                        >
                          Download
                        </Button>
                      </Col>
                      <Col md={4}>
                        <Button
                          variant="contained"
                          size="small"
                          color="inherit"
                          className={stylesAGI.standardButton}
                          onClick={handleAdvancedSettingsChange}
                          // disabled={!selectedThing}
                        >
                          {!advancedSettings
                            ? "Advanced Settings"
                            : "Hide Settings"}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Box>
            </Container>
          </Box>
        </Card>
        {advancedSettings ? (
          <MeasurementSettings
            selectedThing={selectedThing}
            getSettings={getSettings}
          />
        ) : null}
      </div>
    )
 
}

export default MeasurementControl
