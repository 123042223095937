import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { getAttribute } from "../../services/auth";
import {
  getAdminDashboard,
  getSuperAdminDashboard,
} from "../../services/axios";
import { getTotalSubscription } from "../../services/chargebee";

const Dashboard = () => {
  const [currentUser, setCurrentUser] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [subscription, setSubscription] = useState({});
  const history = useHistory();
  useEffect(() => {
    async function fetchUserAttribute() {
      let data = await getAttribute();
      if (data["custom:role"] === "technician") {
        history.push("/user");
        return;
      }

      if (data["custom:role"] === "superadmin") {
        await fetchSuperAdmin();
        await fetchSubscriptionList();
      } else {
        await fetchAdmin(data["custom:companyId"]);
      }

      setCurrentUser(data);
    }

    async function fetchSubscriptionList() {
      let totalSub = await getTotalSubscription();

      setSubscription(totalSub.data.data);
    }

    async function fetchSuperAdmin() {
      let superAdminData = await getSuperAdminDashboard();

      setDashboardData(superAdminData.data);
    }
    async function fetchAdmin(data) {
      let adminData = await getAdminDashboard(data);

      setDashboardData(adminData.data);
    }

    fetchUserAttribute();
  }, []);

  return (
    <>
      <div className="mt-1 p-1">
        {/* <h3 className="text-center card mt-1 p-1">Dashboard</h3> */}
      </div>
      <div className="row mt-3">
        <div class="col-4 mb-4  ">
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={{
              pathname: "/device",
            }}
          >
            <div class="card border-primary border-bottom-0 border-right-1 border-top-0 shadow-lg h-200 py-2 p-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold  text-uppercase mb-1">
                      Devices
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800 ">
                      {dashboardData.deviceCount}
                    </div>
                  </div>
                  <div class="col-auto">
                    <i class="fa fa-desktop fa-2x text-dark-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div class="col-4 mb-4 ">
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={{
              pathname:
                currentUser["custom:role"] === "superadmin"
                  ? "/home"
                  : "/surveylocation",
            }}
          >
            <div class="card border-primary border-bottom-0 border-right-1 border-top-0 shadow-lg h-200 py-2 p-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold  text-uppercase mb-1">
                      Surveys
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800 ">
                      {dashboardData.surveyLocationCount}
                    </div>
                  </div>
                  <div class="col-auto">
                    <i class="fa fa-map-marker fa-2x text-dark-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        <div class="col-4 mb-4 ">
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={{
              pathname: "/user",
            }}
          >
            <div class="card border-primary border-bottom-0 border-right-1 border-top-0 shadow-lg h-200 py-2 p-2">
              <div class="card-body">
                <div class="row no-gutters align-items-center">
                  <div class="col mr-2">
                    <div class="text-xs font-weight-bold  text-uppercase mb-1">
                      Users
                    </div>
                    <div class="h5 mb-0 font-weight-bold text-gray-800 ">
                      {dashboardData.userCount}
                    </div>
                  </div>
                  <div class="col-auto">
                    <i class="fa fa-users fa-2x text-dark-300"></i>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>

        {currentUser["custom:role"] === "superadmin" ? (
          <>
            <div class="col-4 mb-4">
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={{
                  pathname: "/company",
                }}
              >
                <div class="card border-primary border-bottom-0 border-right-1 border-top-0 shadow-lg h-200 py-2 p-2">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold  text-uppercase mb-1">
                          Tenants
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800 ">
                          {dashboardData.companyCount}
                        </div>
                      </div>
                      <div class="col-auto">
                        <i class="fa fa-industry fa-2x text-dark-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div class="col-4 mb-4">
              {/* <Link
                style={{ textDecoration: "none", color: "black" }}
                to={{
                  pathname: "/purchase",
                }}
              > */}
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={{
                  pathname:
                    currentUser["custom:role"] === "superadmin"
                      ? "/home"
                      : "/purchase",
                }}
              >
                <div class="card border-primary border-bottom-0 border-right-1 border-top-0 shadow-lg h-200 py-2 p-2">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold  text-uppercase mb-1">
                          Active Subscriptions
                        </div>
                        <div class="h5 mb-0 font-weight-bold text-gray-800 ">
                          {subscription}
                        </div>
                      </div>
                      <div class="col-auto">
                        <i class="fa fa-rocket fa-2x text-dark-300"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default Dashboard;
