import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaSave } from "react-icons/fa";
import { Redirect, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getAttribute } from "../../services/auth";
import {
  companyStatusUpdate,
  companyUpdateData,
  getCompanyData,
} from "../../services/axios";
import BackButton from "../back_button/back_button";

const EditCompany = () => {
  const data = {
    id: "abc123",
    name: "Volansys Technologies",
    address1: "123 Main Street",
    address2: "TX, USA",
    state: "CA",
    country: "USA",
    person: "ABC XYZ",
    zipCode: "123456",
    city: "San Francisco",
    email: "volansys@volansys.com",
    subscriptions: 1,
    totalDevices: 2,
  };
  const history = useHistory();
  
  const [status, setStatus] = useState(
    history.location.state.subscriptionStatus
  );
  const [company, setCompany] = useState(history.location.state);
  const [readMode, setReadMode] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const validationSchema = Yup.object().shape({
    companyName: Yup.string()
      .required("Company Name is required")
      .max(50, "No more than 50 characters."),
    address1: Yup.string()
      .required("Address is required.")
      .max(100, "No more than 100 characters."),
    address2: Yup.string().max(100, "No more than 100 characters."),
    city: Yup.string()
      .required("City is required.")
      .max(50, "No more than 50 characters."),
    state: Yup.string().required("State is required."),
    zipCode: Yup.string().max(50, "No more than 10 numbers."),
    country: Yup.string().required("Country is required."),
  })
  const formOptions = { resolver: yupResolver(validationSchema), defaultValues:history.location.state};
  const { register, handleSubmit, setValue,formState: { errors } } = useForm(formOptions);
  useEffect(() => {
    if (history.location.state.edit) {
      setReadMode(false);
      fetchCompanyData();
      setValue("companyName", history.location.state.name,{ shouldValidate: true });
      setValue("address1", history.location.state.address1,{ shouldValidate: true });
      setValue("address2", history.location.state.address2,{ shouldValidate: true });
      setValue("city", history.location.state.city,{ shouldValidate: true });
      setValue("zipCode", history.location.state.zipCode,{ shouldValidate: true });
      setValue("country", history.location.state.country,{ shouldValidate: true });
      setValue("state", history.location.state.state,{ shouldValidate: true });
    }
    async function fetchUserAttribute() {
      let data = await getAttribute();
      setCurrentUser(data);
    }
    fetchUserAttribute();
  }, []);

  if (currentUser["custom:role"] === "technician") {
    return <Redirect to={"/home"} />;
  }

  const fetchCompanyData = async () => {
    let data = await getCompanyData(history.location.state.companyId);
    setCompany(data.data.data[0]);
  };

  const handleStatus = (e) => {
    e.preventDefault();
    setStatus(e.target.value);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    
    if (!history.location.state.edit) {
      let resp = await companyStatusUpdate(
        status,
        company.id,
        currentUser["custom:userId"]
      );
      if (resp.data.statusCode === 200) {
        toast.success(resp.data.message);
        history.goBack();
      } else {
        console.log(resp.data.error);
        toast.error(resp.data.message);
      }
    } else {
      console.log("Here");
    }
  };

  const handleChange = (e) => {
    e.preventDefault();
    console.log(e.target.value);
    setValue([e.target.value], e.target.value);
    setCompany({ ...company, [e.target.value]: e.target.value });
  };

  const onSubmit = async (e) => {
    console.log('onSubmit:',e)
    if (currentUser["custom:role"] === "admin") {
      let payload = {
        companyId: currentUser["custom:companyId"],
        userId: currentUser["custom:userId"],
        company: e,
      };
      let resp = await companyUpdateData(
        payload.companyId,
        payload.userId,
        payload.company
      );

      if (resp.data.statusCode === 200) {
        toast.success(resp.data.message);
        history.goBack();
      } else {
        console.log(resp.data);
        toast.error(resp.data.message);
      }
    }
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-8 p-3">
          <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label>
                Company Name<span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="companyName"
                defaultValue={company.name}
                onChange={handleChange}
                className={`form-control ${
                  errors.companyName? "is-invalid" : ""
                }`}
                {...register("companyName")}
                disabled={readMode}
              />
                <div className="invalid-feedback">
                            {errors.companyName?.message}
                  </div>
            </div>
            <div className="form-group">
              <label htmlFor="inputAddress">
                Address1 <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                name="address1"
                onChange={handleChange}
                className={`form-control ${
                  errors.address1? "is-invalid" : ""
                }`}
               
                {...register("address1")}
                defaultValue={company.address1}
                disabled={readMode}
              />
               <div className="invalid-feedback">
                            {errors.address1?.message}
                  </div>
            </div>
            <div>
              <div className="form-group">
                <label htmlFor="inputAddress2">Address 2</label>
                <input
                  type="text"
                  className="form-control"
                  name="address2"
                 {...register("address2")}
                  onChange={handleChange}
                  defaultValue={company.address2}
                  disabled={readMode}
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group col-md-4">
                <label htmlFor="inputCity">
                  City <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={company.city}
                  disabled={readMode}
                  name="city"
                  onChange={handleChange}
                  {...register("city")}
                  className={`form-control ${
                    errors.city? "is-invalid" : ""
                  }`}
                />
                <div className="invalid-feedback">
                            {errors.city?.message}
                  </div>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="inputState">
                  State <span className="text-danger">*</span>
                </label>
                <select
                   className={`form-control ${
                    errors.state? "is-invalid" : ""
                  }`}
                  defaultValue={company.state}
                  name="state"
                  
                  onChange={handleChange}
                  disabled={readMode}
                >
                  <option value="AL">AL</option>
                  <option value="AK">AK</option>
                  <option value="AZ">AZ</option>
                  <option value="AR">AR</option>
                  <option value="CA">CA</option>
                  <option value="CO">CO</option>
                  <option value="CT">CT</option>
                  <option value="DE">DE</option>
                  <option value="DC">DC</option>
                  <option value="FL">FL</option>
                  <option value="GA">GA</option>
                  <option value="HI">HI</option>
                  <option value="ID">ID</option>
                  <option value="IL">IL</option>
                  <option value="IN">IN</option>
                  <option value="IA">IA</option>
                  <option value="KS">KS</option>
                  <option value="KY">KY</option>
                  <option value="LA">LA</option>
                  <option value="ME">ME</option>
                  <option value="MD">MD</option>
                  <option value="MA">MA</option>
                  <option value="MI">MI</option>
                  <option value="MN">MN</option>
                  <option value="MS">MS</option>
                  <option value="MO">MO</option>
                  <option value="MT">MT</option>
                  <option value="NE">NE</option>
                  <option value="NV">NV</option>
                  <option value="NH">NH</option>
                  <option value="NJ">NJ</option>
                  <option value="NM">NM</option>
                  <option value="NY">NY</option>
                  <option value="NC">NC</option>
                  <option value="ND">ND</option>
                  <option value="OH">OH</option>
                  <option value="OK">OK</option>
                  <option value="OR">OR</option>
                  <option value="PA">PA</option>
                  <option value="RI">RI</option>
                  <option value="SC">SC</option>
                  <option value="SD">SD</option>
                  <option value="TN">TN</option>
                  <option value="TX">TX</option>
                  <option value="UT">UT</option>
                  <option value="VT">VT</option>
                  <option value="VA">VA</option>
                  <option value="WA">WA</option>
                  <option value="WV">WV</option>
                  <option value="WI">WI</option>
                  <option value="WY">WY</option>
                </select>
                <div className="invalid-feedback">
                            {errors.state?.message}
                  </div>
              </div>
              <div className="form-group col-md-3">
                <label htmlFor="inputZip">
                  ZipCode 
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="zipCode"
                  onChange={handleChange}
                  {...register("zipCode")}
                  defaultValue={company.zipCode}
                  disabled={readMode}
                />
                <div className="invalid-feedback">
                            {errors.zipCode?.message}
                  </div>
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="inputState">
                  Country <span className="text-danger">*</span>
                </label>
                <select
                  className="form-control"
                  name="country"
                  onChange={handleChange}
                  {...register("country")}
                  defaultValue={company.country}
                  disabled={readMode}
                >
                  <option value="USA">USA</option>
                </select>
                <div className="invalid-feedback">
                            {errors.country?.message}
                  </div>
              </div>

            </div>

            <div className="row">
              <div className="form-group col-md-3">
                <label htmlFor="inputZip">Company Status</label>
                <select
                  className="form-control"
                  disabled={!readMode}
                  defaultValue={company.subscriptionStatus}
                  onChange={readMode ? handleStatus : null}
                >
                  <option value={true}>Active</option>
                  <option value={false}>Deactivate</option>
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <BackButton />
              </div>
              <div className="col text-right">
                {/* <Link
                to={{
                  pathname: "/user",
                  state: {
                    message: "from edit company",
                  },
                }}
              >
                <button className="btn btn-info btn-sm mr-3">
                  <FaUsers className="mr-1" color="black" />
                  <span>Users</span>
                </button>
              </Link> */}
                {currentUser["custom:role"] === "superadmin" ? (
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleSave}
                    type="submit"
                  >
                    <FaSave className="mr-1" color="" />
                    <span>Save</span>
                  </button>
                ) : (
                  <button className="btn btn-primary btn-sm" type="submit">
                    <FaSave className="mr-1" color="" />
                    <span>Save</span>
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditCompany;
