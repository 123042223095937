import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { forceChange, sendForgotPasswordCode } from "../../services/axios";
import BackButton from "../back_button/back_button";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form"; 

const ForgotPassword = (props) => {
  const history = useHistory();

  const [code, setCode] = useState("");
  const [email, setEmail] = useState(props.email);
  const [password, setPassword] = useState("");
  const [codeSend, setCodeSend] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [eye, setEye] = useState(true);
  let passPattern =
    /^(?=.*[0-9])(?=.*[A-Z])(?=.*[.!@#$%^&*])[a-zA-Z0-9.!@#$%^&*]{8,24}$/;
  
  const emailValidationSchema = Yup.object().shape({
    email: Yup.string().email('Must be valid email.').required("Email is required").max(50, "No more than 50 characters"),
    
  });
  const validationSchema = Yup.object().shape({
    password: Yup.string().required('New Password is required').matches(passPattern, "Enter a Valid Password"),
    code: Yup.string().required(' Confirmation code is required').max(10, "No more than 10 Characters"),
  })
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(emailValidationSchema) });
  
  const {
    register:register1,
    handleSubmit:handleSubmit1,
    formState: { errors:errors1 },
  } = useForm({ resolver: yupResolver(validationSchema) });
  
  const handleCodeChange = async (e) => {
    setCode(e.target.value);
  };

  const handleEmailChange = async (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = async (e) => {
    setPassword(e.target.value);
  };

  const handleCodeSend = async (e) => {
    // e.preventDefault();
    // let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // if (!pattern.test(email)) {
    //   toast.error("Please, Enter valid email.");
    //   return;
    // }
    setEmail(e.email);
    let resp = await sendForgotPasswordCode(e.email);
    if (resp.data.statusCode === 200) {
      toast.success("Confirmation sent to email address.");
      setCodeSend(true);
    } else {
      toast.error(resp.data.message);
      setCodeSend(false);
    }
  };

   const  handleCodeSubmit = async (e) => {
    // console.log('e:',e)
    // e.preventDefault();
    
    // if (code.trim().length < 1) {
    //   toast.error("Please, Enter Code.");
    //   return;
    // }
    // if (!pattern.test(password)) {
    //   toast.error("Please, Enter valid password.");
    //   setPasswordError(true);
    //   return;
    // }
    // let resp = await forgotPasswordSubmit(email, code, password);
    // if (resp.success) {
    let uData = await forceChange(email, e.code,e.password);
    if (uData.data.statusCode === 200) {
      toast.success("Password Changed Successfully.");
      toast.info("Login for continue");
      history.push("/login");
    } else {
      toast.error(uData.data.message);
    }
    // } else {
    //   console.log(resp.code);
    //   toast.error(resp.message);
    // }
  };

  return (
    <>
      <main>
        <div className="container">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <div className="col-lg-5">
              <div className="card shadow-lg border-0 rounded-lg mt-5">
                <div className="d-flex justify-content-center mt-3">
                  <img
                    src={
                      "GeoStack.png"
                    }
                    style={{ width: "25%", height: "25%" }}
                    alt="Logo"
                  />
                </div>
                <div className="card-header text-center bg-white">
                  <h3 className="font-weight-light my-3">Welcome</h3>
                  <h6 className="font-weight-light mb-3 mt-n1">to GeoStack</h6>
                </div>
                <div className="card-body">
                  <form className="p-2" onSubmit={handleSubmit(handleCodeSend)}>
                    <div className="form-group mb-3">
                      <label htmlFor="inputEmail">Forgot Password</label>
                      <p>
                        <li>
                          <small>
                          You will receive confirmation code on the registered email.
                          </small>
                        </li>
                        <li>
                          <small>
                            Make sure you have an access to the email.
                          </small>
                        </li>
                      </p>
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="inputEmail">Registered Email</label>
                      <input
                         className={`form-control ${
                          errors.email? "is-invalid" : ""
                        }`}
                        id="inputEmail"
                        autoFocus
                        type="email"
                        onChange={handleEmailChange}
                        defaultValue={email}
                        placeholder="Enter Registered Email"
                        {...register("email")}
                      />
                       <div className="invalid-feedback">
                            {errors.email?.message}
                          </div> 
                      <div className="mt-3">
                        <button
                          style={{ background: "#228B32", width: "100%" }}
                          className=" btn card-footer text-center py-3"
                          // onClick={handleCodeSend}
                          type="submit"
                        >
                          Send Confirmation Code
                        </button>
                      </div>
                    </div>
                    </form>
                    {codeSend ? (
                    <div>
                      <form className="p-2" onSubmit={handleSubmit1(handleCodeSubmit)}>
                        <div className="form-group mb-3">
                          <label htmlFor="inputCode">Confirmation Code</label>
                          <input
                           className={`form-control ${
                            errors1.code ? "is-invalid" : ""
                          }`}
                            id="inputCode"
                            type="text"
                            onChange={handleCodeChange}
                            placeholder="Enter code"
                            {...register1("code")}
                            
                          />
                          {/* <input type='hidden' name='username' id='username' value=''/> */}
                          <div className="invalid-feedback">
                            {errors1.code?.message}
                           </div>
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="inputPassword">New Password</label>
                          <div className="input-group">
                            <input
                             className={`form-control ${
                              errors1.password ? "is-invalid" : ""
                            }`}
                              id="inputPassword"
                              type={eye ? "password" : "text"}
                              onChange={handlePasswordChange}
                              placeholder="Enter New Password"
                              {...register1("password")}
                            />
                           
                            <div className="input-group-append">
                              <button
                                className="input-group-text"
                                type="button"
                                onClick={(e) => setEye(!eye)}
                              >
                              {eye ? (
                                <i
                                  className="fa fa-eye"
                                  aria-hidden="true"
                              ></i>
                                ) : (
                                  <i
                                    className="fa fa-eye-slash"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </button>
                             
                            </div>
                            <div className="invalid-feedback">
                            {errors1.password?.message}
                            </div> 
                          </div>
                        </div>
                        {passwordError ? (
                          <span>
                            <small className="text-danger">
                              Atleast one: Upper-case, Number and Special
                              Character
                              {" .!@#$%^&* "}
                            </small>
                          </span>
                        ) : null}
                        <div>
                          <button
                            style={{ background: "#228B32", width: "100%" }}
                            className=" btn card-footer text-center py-3"
                            // onClick={handleCodeSubmit} 
                            type="submit"
                          >
                            Change Password
                          </button>
                        </div>
                      </form>
                    </div>
                    ) : null}
                    <div className="text-center mt-2">
                      <BackButton />
                    </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ForgotPassword;
