import React, { useEffect, useState } from "react";
import { FaArrowLeft, FaSave } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getAttribute } from "../../services/auth";
import { createUserAPI, userAdd } from "../../services/axios";
import AddSuperUser from "../add_super_admin/add_super_admin";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";




const AddUser = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [status, setStatus] = useState(1);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("T");
  const [eye, setEye] = useState(true);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const history = useHistory();

  const [currentUser, setCurrentUser] = useState({});
  const phoneRegExp = /^\d{10}$/;
  let pattern = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[.!@#$%^&*])[a-zA-Z0-9.!@#$%^&*]{8,24}$/;

  const validationSchema = Yup.object().shape({
    firstName:Yup.string().required("First Name is required").max(50,"No more than 50 characters."),
    mobile: Yup.string().required("Mobile number  is required").matches(phoneRegExp, "Mobile number is invalid."),
    lastName: Yup.string().max(50, "No more than 50 characters."),
    email: Yup.string()
      .email("Must be valid email")
      .max(50, "No more than 50 characters.")
      .required("Email is required."),
    password: Yup.string().required("Password is required.").matches(pattern,"Enter a valid password")
  })
  const formOptions = { resolver: yupResolver(validationSchema)};
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions)
  


  useEffect(() => {
    async function fetchUserAttribute() {
      let data = await getAttribute();
      setCurrentUser(data);
    }
    fetchUserAttribute();
  }, []);

  const handleFirstName = (e) => {
    e.preventDefault();
    setFirstName(e.target.value);
  };

  const handleLastName = (e) => {
    e.preventDefault();
    setLastName(e.target.value);
  };

  const handleMobile = (e) => {
    e.preventDefault();
    setMobile(e.target.value);
  };

  const handleRole = (e) => {
    e.preventDefault();
    setRole(e.target.value);
  };

  const handlePassword = async (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const handleEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handleAdd = async (e) => {
    // e.preventDefault();
   
   
    setPasswordError(false);
    setLoading(true);
    let payload = {
      createdBy: currentUser["custom:userId"],
      companyId: currentUser["custom:companyId"],
      roleId: role,
      firstName:e.firstName,
      lastName:e.lastName,
      mobile:e.mobile,
      email:e.email,
      password:e.password,
      status,
      flag: "create",
    };
    let res = await userAdd(payload);
    if (res.data.statusCode === 200) {
      payload.role = role === "A" ? "admin" : "technician";
      payload.userId = res.data.userId;
      let uData = await createUserAPI(payload);
      if (uData.data.statusCode === 200) {
        toast.success(uData.data.message);
        setLoading(false);
        history.push("/user");
      } else {
        toast.error(uData.data.message);
        setLoading(false);
      }
    } else {
      toast.error(res.data.message);
      setLoading(false);
    }
  };
  const goToPreviousPath = (e) => {
    history.goBack();
  };

  if (currentUser["custom:role"] === "superadmin") {
    return <AddSuperUser />;
  } else {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="p-5">
              <form onSubmit={handleSubmit(handleAdd)}>
                <div className="row">
                  <div className="col">
                    <label className="form-label">
                      Firstname <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.firstName ? "is-invalid" : ""
                      }`}
                      placeholder="Enter First Name"
                      onChange={handleFirstName}
                      {...register("firstName")}
                     
                    />
                    <div className="invalid-feedback">
                              {errors.firstName?.message}
                            </div>
                  </div>
                  <div className="col">
                    <label>Lastname</label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.lastName ? "is-invalid" : ""
                        }`}
                        {...register("lastName")}
                      placeholder="Enter Last Name"
                      onChange={handleLastName}
                     
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <label>
                      Mobile <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        errors.mobile ? "is-invalid" : ""
                      }`}
                      placeholder="Enter Mobile"
                      onChange={handleMobile}
                      {...register("mobile")}
                     
                    />
                     <div className="invalid-feedback">
                              {errors.mobile?.message}
                            </div>
                  </div>

                  <div className="col">
                    <label>
                      Role <span className="text-danger">*</span>
                    </label>
                    <select className="form-control" onChange={handleRole}>
                      <option value="T">Technician</option>
                      <option value="A">Admin</option>
                    </select>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      placeholder="Enter Email"
                      onChange={handleEmail}
                      {...register("email")}
                    />
                     <div className="invalid-feedback">
                              {errors.email?.message}
                            </div>
                  </div>
                  <div className="col">
                    <label>
                      Password <span className="text-danger">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type={eye ? "password" : "text"}
                        className={`form-control ${
                          errors.password || passwordError
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Enter Password"
                        onChange={handlePassword}
                        {...register("password")}
                      />
                      <div className="input-group-append">
                        <button
                          className="input-group-text"
                          type="button"
                          onClick={(e) => setEye(!eye)}
                        >
                          {eye ? (
                            <i className="fa fa-eye" aria-hidden="true"></i>
                          ) : (
                            <i
                              className="fa fa-eye-slash"
                              aria-hidden="true"
                            ></i>
                          )}
                        </button>
                      </div>
                      <div className="invalid-feedback">
                                {errors.password?.message}
                              </div>
                    </div>
                    {passwordError ? (
                      <span>
                        <ul className="text-danger">
                          <li>
                            <small>Minimum 8 characters required</small>
                          </li>
                          <li>
                            <small>
                              Atleast one: Upper-case, Number and Special
                              Character
                              {" .!@#$%^&* "}
                            </small>
                          </li>
                        </ul>
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group mt-4 row">
                  <div className="col">
                    <button
                      className="btn btn-warning btn-sm"
                      onClick={goToPreviousPath}
                      type="button"
                    >
                      <FaArrowLeft className="mr-1 mb-1" />
                      Back
                    </button>
                  </div>
                  <div className="col text-right">
                    <button
                      // disabled={isLoading}
                      className="btn btn-primary btn-sm"
                      type="submit"
                    >
                      <FaSave className="mr-1 mb-1" />
                      Save
                      {isLoading ? (
                        <span className="ml-2 spinner-grow spinner-grow-sm text-light" />
                      ) : null}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default AddUser;
