import { Box, Paper } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import { toast } from "react-toastify"
import { Logger } from "aws-amplify"
import React, { useState,useEffect} from "react"
import { Col, Container, Row } from "react-grid-system"
import MeasurementControl from "../measurements/MeasurementControl";
import deviceImage from "../../assets/images/device.png"
import { getDeviceList,updateDevice } from "../../services/axios";
import { useHistory } from 'react-router-dom';
import { getAttribute } from "../../services/auth";
import agiStyles from "../../styles"
import cardStyles from "../../styles/CardStyles"
import thingInfoStyles from "../../styles/ThingInfoStyles"
import TextEditField from "../text/TextEditField"
import MQTTComponent from "../mqtt/mqtt";
import BackButton from "../back_button/back_button";
import { confirmAlert } from "react-confirm-alert";



const DeviceInfo = ({
  selectedThingType,
  selectedThing,
  deviceIdentity,
  firmwareUpdateAvailable
}) => {
  let flag="device";
  const levelPercent = 33.3
  const timeRemainingSeconds = 356789
  const storageUsed = 1024
  const storageTotal = 4096
  const history = useHistory();
  const [status, setStatus] = useState(history.location.state.status);
  const [currentUser, setCurrentUser] = useState({});
  const [readMode, setReadMode] = useState(true);
  const stylesAGI = agiStyles()
  const stylesCard = cardStyles()
  const styles = thingInfoStyles()
  

 
  async function fetchAttribute() { 
    let data = await getAttribute();
    
    setCurrentUser(data);
  }

  useEffect(() => {
    fetchAttribute();
    
 },[])
  
  const showConfirmationDialogue = (e) => {
   
  confirmAlert({
    title: "Change Status",
    message: "Are you sure to do this?",
    buttons: [
      {
        label: "Yes",
        onClick: () => handleStatus(e),
      },
      {
        label: "No",
      },
    ],
  });
};
  const handleStatus = async (e) => {
    e.preventDefault();
    
    setStatus(e.target.value);
    
    let resp = await updateDevice(
      e.target.value,
      history.location.state.id,
          
      );
    console.log("resp: ", resp); 
    if (resp.status == 200) {
      toast.success("Device status updated");
      history.push("/device");
      
    } else {
      toast.success("Error in updateting status");
      history.push('/deviceInfo');
     
    }
   
  };

  

    // setDevices(temp);
   


   
    return (
        <>
            <h5 className="card-title col-10 pb-2 ml-4 pl-4">
                      <span >Device Details</span>
                      <span className="ml-4"></span>
        </h5>
        {currentUser["custom:role"] == "technician" ? null :
          <div className="row">
              <div className="form-group pb-2 ml-3 pl-5">
                <label htmlFor="inputZip">Device Status</label>
                <select
              className="form-control"
              disabled={!readMode}
              defaultValue={history.location.state.status}
              onChange={showConfirmationDialogue}
                >
                  <option value={true}>Active</option>
                  <option value={false}>Deactivate</option>
                </select>
              </div>
            </div>}
           
        <Card className={stylesCard.card}>
        <Paper className={stylesCard.header}>
          <Typography
            className={stylesCard.headerText}
            variant="h5"
            component="h2"
            align="left"
          >
            Thing Information
          </Typography>
        </Paper>

        <Box border={1}>
          <Container className={styles.paddingTop15}>
            <Box className={styles.paddingBottom15}>
              <Row>
                <Col md={5}>
                  <CardMedia className={stylesCard.image}>
                    <img
                      className={stylesCard.img}
                      alt="device"
                      src={deviceImage}
                    />
                  </CardMedia>
                  <div className={styles.readyForUserText}>
                    <span className={styles.circleStatus}></span>
                    <span className={styles.statusText}>Ready for use</span>
                  </div>

                  {/* <Button
                    variant="contained"
                    size="small"
                    color="inherit"
                    className={stylesAGI.standardButton}
                  
                    disabled={!firmwareUpdateAvailable}
                  >
                    Firmware Update Available
                  </Button> */}
                </Col>
                <Col md={7}>
                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={10}>
                        <Typography align="left" variant="body2"  noWrap>
                        Device Name:{history.location.state.data}
                      </Typography>
                    </Col>
                    <Col md={7}>
                      <Typography
                        align="left"
                        variant="body2"
                        color="textSecondary"
                      >
                        {deviceIdentity?.header?.source.id}
                      </Typography>
                    </Col>
                  </Row>
                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={5}>
                      <Typography align="left" variant="body2">
                        Device Type
                      </Typography>
                    </Col>
                    <Col md={7}>
                      <TextEditField
                        textValue={deviceIdentity?.payload?.deviceType}
                       
                      />
                    </Col>
                  </Row>

                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={5}>
                      <Typography align="left" variant="body2">
                        Serial Number
                      </Typography>
                    </Col>
                    <Col md={7}>
                      <Typography
                        align="left"
                        variant="body2"
                        color="textSecondary"
                      >
                        {deviceIdentity?.header?.source.serialNumber}
                      </Typography>
                    </Col>
                  </Row>
                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={5}>
                      <Typography align="left" variant="body2">
                        Manufacture Date
                      </Typography>
                    </Col>
                    <Col md={7}>
                      <Typography
                        align="left"
                        variant="body2"
                        color="textSecondary"
                      >
                        {deviceIdentity?.payload?.manufactureDate}
                      </Typography>
                    </Col>
                  </Row>
                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={5}>
                      <Typography align="left" variant="body2">
                        Firmware Version
                      </Typography>
                    </Col>
                    <Col md={7}>
                      <Typography
                        align="left"
                        variant="body2"
                        color="textSecondary"
                      >
                        {deviceIdentity?.payload?.firmwareVersion}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Box>

            {/* <Row className={styles.customContainerTopBorder}>
              <Col md={6} className={styles.customContainerRightBorder}>
                <BatteryLevel levelPercent={levelPercent} timeRemainingSeconds={timeRemainingSeconds} />
              </Col>
              <Col md={6}>
                <StorageUsage storageUsed={storageUsed} storageTotal={storageTotal} />
              </Col>
            </Row> */}
                </Container>
            </Box>
           
            
        </Card>
        <div style={{display:"inline-block"}}>
          <div ><MeasurementControl  /></div>
          <div  className="mt-2">
          
          </div>
        </div>
        <div className="row ml-4"  >
              <div className="col">
                <BackButton />
        </div>
      </div>
        </>
    )
  } 
  


export default DeviceInfo;
