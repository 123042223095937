import * as _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import * as uuid from "uuid";
import HereMapStyle from "./styles/HereMap";
var UTM = require("utm-latlng");

const plusIcon = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm.5 10h6v1h-6v6h-1v-6h-6v-1h6v-6h1v6z"/></svg>`;
const lineModeHeaderCircle = `<svg height="10" width="10" ><circle cx="5" cy="5" r="5" stroke="black" fill="#4b4b4b" stroke-width="0" /></svg>`;
const lineModeHeaderRect = `<svg width="31" height="31" style="zoom:0.37"><rect x="12" y="-10" width="20" height="20" transform="rotate(45)" fill="#4b4b4b" stroke-width="0"/></svg>`;
const minusIcon = `<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.5 0c6.347 0 11.5 5.153 11.5 11.5s-5.153 11.5-11.5 11.5-11.5-5.153-11.5-11.5 5.153-11.5 11.5-11.5zm0 1c5.795 0 10.5 4.705 10.5 10.5s-4.705 10.5-10.5 10.5-10.5-4.705-10.5-10.5 4.705-10.5 10.5-10.5zm-6.5 10h13v1h-13v-1z"/></svg>`;
const svgMarkup =
  '<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><g id="UrTavla"><circle cx="15" cy="15" r="13" fill="${STROKE}" stroke="#FFF" stroke-width="2"/><text x="50%" y="50%" text-anchor="middle" stroke="black" font-size="12px" dy=".3em">${TEXT}</text></g></svg>';
const svgLinkMarkup =
  '<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><g id="UrTavla"><circle cx="15" cy="15" r="13" fill="${STROKE}" stroke="#FFF" stroke-width="2"/><text x="50%" y="50%" text-anchor="middle" stroke="white" font-size="12px" dy=".3em">${TEXT}</text></g></svg>';
const svgRectangleUp =
  '<svg width="44" height="44" xmlns="http://www.w3.org/2000/svg"><g><path transform="rotate(-45 21.9694 21.9223)" d="m6.96938,6.9223l30,0l0,30l-30,0l0,-30z" stroke-width="2" stroke="white" fill="${STROKE}"/><text x="50%" y="50%" text-anchor="middle" stroke="white" font-size="12px" dy=".3em">${TEXT}</text></g></svg>';
const HereMap = ({
  height = 500,
  lineColor = "white",
  markColor = "#00f5a8",
  lineMarkColor = "#db3af0",
  dataChange,
  dotPoints,
  linePoints,
  avglat,
  avglng,
}) => {
  const classes = HereMapStyle();
  const mapRef = useRef(null);
  const [mode, setMode] = useState(0);
  const [open, setOpen] = useState(false);
  const [hereMap, setHereMap] = useState();
  const [behavior, setBehavior] = useState();
  const [geocode, setGeoCode] = useState();
  const [initialLatitude, setInitialLatitude] = useState("");
  const [initialLongitude, setInitialLongitude] = useState("");
  const [initialStreetAddress, setInitialStreetAddress] = useState("");
  const [firstMarkerID, setFirstMarkerID] = useState("");
  const [selectedMarkerID, setSelectedMarkerID] = useState("");
  const [selectedLatitude, setSelectedLatitude] = useState("");
  const [selectedLongitude, setSelectedLongitude] = useState("");
  const [dotIndex, setDotIndex] = useState(0);
  const [lineIndex, setLineIndex] = useState(0);
  const [pointMode, setPointMode] = useState(0);
  const [unitMode, setUnitMode] = useState(0);
  const [dotPointsData, setDotPointsData] = useState(
    dotPoints ? dotPoints : []
  );
  const [linePointsData, setLinePointsData] = useState(
    linePoints ? linePoints : []
  );
  const [updateComponent, setUpdateComponent] = useState(false);

  const H = window.H;
  var utm = new UTM();

  //form data
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();

  // const formChange = (event) => {
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value.trim(),
  //   });
  // };

  useEffect(() => {
    if (hereMap) {
      addInitMapData(hereMap);
    }
  }, [hereMap]);

  useEffect(() => {
    if (hereMap) {
      dataChange({
        address: initialStreetAddress,
        lat: initialLatitude,
        lng: initialLongitude,
        mapData: {
          dotPointsData: dotPointsData,
          linePointsData: linePointsData,
        },
      });
    } else {
      dataChange({
        mapData: {
          dotPointsData: dotPointsData,
          linePointsData: linePointsData,
        },
      });
    }
  }, [initialLongitude, initialLatitude, dotPointsData, linePointsData]);

  React.useLayoutEffect(() => {
    if (!mapRef.current) return;

    const platform = new H.service.Platform({
      apikey: "8cDmzZNa8yMCV7_bl-bN7oYmpnki95nOo5OHYyeCvbw",
    });
    const defaultLayers = platform.createDefaultLayers();
    const hMap = new H.Map(mapRef.current, defaultLayers.raster.terrain.map, {
      center: {
        lat: !isNaN(avglat) ? avglat : 38,
        lng: !isNaN(avglng) ? avglng : -93,
      },
      zoom: 4.1,
      padding: { top: 20, left: 20, bottom: 20, right: 20 },
      pixelRatio: window.devicePixelRatio || 1,
    });

    const ui = H.ui.UI.createDefault(hMap, defaultLayers, "en-US");
    const mapSettings = ui.getControl("mapsettings");
    const zoom = ui.getControl("zoom");
    const scalebar = ui.getControl("scalebar");

    mapSettings.setAlignment("top-left");

    const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(hMap));

    setBehavior(behavior);
    ui.removeControl("mapsettings");

    const ms = new H.ui.MapSettingsControl({
      baseLayers: [
        {
          label: "Normal",
          layer: defaultLayers.raster.normal.map,
        },
        {
          label: "Satellite",
          layer: defaultLayers.raster.satellite.map,
        },
        {
          label: "Terrain",
          layer: defaultLayers.raster.terrain.map,
        },
      ],
      layers: [
        {
          label: "layer.traffic",
          layer: defaultLayers.vector.normal.traffic,
        },
        {
          label: "layer.incidents",
          layer: defaultLayers.vector.normal.trafficincidents,
        },
      ],
    });
    ui.addControl("customized", ms);

    ms.setAlignment("top-left");
    zoom.setAlignment("top-left");
    scalebar.setAlignment("top-left");
    setHereMap(hMap);

    window.addEventListener("resize", function () {
      hMap.getViewPort().resize();
    });

    hMap.addEventListener(
      "dragstart",
      (ev) => {
        dotDragStart(ev);
      },
      false
    );

    hMap.addEventListener(
      "dragend",
      (ev) => {
        dotDragEndEvent(ev);
      },
      false
    );

    hMap.addEventListener(
      "drag",
      (ev) => {
        dotDragEvent(ev);
      },
      false
    );

    const geocoder = platform.getSearchService();

    setGeoCode(geocoder);
    return () => {
      hMap.dispose();
    };
  }, [mapRef]);

  function addInitMapData(map) {
    // let temp = [];
    // let groupObj = new H.map.Group();
    if (dotPoints && dotPoints.length !== 0) {
      // let temp = [...group];
      dotPoints.map((dotPoint, index) => {
        // console.log(dotPoint);
        const markSvgText = svgMarkup
          .replace("${FILL}", "blue")
          .replace("${STROKE}", markColor)
          .replace("${TEXT}", `${index + 1}`);
        let bearsIcon = new H.map.Icon(markSvgText, {
          size: { w: 30, h: 30 },
          anchor: new H.math.Point(15, 15),
        });
        let bearsMarker = new H.map.Marker(
          { lat: dotPoint.lat, lng: dotPoint.lng },
          {
            icon: bearsIcon,
            crossOrigin: false,
            volatility: true,
          }
        );
        bearsMarker.draggable = true;

        bearsMarker.setData({ markerUUID: dotPoint.markerUUID });
        map.addObject(bearsMarker);
        // temp.push(bearsMarker);

        // setGroup([...group, bearsMarker]);
        // temp = [...temp, bearsMarker];

        setDotIndex(index + 1);
        setSelectedMarkerID(dotPoint.markerUUID);
        setSelectedLatitude(dotPoint.lat);
        setSelectedLongitude(dotPoint.lng);
      });
      // setGroup(temp);
    }

    if (linePoints && linePoints.length !== 0) {
      // let temp = [...group];
      linePoints.map((linePoint, index) => {
        let bearsIcon = new H.map.Icon(
          svgLinkMarkup
            .replace("${FILL}", "blue")
            .replace("${STROKE}", lineMarkColor)
            .replace("${TEXT}", `${index + 1}`),
          { size: { w: 30, h: 30 }, anchor: new H.math.Point(15, 15) }
        );

        let bearsRectangleIcon = new H.map.Icon(
          svgRectangleUp
            .replace("${FILL}", "blue")
            .replace("${STROKE}", lineMarkColor)
            .replace("${TEXT}", `${index + 1}`),
          { size: { w: 44, h: 44 }, anchor: new H.math.Point(22, 22) }
        );

        let coordFirst = {
          lat: linePoint.lineFirstPoint.lat,
          lng: linePoint.lineFirstPoint.lng,
        };
        let bearsMarker = new H.map.Marker(coordFirst, {
          icon: bearsIcon,
          crossOrigin: false,
          volatility: true,
        });
        bearsMarker.draggable = true;

        const markerUUID = linePoint.lineFirstPoint.markerUUID;
        const polyLineUUID = linePoint.polyLineUUID;
        const groupID = linePoint.groupID;
        const verticeGroupID = linePoint.verticeGroupID;

        bearsMarker.setData({
          markerUUID,
          polyLineUUID,
          groupID,
          verticeGroupID,
        });

        // hereMap.addObject(bearsMarker);

        setFirstMarkerID(markerUUID);

        let coord = {
          lat: linePoint.lineSecondPoint.lat,
          lng: linePoint.lineSecondPoint.lng,
        };

        let lineString = new H.geo.LineString();

        lineString.pushPoint(coordFirst);
        lineString.pushPoint(coord);

        let polyLine = new H.map.Polyline(lineString, {
          style: { lineWidth: 4, strokeColor: lineColor },
        });

        polyLine.setData({ polyLineUUID });

        setFirstMarkerID("");

        let verticeGroup = new H.map.Group({
          visibility: true,
        });

        verticeGroup.setData({ groupID, verticeGroupID });

        let mainGroup = new H.map.Group({
          volatility: true,
          objects: [polyLine, verticeGroup],
        });

        mainGroup.setData({ groupID });
        polyLine.draggable = true;

        let lastMarkerUUID = linePoint.lineSecondPoint.markerUUID;

        polyLine.getGeometry().eachLatLngAlt(function (lat, lng, alt, index) {
          let lastIcon = coord.lat === lat && coord.lng === lng ? 1 : 0;
          let vertice = new H.map.Marker(
            { lat, lng },
            {
              icon: lastIcon ? bearsRectangleIcon : bearsIcon,
              crossOrigin: false,
              volatility: true,
            }
          );

          vertice.draggable = true;

          if (coord.lat === lat && coord.lng === lng) {
            vertice.setData({
              verticeIndex: index,
              markerUUID: lastMarkerUUID,
              groupID: groupID,
              verticeGroupID: verticeGroupID,
              polyLineUUID: polyLineUUID,
            });
          } else {
            vertice.setData({
              verticeIndex: index,
              markerUUID: markerUUID,
              groupID: groupID,
              verticeGroupID: verticeGroupID,
              polyLineUUID: polyLineUUID,
            });
          }

          verticeGroup.addObject(vertice);

          // temp = [...temp, verticeGroup];
        });

        let cloneLinePointsData = linePointsData.slice(0);

        const foundIndex = _.findIndex(
          linePointsData,
          (r) => r.polyLineUUID === polyLineUUID
        );

        if (foundIndex !== -1) {
          if (!cloneLinePointsData[foundIndex].lineSecondPoint) {
            cloneLinePointsData[foundIndex].lineSecondPoint = {
              markerUUID: lastMarkerUUID,
              lat: coord.lat,
              lng: coord.lng,
            };
            setLinePointsData(cloneLinePointsData);
          }
        }

        setSelectedMarkerID(lastMarkerUUID);
        setSelectedLatitude(coord.lat);
        setSelectedLongitude(coord.lng);

        // hereMap.getViewModel().setLookAtData({
        //   bounds: mainGroup.getBoundingBox(),
        // });
        hereMap.addObject(mainGroup);
        // temp = [...temp, mainGroup];

        setLineIndex(index + 1);
        setMode(0);
      });
      // setGroup(temp);
    }

    if ((linePoints || dotPoints) && (avglat || avglng)) {
      map.getViewModel().setLookAtData({
        bounds: new H.geo.Rect(avglat.min, avglng.min, avglat.max, avglng.max),
      });
    }
  }

  const tapListener = (evt) => {
    console.log("tap event");
    if (evt.originalEvent.which !== 1) return;
    const H = window.H;

    const coord = hereMap.screenToGeo(
      evt.currentPointer.viewportX,
      evt.currentPointer.viewportY
    );

    if (evt) {
      const target = evt.target;

      if (target instanceof H.map.Marker) {
        return;
      }
    }

    if (mode === 1) {
      const markSvgText = svgMarkup
        .replace("${FILL}", "blue")
        .replace("${STROKE}", markColor)
        .replace("${TEXT}", `${dotIndex + 1}`);
      let bearsIcon = new H.map.Icon(markSvgText, {
        size: { w: 30, h: 30 },
        anchor: new H.math.Point(15, 15),
      });
      let bearsMarker = new H.map.Marker(coord, {
        icon: bearsIcon,
        crossOrigin: false,
        volatility: true,
      });
      bearsMarker.draggable = true;

      let markerUUID = uuid.v1();

      bearsMarker.setData({ markerUUID });
      hereMap.addObject(bearsMarker);

      setDotIndex(dotIndex + 1);
      setSelectedMarkerID(markerUUID);
      setSelectedLatitude(coord.lat);
      setSelectedLongitude(coord.lng);
      setDotPointsData([
        ...dotPointsData,
        { lat: coord.lat, lng: coord.lng, markerUUID },
      ]);
      setMode(0);
    } else if (mode === 2) {
      let bearsIcon = new H.map.Icon(
        svgLinkMarkup
          .replace("${FILL}", "blue")
          .replace("${STROKE}", lineMarkColor)
          .replace("${TEXT}", `${lineIndex + 1}`),
        { size: { w: 30, h: 30 }, anchor: new H.math.Point(15, 15) }
      );

      let bearsRectangleIcon = new H.map.Icon(
        svgRectangleUp
          .replace("${FILL}", "blue")
          .replace("${STROKE}", lineMarkColor)
          .replace("${TEXT}", `${lineIndex + 1}`),
        { size: { w: 44, h: 44 }, anchor: new H.math.Point(22, 22) }
      );
      if (!firstMarkerID) {
        let bearsMarker = new H.map.Marker(coord, {
          icon: bearsIcon,
          crossOrigin: false,
          volatility: true,
        });
        bearsMarker.draggable = true;

        const markerUUID = uuid.v1();
        const polyLineUUID = uuid.v1();
        const groupID = uuid.v1();
        const verticeGroupID = uuid.v1();

        bearsMarker.setData({
          markerUUID,
          polyLineUUID,
          groupID,
          verticeGroupID,
        });
        hereMap.addObject(bearsMarker);

        let lineData = {
          polyLineUUID,
          groupID,
          verticeGroupID,
          lineFirstPoint: { markerUUID, lat: coord.lat, lng: coord.lng },
          lineSecondPoint: null,
        };

        setLinePointsData([...linePointsData, lineData]);

        setSelectedMarkerID(markerUUID);
        setSelectedLatitude(coord.lat);
        setSelectedLongitude(coord.lng);

        setTimeout(() => {
          setFirstMarkerID(markerUUID);
        }, 10);
      } else {
        let lineString = new H.geo.LineString();

        let foundMarker = findSingleMarker(firstMarkerID);
        let polyLineUUID = null;
        let groupID = null;
        let verticeGroupID = null;

        if (foundMarker) {
          lineString.pushPoint({
            lat: foundMarker.getGeometry().lat,
            lng: foundMarker.getGeometry().lng,
          });

          const data = foundMarker.getData();
          polyLineUUID = data.hasOwnProperty("polyLineUUID")
            ? data.polyLineUUID
            : null;
          groupID = data.hasOwnProperty("groupID") ? data.groupID : null;
          verticeGroupID = data.hasOwnProperty("verticeGroupID")
            ? data.verticeGroupID
            : null;

          hereMap.removeObject(foundMarker);
        }

        lineString.pushPoint(coord);

        let polyLine = new H.map.Polyline(lineString, {
          style: { lineWidth: 4, strokeColor: lineColor },
        });

        polyLine.setData({ polyLineUUID });

        setFirstMarkerID("");

        let verticeGroup = new H.map.Group({
          visibility: true,
        });

        verticeGroup.setData({ groupID, verticeGroupID });

        let mainGroup = new H.map.Group({
          volatility: true,
          objects: [polyLine, verticeGroup],
        });

        mainGroup.setData({ groupID });
        polyLine.draggable = true;

        let lastMarkerUUID = uuid.v1();

        polyLine.getGeometry().eachLatLngAlt(function (lat, lng, alt, index) {
          let lastIcon = coord.lat === lat && coord.lng === lng ? 1 : 0;
          let vertice = new H.map.Marker(
            { lat, lng },
            {
              icon: lastIcon ? bearsRectangleIcon : bearsIcon,
              crossOrigin: false,
              volatility: true,
            }
          );

          vertice.draggable = true;

          if (coord.lat === lat && coord.lng === lng) {
            vertice.setData({
              verticeIndex: index,
              markerUUID: lastMarkerUUID,
              groupID: groupID,
              verticeGroupID: verticeGroupID,
              polyLineUUID: polyLineUUID,
            });
          } else {
            vertice.setData({
              verticeIndex: index,
              markerUUID: firstMarkerID,
              groupID: groupID,
              verticeGroupID: verticeGroupID,
              polyLineUUID: polyLineUUID,
            });
          }

          verticeGroup.addObject(vertice);
        });

        let cloneLinePointsData = linePointsData.slice(0);

        const foundIndex = _.findIndex(
          linePointsData,
          (r) => r.polyLineUUID === polyLineUUID
        );

        if (foundIndex !== -1) {
          if (!cloneLinePointsData[foundIndex].lineSecondPoint) {
            cloneLinePointsData[foundIndex].lineSecondPoint = {
              markerUUID: lastMarkerUUID,
              lat: coord.lat,
              lng: coord.lng,
            };
            setLinePointsData(cloneLinePointsData);
          }
        }

        setSelectedMarkerID(lastMarkerUUID);
        setSelectedLatitude(coord.lat);
        setSelectedLongitude(coord.lng);

        hereMap.addObject(mainGroup);

        setLineIndex(lineIndex + 1);
        setMode(0);
      }
    }
  };

  const handleAddressSearch = (e) => {
    e.preventDefault();
    if (hereMap) {
      if (initialStreetAddress && geocode) {
        const geocodingParameters = {
          q: initialStreetAddress.trim(),
        };

        geocode.geocode(
          geocodingParameters,
          (result) => {
            if (result && result.items && result.items.length > 0) {
              hereMap.setCenter({
                lat: result.items[0].position.lat,
                lng: result.items[0].position.lng,
              });
              // var locationMarker = new H.map.Marker({
              //   lat: result.items[0].position.lat,
              //   lng: result.items[0].position.lng
              // });
              // hereMap.addObject(locationMarker);
              setInitialLongitude(result.items[0].position.lng);
              setInitialLatitude(result.items[0].position.lat);
              hereMap.setZoom(14);
              setOpen(false);
            }
          },
          (error) => {}
        );
      }
    }
  };

  const handleCoordinatesSearch = () => {
    if (hereMap) {
      if (initialLatitude && initialLongitude) {
        hereMap.setCenter({
          lat: initialLatitude,
          lng: initialLongitude,
        });
        hereMap.setZoom(14);
        // var locationMarker = new H.map.Marker({ lat: initialLatitude, lng: initialLongitude });
        // hereMap.addObject(locationMarker);
        setOpen(false);
      }
    }
  };

  //fields
  const newFields = () => {
    return (
      <>
        <div className="container">
          {/* <form className="p-3">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Name</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="name" name="name" {...register("name")} onChange={formChange} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Work Order</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="workOrder" name="workOrder" {...register("workOrder")} onChange={formChange} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Site</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="site" name="site" {...register("site")} onChange={formChange} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Area</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="area" name="area" {...register("area")} onChange={formChange} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Tag</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="tag" name="tag" {...register("tag")} onChange={formChange} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Weather</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="weather" name="weather" {...register("weather")} onChange={formChange} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Temperature °C</label>
                  <div className="col-sm-8">
                    <input type="number" className="form-control form-control-sm" id="colFormLabelSm" placeholder="temperatureDegC" name="temperatureDegC" {...register("temperatureDegC")} onChange={formChange} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Humidity %</label>
                  <div className="col-sm-8">
                    <input type="number" className="form-control form-control-sm" id="colFormLabelSm" placeholder="humidityPercent" name="humidityPercent" {...register("humidityPercent")} onChange={formChange} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Soil Type</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="soilType" name="soilType" {...register("soilType")} onChange={formChange} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Soil Condition</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="soilCondition" name="soilCondition" {...register("soilCondition")} onChange={formChange} />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Fill</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="fill" name="fill" {...register("fill")} onChange={formChange} />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Soil</label>
                  <div className="col-sm-8">
                    <input type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="soil" name="soil" {...register("soil")} onChange={formChange} />
                  </div>
                </div>
              </div>
            </div>
          </form> */}

          <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label
                  htmlFor="colFormLabelSm"
                  className="col-sm-12 col-form-label col-form-label-sm"
                >
                  Address
                </label>
                <div className="col-sm-9">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="colFormLabelSm"
                    placeholder="address"
                    onChange={(event) => {
                      let { value } = event.target;
                      setInitialStreetAddress(value);
                    }}
                  />
                </div>
                <div className="col-sm-3">
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={handleAddressSearch}
                  >
                    <i className="fa fa-search" aria-hidden="true"></i>
                    <span className="ml-1">Search</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Latitude</label>
                <div className="col-sm-8">
                  <input disabled type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="lat" value={initialLatitude} />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group row">
                <label htmlFor="colFormLabelSm" className="col-sm-3 col-form-label col-form-label-sm">Longitude</label>
                <div className="col-sm-8">
                  <input disabled type="text" className="form-control form-control-sm" id="colFormLabelSm" value={initialLongitude} placeholder="lng" />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitData = (formD) => {
    // console.log(formData);
    console.log(formD);
    const data = {
      // id: "UUID",
      companyId: "company",
      name: "My Company",
      workOrder: "workOrder",
      site: "site",
      area: "area",
      tag: "tag",
      temperatureDegC: 23.23,
      humidityPercent: 50.2,
      weather: "weather",
      soilType: "soilType",
      soilCondition: "soilCondition",
      soil: "soil",
      fill: "fill",
      lat: initialLatitude,
      lng: initialLongitude,
      // createdAt: new Date().toISOString(),
      createdBy: "UUID",
      // updatedAt: new Date().toISOString(),
      updatedBy: "UUID",
      mapData: {
        dotPointsData: dotPointsData,
        linePointsData: linePointsData,
      },
    };
    alert(JSON.stringify(data, null, 3));
  };

  const dragMarkerTreat = (target) => {
    // console.log("dragMarkerTreat");
    const data = target.getData();
    const geometry = target.getGeometry();
    const markerUUID = data.markerUUID;
    const polyLineUUID = data.hasOwnProperty("polyLineUUID")
      ? data.polyLineUUID
      : null;

    if (polyLineUUID) {
      const foundIndex = _.findIndex(
        linePointsData,
        (r) => r.polyLineUUID === polyLineUUID
      );

      if (foundIndex !== -1) {
        let cloneLinePointsData = linePointsData.slice(0);
        let bUpdated = false;
        if (cloneLinePointsData[foundIndex].lineFirstPoint) {
          if (
            cloneLinePointsData[foundIndex].lineFirstPoint.markerUUID ===
            markerUUID
          ) {
            cloneLinePointsData[foundIndex].lineFirstPoint.lat = geometry.lat;
            cloneLinePointsData[foundIndex].lineFirstPoint.lng = geometry.lng;

            setLinePointsData(cloneLinePointsData);

            bUpdated = true;
          }
        }

        if (!bUpdated) {
          if (cloneLinePointsData[foundIndex].lineSecondPoint) {
            if (
              cloneLinePointsData[foundIndex].lineSecondPoint.markerUUID ===
              markerUUID
            ) {
              cloneLinePointsData[foundIndex].lineSecondPoint.lat =
                geometry.lat;
              cloneLinePointsData[foundIndex].lineSecondPoint.lng =
                geometry.lng;

              setLinePointsData(cloneLinePointsData);
            }
          }
        }
      }
    } else {
      const foundIndex = _.findIndex(
        dotPointsData,
        (r) => r.markerUUID === markerUUID
      );
      if (foundIndex !== -1) {
        let cloneDotPointsData = dotPointsData.slice(0);

        cloneDotPointsData[foundIndex].lat = geometry.lat;
        cloneDotPointsData[foundIndex].lng = geometry.lng;

        setDotPointsData(cloneDotPointsData);
      }
    }
  };

  const dotDragStart = (ev) => {
    // console.log("dotDragStart");
    var target = ev.target,
      pointer = ev.currentPointer;
    if (hereMap) {
      if (target instanceof H.map.Marker) {
        var targetPosition = hereMap.geoToScreen(target.getGeometry());
        target["offset"] = new H.math.Point(
          pointer.viewportX - targetPosition.x,
          pointer.viewportY - targetPosition.y
        );
        dragMarkerTreat(target);
        behavior.disable();
      }
    }
  };

  const dotDragEndEvent = (ev) => {
    // console.log("dotDragEnd");
    var target = ev.target;
    if (hereMap && behavior) {
      if (target instanceof H.map.Marker) {
        behavior.enable();

        dragMarkerTreat(target);
      }
    }
  };

  const dotDragEvent = (evt) => {
    // console.log("Dot drag event");
    var target = evt.target,
      pointer = evt.currentPointer;

    if (hereMap) {
      if (target instanceof H.map.Marker) {
        target.setGeometry(
          hereMap.screenToGeo(
            pointer.viewportX - target["offset"].x,
            pointer.viewportY - target["offset"].y
          )
        );

        dragMarkerTreat(target);

        const data = target.getData();

        if (data.hasOwnProperty("polyLineUUID")) {
          let polyLine = getPolyLine(data.polyLineUUID);

          if (polyLine) {
            let geoLineString = polyLine.getGeometry(),
              geoPoint = hereMap.screenToGeo(
                pointer.viewportX,
                pointer.viewportY
              );
            evt.target.setGeometry(geoPoint);
            geoLineString.removePoint(evt.target.getData()["verticeIndex"]);
            geoLineString.insertPoint(
              evt.target.getData()["verticeIndex"],
              geoPoint
            );
            polyLine.setGeometry(geoLineString);
          }
        }
      }
    }
  };

  const getPolyLine = (polyLineUUID) => {
    const mapObjects = hereMap.getObjects(true);
    let foundPolyLine = null;

    for (let i = 0; i < mapObjects.length; i++) {
      if (mapObjects[i] && mapObjects[i] instanceof H.map.Polyline) {
        const selectedMarker = mapObjects[i];

        const firstMarkerData = selectedMarker.getData();

        if (firstMarkerData.polyLineUUID === polyLineUUID) {
          foundPolyLine = mapObjects[i];

          break;
        }
      }
    }

    return foundPolyLine;
  };

  const removeDotMark = (index) => {
    const deletedMark = dotPointsData.splice(index, 1);

    if (hereMap) {
      var foundMarker = null;
      const mapObjects = hereMap.getObjects(true);

      for (let i = 0; i < mapObjects.length; i++) {
        if (mapObjects[i] instanceof H.map.Marker) {
          const selectedMarker = mapObjects[i];

          const firstMarkerData = selectedMarker.getData();

          if (firstMarkerData.markerUUID === deletedMark[0].markerUUID) {
            foundMarker = mapObjects[i];

            break;
          }
        }
      }
      if (foundMarker) {
        hereMap.removeObject(foundMarker);
      }

      const markerObjects = hereMap.getObjects(true);

      for (let i = 0; i < dotPointsData.length; i++) {
        var foundMarker = null;

        for (let j = 0; j < markerObjects.length; j++) {
          if (markerObjects[j] instanceof H.map.Marker) {
            const firstMarkerData = markerObjects[j].getData();

            if (firstMarkerData) {
              if (firstMarkerData.markerUUID === dotPointsData[i].markerUUID) {
                foundMarker = markerObjects[i];

                break;
              }
            }
          }
        }

        if (foundMarker) {
          const markSvgText = svgMarkup
            .replace("${FILL}", "blue")
            .replace("${STROKE}", markColor)
            .replace("${TEXT}", `${i + 1}`);
          let bearsIcon = new H.map.Icon(markSvgText, {
            size: { w: 30, h: 30 },
            anchor: new H.math.Point(15, 15),
          });

          foundMarker.setIcon(bearsIcon);
        }
      }
    }

    setDotPointsData(dotPointsData);
    setDotIndex(dotIndex - 1);
  };

  React.useEffect(() => {
    if (hereMap) {
      hereMap.addEventListener("tap", tapListener);
      hereMap.addEventListener("drag", dotDragEvent);
      hereMap.addEventListener("dragstart", dotDragStart);
      hereMap.addEventListener("dragend", dotDragEndEvent);
      return () => {
        hereMap.removeEventListener("tap", tapListener);
        hereMap.removeEventListener("drag", dotDragEvent);
        hereMap.removeEventListener("dragstart", dotDragStart);
        hereMap.removeEventListener("dragend", dotDragEndEvent);
      };
    }

    setFirstMarkerID("");
  }, [mode, firstMarkerID, dotIndex, updateComponent, pointMode, lineIndex]);

  const locationSave = () => {
    if (
      !selectedLatitude ||
      !selectedLongitude ||
      !selectedMarkerID ||
      !hereMap
    ) {
      return;
    }

    let foundMarker = null;

    hereMap.getObjectsWithin(
      hereMap.getViewModel().getLookAtData().bounds,
      (objects) => {
        for (let i = 0; i < objects.length; i++) {
          if (objects[i] instanceof H.map.Marker) {
            const selectedMarker = objects[i];

            const firstMarkerData = selectedMarker.getData();

            if (firstMarkerData.markerUUID === selectedMarkerID) {
              foundMarker = objects[i];

              break;
            }
          }
        }

        if (foundMarker) {
          const selectedMarkerData = foundMarker.getData();

          if (selectedMarkerData) {
            if (selectedMarkerData.hasOwnProperty("polyLineUUID")) {
              let focusedPolyLine = null;
              for (let i = 0; i < objects.length; i++) {
                if (objects[i] instanceof H.map.Polyline) {
                  const selectedPolyLine = objects[i];
                  const selectedPolyLineData = selectedPolyLine.getData();

                  if (
                    selectedPolyLineData.polyLineUUID ===
                    selectedMarkerData.polyLineUUID
                  ) {
                    focusedPolyLine = selectedPolyLine;

                    break;
                  }
                }
              }

              if (focusedPolyLine) {
                let geoLineString = focusedPolyLine.getGeometry();
                foundMarker.setGeometry({
                  lat: selectedLatitude,
                  lng: selectedLongitude,
                });

                geoLineString.removePoint(
                  foundMarker.getData()["verticeIndex"]
                );
                geoLineString.insertPoint(
                  foundMarker.getData()["verticeIndex"],
                  { lat: selectedLatitude, lng: selectedLongitude }
                );
                focusedPolyLine.setGeometry(geoLineString);
              }
            } else {
              foundMarker.setGeometry({
                lat: selectedLatitude,
                lng: selectedLongitude,
              });
            }
          }
        }
      }
    );
  };

  const findSingleMarker = (markerUUID) => {
    if (!hereMap) {
      return null;
    }

    let foundMarker = null;

    let markObjects = hereMap.getObjects(true);

    if (markObjects.length > 0) {
      for (let i = 0; i < markObjects.length; i++) {
        if (markObjects[i] instanceof H.map.Marker) {
          const firstMarker = markObjects[i];

          const firstMarkerData = firstMarker.getData();

          if (firstMarkerData.markerUUID === markerUUID) {
            foundMarker = markObjects[i];

            break;
          }
        }
      }
    }

    return foundMarker;
  };

  const findPolyLine = (polyLineUUID) => {
    if (!hereMap) {
      return null;
    }

    let foundLine = null;

    let markObjects = hereMap.getObjects(true);

    if (markObjects.length > 0) {
      for (let i = 0; i < markObjects.length; i++) {
        if (markObjects[i] instanceof H.map.Polyline) {
          const polyLine = markObjects[i];

          const polyLineData = polyLine.getData();

          if (polyLineData.polyLineUUID === polyLineUUID) {
            foundLine = markObjects[i];

            break;
          }
        }
      }
    }

    return foundLine;
  };

  const onChangeLat = (target, index) => {
    const value = target.value;

    if (value === "") return;

    let selectedMarker = Object.assign({}, dotPointsData[index]);

    if (unitMode === 0) {
      const min = -85;
      const max = 85;
      const maxValue = Math.max(
        Number(min),
        Math.min(Number(max), Number(value))
      );

      selectedMarker.lat = maxValue;

      let cloneDotPointData = dotPointsData.slice(0);

      cloneDotPointData[index] = selectedMarker;

      setDotPointsData(cloneDotPointData);
      setUpdateComponent(!updateComponent);

      let foundMarker = findSingleMarker(selectedMarker.markerUUID);

      if (foundMarker) {
        foundMarker.setGeometry({
          lat: selectedMarker.lat,
          lng: selectedMarker.lng,
        });
      }
    }
  };

  const onChangeLng = (target, index) => {
    const value = target.value;
    if (value === "") return;

    let selectedMarker = Object.assign({}, dotPointsData[index]);
    if (unitMode === 0) {
      const min = -180;
      const max = 180;
      const maxValue = Math.max(
        Number(min),
        Math.min(Number(max), Number(value))
      );

      selectedMarker.lng = maxValue;

      let cloneDotPointData = dotPointsData.slice(0);

      cloneDotPointData[index] = selectedMarker;

      setDotPointsData(cloneDotPointData);
      setUpdateComponent(!updateComponent);

      let foundMarker = findSingleMarker(selectedMarker.markerUUID);

      if (foundMarker) {
        foundMarker.setGeometry({
          lat: selectedMarker.lat,
          lng: selectedMarker.lng,
        });
      }
    }
  };

  const onChangeLineFirstLat = (target, index) => {
    const value = target.value;

    if (value === "") return;

    let cloneLinePointsData = linePointsData.slice(0);
    let markerUUID = cloneLinePointsData[index].lineFirstPoint.markerUUID;
    const min = -85;
    const max = 85;
    const maxValue = Math.max(
      Number(min),
      Math.min(Number(max), Number(value))
    );

    cloneLinePointsData[index].lineFirstPoint.lat = maxValue;

    setLinePointsData(cloneLinePointsData);
    setUpdateComponent(!updateComponent);
    let foundMarker = findSingleMarker(markerUUID);

    if (foundMarker) {
      foundMarker.setGeometry({
        lat: maxValue,
        lng: cloneLinePointsData[index].lineFirstPoint.lng,
      });
    }

    let focusedPolyLine = findPolyLine(cloneLinePointsData[index].polyLineUUID);
    if (focusedPolyLine && foundMarker) {
      let geoLineString = focusedPolyLine.getGeometry();

      geoLineString.removePoint(foundMarker.getData()["verticeIndex"]);
      geoLineString.insertPoint(foundMarker.getData()["verticeIndex"], {
        lat: maxValue,
        lng: cloneLinePointsData[index].lineFirstPoint.lng,
      });
      focusedPolyLine.setGeometry(geoLineString);
    }
  };

  const onChangeLineFirstLng = (target, index) => {
    const value = target.value;

    if (value === "") return;

    let cloneLinePointsData = linePointsData.slice(0);
    let markerUUID = cloneLinePointsData[index].lineFirstPoint.markerUUID;
    const min = -180;
    const max = 180;
    const maxValue = Math.max(
      Number(min),
      Math.min(Number(max), Number(value))
    );

    cloneLinePointsData[index].lineFirstPoint.lng = maxValue;

    setLinePointsData(cloneLinePointsData);
    setUpdateComponent(!updateComponent);
    let foundMarker = findSingleMarker(markerUUID);

    if (foundMarker) {
      foundMarker.setGeometry({
        lat: cloneLinePointsData[index].lineFirstPoint.lat,
        lng: maxValue,
      });
    }
    findPolyLine(cloneLinePointsData[index].polyLineUUID).then(
      (focusedPolyLine) => {
        if (focusedPolyLine && foundMarker) {
          let geoLineString = focusedPolyLine.getGeometry();

          geoLineString.removePoint(foundMarker.getData()["verticeIndex"]);
          geoLineString.insertPoint(foundMarker.getData()["verticeIndex"], {
            lat: cloneLinePointsData[index].lineFirstPoint.lat,
            lng: maxValue,
          });
          focusedPolyLine.setGeometry(geoLineString);
        }
      }
    );
  };

  const onChangeLineSecondLat = (target, index) => {
    const value = target.value;

    if (value === "") return;

    let cloneLinePointsData = linePointsData.slice(0);

    if (!cloneLinePointsData[index].lineSecondPoint) return;

    let markerUUID = cloneLinePointsData[index].lineSecondPoint.markerUUID;
    const min = -85;
    const max = 85;
    const maxValue = Math.max(
      Number(min),
      Math.min(Number(max), Number(value))
    );

    cloneLinePointsData[index].lineSecondPoint.lat = maxValue;

    setLinePointsData(cloneLinePointsData);
    setUpdateComponent(!updateComponent);
    let foundMarker = findSingleMarker(markerUUID);

    if (foundMarker) {
      foundMarker.setGeometry({
        lat: maxValue,
        lng: cloneLinePointsData[index].lineSecondPoint.lng,
      });
    }

    let focusedPolyLine = findPolyLine(cloneLinePointsData[index].polyLineUUID);
    if (focusedPolyLine && foundMarker) {
      let geoLineString = focusedPolyLine.getGeometry();

      geoLineString.removePoint(foundMarker.getData()["verticeIndex"]);
      geoLineString.insertPoint(foundMarker.getData()["verticeIndex"], {
        lat: maxValue,
        lng: cloneLinePointsData[index].lineSecondPoint.lng,
      });
      focusedPolyLine.setGeometry(geoLineString);
    }
  };

  const onChangeLineSecondLng = (target, index) => {
    const value = target.value;

    if (value === "") return;

    let cloneLinePointsData = linePointsData.slice(0);

    if (!cloneLinePointsData[index].lineSecondPoint) return;

    let markerUUID = cloneLinePointsData[index].lineSecondPoint.markerUUID;
    const min = -180;
    const max = 180;
    const maxValue = Math.max(
      Number(min),
      Math.min(Number(max), Number(value))
    );

    cloneLinePointsData[index].lineSecondPoint.lng = maxValue;

    setLinePointsData(cloneLinePointsData);
    setUpdateComponent(!updateComponent);
    let foundMarker = findSingleMarker(markerUUID);

    if (foundMarker) {
      foundMarker.setGeometry({
        lat: cloneLinePointsData[index].lineSecondPoint.lat,
        lng: maxValue,
      });
    }
    findPolyLine(cloneLinePointsData[index].polyLineUUID).then(
      (focusedPolyLine) => {
        if (focusedPolyLine && foundMarker) {
          let geoLineString = focusedPolyLine.getGeometry();

          geoLineString.removePoint(foundMarker.getData()["verticeIndex"]);
          geoLineString.insertPoint(foundMarker.getData()["verticeIndex"], {
            lat: cloneLinePointsData[index].lineSecondPoint.lat,
            lng: maxValue,
          });
          focusedPolyLine.setGeometry(geoLineString);
        }
      }
    );
  };

  const gotoPointer = (index) => {
    console.log(index + 1);

    if (hereMap) {
      if (dotPointsData.length > index) {
        hereMap.setCenter({
          lat: dotPointsData[index].lat,
          lng: dotPointsData[index].lng,
        });
      }
    }
  };

  const gotoFirstPointer = (index) => {
    console.log(index + 1);

    if (hereMap) {
      if (linePointsData.length > index) {
        if (
          linePointsData[index].lineFirstPoint &&
          linePointsData[index].lineSecondPoint
        ) {
          const firstLat =
            linePointsData[index].lineFirstPoint.lat >
            linePointsData[index].lineSecondPoint.lat
              ? linePointsData[index].lineSecondPoint.lat
              : linePointsData[index].lineFirstPoint.lat;
          const firstLng =
            linePointsData[index].lineFirstPoint.lng >
            linePointsData[index].lineSecondPoint.lng
              ? linePointsData[index].lineSecondPoint.lng
              : linePointsData[index].lineFirstPoint.lng;
          const secondLat =
            linePointsData[index].lineFirstPoint.lat <
            linePointsData[index].lineSecondPoint.lat
              ? linePointsData[index].lineSecondPoint.lat
              : linePointsData[index].lineFirstPoint.lat;
          const secondLng =
            linePointsData[index].lineFirstPoint.lng <
            linePointsData[index].lineSecondPoint.lng
              ? linePointsData[index].lineSecondPoint.lng
              : linePointsData[index].lineFirstPoint.lng;

          const diffLat = (secondLat - firstLat) / 5;
          const diffLng = (secondLng - firstLng) / 5;
          var bbox = new H.geo.Rect(
            firstLat - diffLat,
            firstLng - diffLng,
            secondLat + diffLat,
            secondLng + diffLng
          );

          hereMap.getViewModel().setLookAtData({ bounds: bbox });
        } else {
          if (linePointsData[index].lineFirstPoint) {
            hereMap.setCenter({
              lat: linePointsData[index].lineFirstPoint.lat,
              lng: linePointsData[index].lineFirstPoint.lng,
            });
          }
        }
      }
    }
  };

  const deleteLine = (index) => {
    if (linePointsData.length <= index) return;

    const deletedLine = linePointsData.splice(index, 1);

    if (hereMap) {
      let shouldDelete = [];

      const mapFullObjects = hereMap.getObjects(true);

      if (deletedLine[0].lineSecondPoint) {
        for (let i = 0; i < mapFullObjects.length; i++) {
          if (mapFullObjects[i] instanceof H.map.Group) {
            const selectedMarker = mapFullObjects[i];

            const selectedMarkerData = selectedMarker.getData();

            if (selectedMarkerData.hasOwnProperty("verticeGroupID")) {
              continue;
            }
            if (selectedMarkerData.groupID === deletedLine[0].groupID) {
              shouldDelete.push(mapFullObjects[i]);
            }
          }
        }
        if (shouldDelete.length > 0) {
          for (let i = 0; i < shouldDelete.length; i++) {
            hereMap.removeObject(shouldDelete[i]);
          }
        }
      } else {
        var foundMarker = null;

        for (let i = 0; i < mapFullObjects.length; i++) {
          if (mapFullObjects[i] instanceof H.map.Marker) {
            const firstMarkerData = mapFullObjects[i].getData();

            if (firstMarkerData) {
              if (
                firstMarkerData.markerUUID ===
                deletedLine[0].lineFirstPoint.markerUUID
              ) {
                foundMarker = mapFullObjects[i];

                break;
              }
            }
          }
        }

        if (foundMarker) {
          hereMap.removeObject(foundMarker);
        }
      }

      for (let i = 0; i < linePointsData.length; i++) {
        const firstPoint = linePointsData[i].lineFirstPoint;
        const secondPoint = linePointsData[i].lineSecondPoint;

        if (firstPoint) {
          var foundMarker = findSingleMarker(firstPoint.markerUUID);

          if (foundMarker) {
            const markSvgText = svgLinkMarkup
              .replace("${FILL}", "blue")
              .replace("${STROKE}", lineMarkColor)
              .replace("${TEXT}", `${i + 1}`);
            let bearsIcon = new H.map.Icon(markSvgText, {
              size: { w: 30, h: 30 },
              anchor: new H.math.Point(15, 15),
            });

            foundMarker.setIcon(bearsIcon);
          }
        }

        if (secondPoint) {
          var foundMarker = findSingleMarker(secondPoint.markerUUID);

          if (foundMarker) {
            const markSvgText = svgRectangleUp
              .replace("${FILL}", "blue")
              .replace("${STROKE}", lineMarkColor)
              .replace("${TEXT}", `${i + 1}`);
            let bearsIcon = new H.map.Icon(markSvgText, {
              size: { w: 44, h: 44 },
              anchor: new H.math.Point(22, 22),
            });

            foundMarker.setIcon(bearsIcon);
          }
        }
      }
    }

    setLineIndex(lineIndex - 1);
    setUpdateComponent(!updateComponent);
    setLinePointsData(linePointsData);
  };

  return (
    <div>
      {newFields()}
      {/* <SurveyLocationFields /> */}
      <div
        className={classes.hereMapContainer}
        style={{ height: height + "px" }}
      >
        <div
          className="map"
          ref={mapRef}
          style={{
            height: height + "px",
            width: "calc(100% - 400px)",
            marginRight: "5px",
          }}
        />
        {/* {!open ? <button className="btn btn-info" onClick={handleOpen}>{"Open"}</button> : null} */}
        {/* // <button className="btn btn-danger" onClick={handleClose}>{"Close"}</button>} */}

        <div
          className={classes.tabContainer}
          style={{ height: (height ? height : 0) - 30 + "px" }}
        >
          <div className={classes.tableContainer}>
            <div className={classes.tabHeadContainer}>
              <div
                className={classes.tabHeadSubContainer}
                style={
                  pointMode === 0
                    ? {
                        border: "1px solid grey",
                        borderRadius: "2px",
                        backgroundColor: "#ededed",
                      }
                    : {
                        border: "1px solid grey",
                        borderRadius: "2px",
                      }
                }
                onClick={() => setPointMode(0)}
              >
                Data Points
              </div>
              <div
                className={classes.tabHeadSubContainer}
                style={
                  pointMode === 1
                    ? {
                        border: "1px solid grey",
                        borderRadius: "2px",
                        backgroundColor: "#ededed",
                      }
                    : {
                        border: "1px solid grey",
                        borderRadius: "2px",
                      }
                }
                onClick={() => setPointMode(1)}
              >
                Data Lines
              </div>
            </div>
            <div className={classes.tabLatLngSelectContainer}>
              <div
                className={classes.tabLatLngSelectTypeContainer}
                style={
                  unitMode === 0
                    ? {
                        border: "1px solid grey",
                        borderRadius: "2px",
                        backgroundColor: "#ededed",
                      }
                    : {
                        border: "1px solid grey",
                        borderRadius: "2px",
                      }
                }
                onClick={() => setUnitMode(0)}
              >
                LAT/LNG
              </div>
              {/* <div className={classes.tabLatLngDivider}></div> */}
              <div
                className={classes.tabLatLngSelectTypeContainer}
                style={
                  unitMode === 1
                    ? {
                        border: "1px solid grey",
                        borderRadius: "2px",
                        backgroundColor: "#ededed",
                      }
                    : {
                        border: "1px solid grey",
                        borderRadius: "2px",
                      }
                }
                onClick={() => setUnitMode(1)}
              >
                UTM
              </div>
            </div>
            <div className={classes.tableFullContainer}>
              {pointMode === 0 && (
                <>
                  <div className={classes.tableHeader}>
                    <div className={classes.tableSmallHeader}></div>
                    <div className={classes.tableSmallHeader}>#</div>
                    <div className={classes.tableBigWithBorderHeader}>
                      {unitMode === 0 ? "Latitude" : "Easting"}
                    </div>
                    <div className={classes.tableBigHeader}>
                      {unitMode === 0 ? "Longitude" : "Northing"}
                    </div>
                  </div>
                  <div className={classes.tableBody}>
                    <div>
                      {dotPointsData.length > 0 &&
                        dotPointsData.map((dotItem, index) => {
                          let lat = dotItem.lat;
                          let lng = dotItem.lng;
                          if (unitMode === 0) {
                            if (
                              `${lat}`.length > 8 &&
                              `${lat}`.split(".").length > 1 &&
                              `${lat}`.split(".")[1].length > 8
                            ) {
                              lat = parseFloat(`${lat}`).toFixed(8);
                            }

                            if (
                              `${lng}`.length > 8 &&
                              `${lng}`.split(".").length > 1 &&
                              `${lng}`.split(".")[1].length > 8
                            ) {
                              lng = parseFloat(`${lng}`).toFixed(8);
                            }
                          } else {
                            const utmData = utm.convertLatLngToUtm(
                              dotItem.lat,
                              dotItem.lng,
                              8
                            );

                            if (utmData) {
                              lat = utmData.Easting;
                              lng = utmData.Northing;
                            }
                          }

                          return (
                            <div className={classes.tableBodyRow} key={index}>
                              <div className={classes.tableSmallTableBodyRow}>
                                <div
                                  className={classes.addDeleteButton}
                                  dangerouslySetInnerHTML={{
                                    __html: minusIcon,
                                  }}
                                  onClick={() => {
                                    removeDotMark(index);
                                  }}
                                />
                              </div>
                              <div
                                className={classes.tableSmallTableBodyRow}
                                style={{ cursor: "pointer" }}
                                onDoubleClick={() => {
                                  gotoPointer(index);
                                }}
                              >
                                {index + 1}
                              </div>
                              <div>
                                {unitMode === 1 ? (
                                  <input
                                    className={classes.sideBarInput}
                                    value={lat}
                                    disabled
                                  ></input>
                                ) : (
                                  // <div>{lat}</div>
                                  <input
                                    value={lat}
                                    className={classes.sideBarInput}
                                    type="number"
                                    onChange={(event) => {
                                      onChangeLat(event.target, index);
                                    }}
                                  ></input>
                                )}
                              </div>
                              <div className={classes.inputLatLngDivider}></div>
                              <div>
                                {unitMode === 1 ? (
                                  <input
                                    className={classes.sideBarInput}
                                    value={lng}
                                    disabled
                                  ></input>
                                ) : (
                                  <input
                                    value={lng}
                                    className={classes.sideBarInput}
                                    type="number"
                                    onChange={(event) => {
                                      onChangeLng(event.target, index);
                                    }}
                                    style={{ paddingLeft: "5px" }}
                                  ></input>
                                )}
                              </div>
                            </div>
                          );
                        })}

                      <div className={classes.tableBodyRow}>
                        <div className={classes.tableSmallTableBodyRow}>
                          <div
                            className={classes.addDeleteButton}
                            dangerouslySetInnerHTML={{
                              __html: plusIcon,
                            }}
                            style={
                              mode === 1
                                ? {
                                    backgroundColor: "grey",
                                    borderRadius: "15px",
                                  }
                                : {}
                            }
                            onClick={() => {
                              setMode(1);
                            }}
                          />
                        </div>
                        <div className={classes.tableSmallTableBodyRow}></div>
                        <div
                          className={classes.tableBodyBigWithBorderRow}
                        ></div>
                        <div className={classes.tableBodyBigRow}></div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {pointMode === 1 && (
                <>
                  <div className={classes.lineModeTopHeader}>
                    <div className={classes.lineModeTopSmallHeader}></div>
                    <div className={classes.lineModeTopSmallHeader}></div>
                    <div className={classes.lineModeTopBigWithBorderHeader}>
                      START{" "}
                      <div
                        className={classes.startEndIcon}
                        dangerouslySetInnerHTML={{
                          __html: lineModeHeaderCircle,
                        }}
                      ></div>
                    </div>
                    <div className={classes.lineModeTopBigHeader}>
                      END{" "}
                      <div
                        className={classes.startEndIcon}
                        dangerouslySetInnerHTML={{ __html: lineModeHeaderRect }}
                      ></div>
                    </div>
                  </div>
                  <div className={classes.tableHeader}>
                    <div className={classes.tableSmallHeader}></div>
                    <div className={classes.tableSmallHeader}>#</div>
                    <div className={classes.tableBigWithBorderHeader}>
                      {unitMode === 0 ? "Latitude" : "Easting"}
                    </div>
                    <div className={classes.tableBigWithBorderHeader}>
                      {unitMode === 0 ? "Longitude" : "Northing"}
                    </div>
                    <div className={classes.tableBigWithBorderHeader}>
                      {unitMode === 0 ? "Latitude" : "Easting"}
                    </div>
                    <div className={classes.tableBigHeader}>
                      {unitMode === 0 ? "Longitude" : "Northing"}
                    </div>
                  </div>
                  <div className={classes.tableBody}>
                    {linePointsData.length > 0 &&
                      linePointsData.map((lineItem, index) => {
                        let firstPointLat = lineItem.lineFirstPoint.lat;
                        let firstPointLng = lineItem.lineFirstPoint.lng;
                        if (unitMode === 0) {
                          if (
                            `${firstPointLat}`.length > 8 &&
                            `${firstPointLat}`.split(".").length > 1 &&
                            `${firstPointLat}`.split(".")[1].length > 8
                          ) {
                            firstPointLat = parseFloat(
                              `${firstPointLat}`
                            ).toFixed(8);
                          }

                          if (
                            `${firstPointLng}`.length > 8 &&
                            `${firstPointLng}`.split(".").length > 1 &&
                            `${firstPointLng}`.split(".")[1].length > 8
                          ) {
                            firstPointLng = parseFloat(
                              `${firstPointLng}`
                            ).toFixed(8);
                          }
                        } else {
                          const utmData = utm.convertLatLngToUtm(
                            firstPointLat,
                            firstPointLng,
                            8
                          );

                          if (utmData) {
                            firstPointLat = utmData.Easting;
                            firstPointLng = utmData.Northing;
                          }
                        }

                        let secondPointLat = "";
                        let secondPointLng = "";

                        if (lineItem.lineSecondPoint) {
                          secondPointLat = lineItem.lineSecondPoint.lat;
                          secondPointLng = lineItem.lineSecondPoint.lng;
                          if (unitMode === 0) {
                            if (
                              `${secondPointLat}`.length > 8 &&
                              `${secondPointLat}`.split(".").length > 1 &&
                              `${secondPointLat}`.split(".")[1].length > 8
                            ) {
                              secondPointLat = parseFloat(
                                `${secondPointLat}`
                              ).toFixed(8);
                            }

                            if (
                              `${secondPointLng}`.length > 8 &&
                              `${secondPointLng}`.split(".").length > 1 &&
                              `${secondPointLng}`.split(".")[1].length > 8
                            ) {
                              secondPointLng = parseFloat(
                                `${secondPointLng}`
                              ).toFixed(8);
                            }
                          } else {
                            const utmData = utm.convertLatLngToUtm(
                              secondPointLat,
                              secondPointLng,
                              8
                            );

                            if (utmData) {
                              secondPointLat = utmData.Easting;
                              secondPointLng = utmData.Northing;
                            }
                          }
                        }

                        return (
                          <div className={classes.tableBodyRow} key={index}>
                            <div className={classes.tableSmallTableBodyRow}>
                              <div
                                className={classes.addDeleteButton}
                                dangerouslySetInnerHTML={{ __html: minusIcon }}
                                onClick={() => {
                                  deleteLine(index);
                                }}
                              />
                            </div>
                            <div
                              className={classes.tableSmallTableBodyRow}
                              onDoubleClick={() => {
                                gotoFirstPointer(index);
                              }}
                            >
                              {index + 1}{" "}
                            </div>
                            <div className={classes.dataLineUTM}>
                              {unitMode === 1 ? (
                                <div>{firstPointLat}</div>
                              ) : (
                                <input
                                  value={firstPointLat}
                                  className={classes.sideBarInput}
                                  type="number"
                                  onChange={(event) => {
                                    onChangeLineFirstLat(event.target, index);
                                  }}
                                ></input>
                              )}
                            </div>
                            <div className={classes.inputLatLngDivider}></div>
                            <div className={classes.dataLineUTM}>
                              {unitMode === 1 ? (
                                <div>{firstPointLng}</div>
                              ) : (
                                <input
                                  value={firstPointLng}
                                  className={classes.sideBarInput}
                                  type="number"
                                  onChange={(event) => {
                                    onChangeLineFirstLng(event.target, index);
                                  }}
                                ></input>
                              )}
                            </div>
                            <div className={classes.inputLatLngDivider}></div>
                            <div className={classes.dataLineUTM}>
                              {unitMode === 1 ? (
                                <div>{secondPointLat}</div>
                              ) : (
                                <input
                                  value={secondPointLat}
                                  className={classes.sideBarInput}
                                  type="number"
                                  onChange={(event) => {
                                    onChangeLineSecondLat(event.target, index);
                                  }}
                                ></input>
                              )}
                            </div>
                            <div className={classes.inputLatLngDivider}></div>
                            <div className={classes.dataLineUTM}>
                              {unitMode === 1 ? (
                                <div>{secondPointLng}</div>
                              ) : (
                                <input
                                  value={secondPointLng}
                                  className={classes.sideBarInput}
                                  type="number"
                                  onChange={(event) => {
                                    onChangeLineSecondLng(event.target, index);
                                  }}
                                ></input>
                              )}
                            </div>
                          </div>
                        );
                      })}

                    <div className={classes.tableBodyRow}>
                      <div className={classes.tableSmallTableBodyRow}>
                        <div
                          className={classes.addDeleteButton}
                          dangerouslySetInnerHTML={{ __html: plusIcon }}
                          style={
                            mode === 2
                              ? {
                                  backgroundColor: "grey",
                                  borderRadius: "15px",
                                }
                              : {}
                          }
                          onClick={() => {
                            setMode(2);
                          }}
                        />
                      </div>
                      <div className={classes.tableSmallTableBodyRow}></div>
                      <div className={classes.tableBodyBigWithBorderRow}></div>
                      <div className={classes.tableBodyBigWithBorderRow}></div>
                      <div className={classes.tableBodyBigWithBorderRow}></div>
                      <div className={classes.tableBodyBigRow}></div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {open && <div className={classes.backDropContainer}></div>}

        {open && (
          <div className={classes.modalContainer}>
            <div className={classes.modalSubContainer}>
              <div className="text-right">
                <button className="btn btn-default" onClick={handleClose}>
                  X
                </button>
              </div>
              <div className={("form-group", classes.enterLocationLabel)}>
                ENTER SURVEY LOCATION TO BEGIN
              </div>
              <div className={classes.fullWidth}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6 col-sm-12 form-group">
                          <div className="row">
                            <div className={("col-sm-3", classes.modalLabel)}>
                              Latitude
                            </div>
                            <div className="col-sm-9">
                              <input
                                className={classes.modalInput}
                                type="number"
                                max="85"
                                min="-85"
                                value={initialLatitude}
                                onChange={(event) => {
                                  let { value, min, max } = event.target;
                                  const maxValue = Math.max(
                                    Number(min),
                                    Math.min(Number(max), Number(value))
                                  );
                                  setInitialLatitude(`${maxValue}`);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-12 form-group">
                          <div className="row">
                            <div className={("col-sm-3", classes.modalLabel)}>
                              Longitude
                            </div>
                            <div className="col-sm-9">
                              <input
                                className={classes.modalInput}
                                type="number"
                                value={initialLongitude}
                                max="180"
                                min="-180"
                                onChange={(event) => {
                                  let { value, min, max } = event.target;
                                  const maxValue = Math.max(
                                    Number(min),
                                    Math.min(Number(max), Number(value))
                                  );
                                  setInitialLongitude(`${maxValue}`);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={("row", classes.modalOrText)}>OR</div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 form-group">
                          <div className="row">
                            <div className={("col-sm-3", classes.modalLabel)}>
                              Street Address
                            </div>
                            <div className="col-sm-9">
                              <input
                                className={classes.modalInput}
                                value={initialStreetAddress}
                                onChange={(event) => {
                                  let { value } = event.target;
                                  setInitialStreetAddress(value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-offset-4 col-md-4">
                          <button
                            type="button"
                            className={"btn btn-primary"}
                            onClick={handleAddressSearch}
                          >
                            Start
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <br />
        {/* {JSON.stringify(dotPointsData, null, 4)}
      <br />
      {JSON.stringify(linePointsData, null, 2)} */}
        <div>
          {/* <button className="btn btn-primary" onClick={handleSubmit(handleSubmitData)}>Submit</button> */}
        </div>
      </div>
    </div>
  );
};

export default HereMap;
