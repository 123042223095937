import axios from "axios";

const url = "http://localhost:4000/api";
const apiGateway = "https://klumralin1.execute-api.us-east-2.amazonaws.com/dev";

const registerCompany = async (company) => {
  return await axios.post(url + "/company/register", company);
};

const userLogin = async (email, password) => {
  return await axios.post(url + "/user/login", {
    email,
    password,
  });
};

const userProfile = async (userId, companyId) => {
  // return await axios.post(url + "/user/myprofile", {
  return await axios.post(apiGateway + "/user", {
    flag: "profile",
    userId,
    companyId,
  });
};

const userChangePassword = async (email, oldPassword, newPassword) => {
  // return await axios.post(url + "/user/changepassword", {
  return await axios.post(apiGateway + "/user", {
    flag: "changepassword",
    email,
    oldPassword,
    newPassword,
  });
};

const getUserList = async (companyId, page, query, sortBy, orderBy) => {
  // return await axios.post(url + "/user/list", {
  return await axios.post(apiGateway + "/user", {
    flag: "list",
    companyId,
    page,
    query,
    sortBy,
    orderBy,
  });
};

const getSuperAdminDashboard = async () => {
  return await axios.post(apiGateway + "/dashboard", {
    flag: "superadmin",
  });
};
const getAdminDashboard = async (companyId) => {
  return await axios.post(apiGateway + "/dashboard", {
    flag: "admin",
    companyId,
  });
};

const userRemove = async (userId, companyId, email) => {
  return await axios.post(apiGateway + "/user/update", {
    userId,
    companyId,
    email,
    flag: "delete",
  });
};

const deleteAllDecryptionFile = async (userId, type) => {
  const res = await axios.get(apiGateway + `/decryption/deleteall?user_id=${userId}&type=${type}`);
  return res.data;
};

const userUpdate = async (user) => {
  // return await axios.post(url + "/user/update", user);
  return await axios.post(apiGateway + "/user/update", user);
};

const userAdd = async (user) => {
  // return await axios.post(url + "/user/create", user);
  return await axios.post(apiGateway + "/user", user);
};

const surveyLocationAdd = async (location) => {
  return await axios.post(url + "/surveylocation/add", location);
};

const surveyLocationList = async (companyId) => {
  return await axios.post(url + "/surveylocation/list", { companyId });
};

const surveyLocationRemove = async (id) => {
  return await axios.post(url + "/surveylocation/remove", { id });
};

const surveyLocationUpdate = async (location) => {
  return await axios.post(url + "/surveylocation/update", location);
};

const getIP = async () => {
  const res = await axios.get("https://geolocation-db.com/json/");
  console.log(res.data);
  return res.data;
};

const registerCompanyAPI = async (data) => {
  return await axios.post(apiGateway + "/company/register", {
    user: {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      mobile: data.mobile,
      password: data.password,
      status: 1,
    },
    company: {
      companyName: data.companyName,
      address1: data.address1,
      address2: data.address2,
      state: data.state,
      country: data.country,
      city: data.city,
      zipCode: data.zipCode,
      subscriptionStatus: true,
      industryType: data.industryType,
      application: data.application,
    },
    info: {
      ip: await getIP(),
      fullName: data.firstName + " " + data.lastName,
      email: data.email,
      city: data.city,
      country: data.country,
      companyName: data.companyName,
      industryType: data.industryType,
      application: data.application,
    },
  });
};

const userListAPI = async (companyId, lastKey) => {
  return await axios.post(apiGateway + "/user/all", {
    companyId,
    lastKey,
  });
};

const surveyLocationListAPI = async (companyId, page, query, sortBy, orderBy) => {
  // return await axios.post(url + "/surveylocation", {
  return await axios.post(apiGateway + "/surveylocation", {
    flag: "get",
    // companyId: "f0da8935-f1c5-45ee-b9e7-7cc4c8e0d4e4",
    companyId,
    page,
    query,
    sortBy,
    orderBy,
  });
};

const addSurveyLocationAPI = async (locations) => {
  // return await axios.post(url + "/surveylocation", {
  return await axios.post(apiGateway + "/surveylocation", {
    flag: "put",
    locations,
  });
};

const deleteSurveyLocationAPI = async (id, companyId) => {
  // return await axios.post(url + "/surveylocation", {
  return await axios.post(apiGateway + "/surveylocation", {
    flag: "delete",
    id,
    companyId,
  });
};

const updateSurveyLocationAPI = async (location) => {
  // return await axios.post(url + "/surveylocation", {
  return await axios.post(apiGateway + "/surveylocation", {
    flag: "update",
    location,
  });
};

//Device Whitelist
const addWhiteListDeviceAPI = async (deviceData) => {
  return await axios.post(apiGateway + "/whitelist", {
    flag: "insert",
    deviceData,
  });
};
const deleteWhiteListDeviceAPI = async (srNo) => {
  return await axios.post(apiGateway + "/whitelist", {
    flag: "delete",
    srNo,
  });
};
const updateWhiteListDeviceAPI = async (srNo) => {
  return await axios.post(apiGateway + "/whitelist", {
    flag: "update",
    srNo,
  });
};
const whiteListDeviceListAPI = async (page, query, sortBy, orderBy) => {
  return await axios.post(apiGateway + "/whitelist", {
    flag: "list",
    page,
    query,
    sortBy,
    orderBy,
  });
};

//End Device Whitelist

const createUserAPI = async (user) => {
  return await axios.post(apiGateway + "/user/create", user);
};

const forceChange = async (email, code, password) => {
  return await axios.post(apiGateway + "/user/update", {
    flag: "reset",
    email,
    code,
    password,
  });
};

const sendForgotPasswordCode = async (email) => {
  return await axios.post(apiGateway + "/user/update", {
    flag: "sendcode",
    email,
  });
};

const getAllChargebeePlans = async () => {
  return await axios.post(apiGateway + "/chargebee/all", {
    flag: "plans",
  });
};

const getDeviceList = async () => {
  return await axios.post(apiGateway + "/device/all", {});
};

const updateDevice = async (status, id) => {
  return await axios.post(apiGateway + "/device/update", {
    flag: "device",
    deviceStatus: status,
    id,
  });
};

const getCompanyList = async (page, query, sortBy, orderBy) => {
  return await axios.post(apiGateway + "/company", {
    flag: "list",
    page,
    query,
    sortBy,
    orderBy,
  });
};

const getCompanyData = async (companyId) => {
  return await axios.post(apiGateway + "/company", {
    flag: "get",
    companyId,
  });
};

const companyStatusUpdate = async (subStatus, companyId, userId) => {
  return await axios.post(apiGateway + "/company", {
    flag: "status",
    companyId,
    subStatus,
    userId,
  });
};

const companyUpdateData = async (companyId, userId, company) => {
  return await axios.post(apiGateway + "/company", {
    flag: "update",
    companyId,
    userId,
    company,
  });
};

// notification
const adminNotification = async (companyId) => {
  return await axios.post(apiGateway + "/notification", {
    flag: "admin",
    companyId,
  });
};
const superAdminNotification = async () => {
  return await axios.post(apiGateway + "/notification", {
    flag: "superAdmin",
  });
};
const technicianNotification = async (companyId) => {
  return await axios.post(apiGateway + "/notification", {
    flag: "technician",
    companyId,
  });
};
//notification end

const getSignedURL = async (file_name, userId, type) => {
  const res = await axios.get(
    `https://klumralin1.execute-api.us-east-2.amazonaws.com/dev/decryption/upload?user_id=${userId}&file_name=${file_name}&type=` +
      type
  );
  return res.data;
};

const getSignedURLSurvey = async (file_name, userId) => {
  const res = await axios.get(
    `https://klumralin1.execute-api.us-east-2.amazonaws.com/dev/surveyini/upload?user_id=${userId}&file_name=${file_name}`
  );
  return res.data;
};

const getSurveyFileList = async (userId) => {
  const res = await axios.get(`https://klumralin1.execute-api.us-east-2.amazonaws.com/dev/surveyini/get?user_id=${userId}`);
  return res.data;
};
const deleteSurveyFile = async (userId, fileId) => {
  const res = await axios.get(
    `https://klumralin1.execute-api.us-east-2.amazonaws.com/dev/surveyini/delete?user_id=${userId}&file_id=${fileId}`
  );
  return res.data;
};

const getSignedURLDownLoadFile = async (file_name, userId, type) => {
  const res = await axios.get(apiGateway + `/decryption/download?user_id=${userId}&file_name=${file_name}&type=` + type);
  return res.data;
};

const uploadFileAsBinary = async (url, fileData) => {
  // const res = await fetch(url, {
  //   method: "PUT",
  //   body: fileData,
  // });
  const res = await axios.put(url, fileData);
  return res.data;
};

const addDecryptionRecord = async (userId, status, filename, url, type) => {
  const res = await axios.post(
    `https://klumralin1.execute-api.us-east-2.amazonaws.com/dev/decryption/post?user_id=${userId}&status=${status}&file_name=${filename}&url=${url}&type=${type}`
  );
  return res.data;
};

const getDecryptionFileList = async (userId, type) => {
  const res = await axios.get(
    `https://klumralin1.execute-api.us-east-2.amazonaws.com/dev/decryption/get?user_id=${userId}&type=${type}`
  );
  return res.data;
};

const deleteDecryptionFile = async (download_file_name, file_name, userId, fileId, type) => {
  const res = await axios.get(
    `https://klumralin1.execute-api.us-east-2.amazonaws.com/dev/decryption/delete?user_id=${userId}&download_file_name=${download_file_name}&file_id=${fileId}&file_name=${file_name}&type=${type}`
  );
  return res.data;
};

export {
  registerCompany,
  userLogin,
  userProfile,
  userChangePassword,
  getUserList,
  userRemove,
  userUpdate,
  userAdd,
  surveyLocationAdd,
  surveyLocationList,
  surveyLocationRemove,
  surveyLocationUpdate,
  registerCompanyAPI,
  userListAPI,
  surveyLocationListAPI,
  getAllChargebeePlans,
  addSurveyLocationAPI,
  deleteSurveyLocationAPI,
  createUserAPI,
  updateSurveyLocationAPI,
  forceChange,
  sendForgotPasswordCode,
  getDeviceList,
  getCompanyList,
  getCompanyData,
  companyStatusUpdate,
  companyUpdateData,
  updateDevice,
  getSuperAdminDashboard,
  getAdminDashboard,
  addWhiteListDeviceAPI,
  updateWhiteListDeviceAPI,
  deleteWhiteListDeviceAPI,
  whiteListDeviceListAPI,
  adminNotification,
  superAdminNotification,
  technicianNotification,
  getSignedURL,
  getSignedURLDownLoadFile,
  uploadFileAsBinary,
  addDecryptionRecord,
  getDecryptionFileList,
  deleteDecryptionFile,
  deleteAllDecryptionFile,
  getSurveyFileList,
  deleteSurveyFile,
  getSignedURLSurvey,
};
