import { TableSortLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FaUserAlt, FaUserEdit, FaRocket } from "react-icons/fa";
//pagination
import Pagination from "react-responsive-pagination";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/pagination.css";
import { getAttribute } from "../../services/auth";
import { getCompanyList } from "../../services/axios";
import { getUserSubscription } from "../../services/chargebee";
import SearchBox from "../search_box/SearchBar";
import Spinner from "../spinner/Spinner";

const CompanyList = () => {
  const data = [
    {
      id: "abc123",
      name: "Volansys Technologies",
      address1: "123 Main Street",
      address2: "TX, USA",
      state: "CA",
      country: "USA",
      subscriptions: true,
    },
  ];
  const history = useHistory();
  const itemsPerPage = 10;
  const [companyList, setCompanyList] = useState(data);
  const [totalCompany, setTotalCompany] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("name");
  const [loading, setLoading] = useState(false);
  const [userAttribute, setUserAttribute] = useState({});

  useEffect(() => {
    async function fetchUserAttribute() {
      let data = await getAttribute();

      console.log("data: ", data);
      console.log("companyList: ", companyList);
      if (data["custom:role"] !== "superadmin") {
        history.push("/home");
        return;
      }
      setUserAttribute(data);
    }
    fetchUserAttribute();
  }, []);

  useEffect(() => {
    //API Call
    console.log("totalCompany: ", totalCompany);
    fetchUsers(searchString, sortBy, orderBy);
  }, [currentPage, totalCompany]);

  const fetchUsers = async (query, sortBy, orderBy) => {
    setLoading(true);
    let userList = await getCompanyList(currentPage, query, sortBy, orderBy);
    if (userList.data.statusCode === 200) {
      setCompanyList(userList.data.data.company);
      setTotalCompany(userList.data.data.count);
      setTotalPage(Math.ceil(userList.data.data.count / itemsPerPage));
    } else {
      toast.error(userList.data.message);
    }
    setLoading(false);
  };
 

  // if (loading) {
  //   return <Spinner />;
  // }

  const handleSearch = async (text) => {
    setSearchString(text);
    await fetchUsers(text, sortBy, orderBy);
  };

  const handleSortByChange = async (e) => {
    e.preventDefault();
    setSortBy(e.target.value);
    console.log(e.target.value);
    await fetchUsers(searchString, e.target.value, orderBy);
  };

  const handleOrderChange = async (e) => {
    e.preventDefault();
    setOrderBy(e.target.value);
    console.log(e.target.value);
    await fetchUsers(searchString, sortBy, e.target.value);
  };

  const handleSort = async (name, e) => {
    e.preventDefault();
    let temp = orderBy === "asc" && sortBy === name ? "desc" : "asc";
    setSortBy(name);
    setOrderBy(temp);
    await fetchUsers(searchString, name, temp);
  };

  return (
    <>
      {
      loading==true ? <Spinner />: <p></p>
      } 
      <div className="container">
        <div className="row mb-3 mt-1 ml-1">
          <div className="col-4">
            <div className="input-group">
              <div className="input-group-append">
                <label className="input-group-text">Sort By</label>
              </div>
              <select
                className="form-control"
                defaultValue={sortBy}
                onChange={handleSortByChange}
              >
                <option value="name">Name</option>
                <option value="createdAt">Created At</option>
                <option value="updatedAt">Updated At</option>
                <option value="address1">Address</option>
                <option value="city">City</option>
                <option value="state">State</option>
                <option value="zipCode">ZipCode</option>
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="input-group">
              <div className="input-group-append">
                <label className="input-group-text">Order</label>
              </div>
              <select
                className="form-control"
                defaultValue={orderBy}
                onChange={handleOrderChange}
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>
          <div className="col-5">
            <SearchBox defaultValue={searchString} onSearch={handleSearch} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex bd-highlight mb-n4">
                  <div className="mr-auto p-2">
                    <h5 className="card-title text-uppercase mb-0">
                      Manage Companies
                    </h5>
                  </div>
                  <div className="p-2 bd-highlight">
                    <label>
                      Total : <b>{totalCompany}</b>
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="table-responsive">
                <table className="table table-striped no-wrap company-table mb-0">
                  <thead>
                    <tr>
                      <th
                        scope="col-1"
                        className="border-0 text-uppercase font-medium pl-4"
                      >
                        #
                      </th>
                      <th
                        scope="col-3"
                        className="border-0 text-uppercase font-medium"
                      >
                        Name
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "name"}
                          onClick={(e) => handleSort("name", e)}
                        />
                      </th>
                      {/* <th
                        scope="col-3"
                        className="border-0 text-uppercase font-medium"
                      >
                        Contact
                      </th> */}
                      {/* <th
                        scope="col-1"
                        className="border-0 text-uppercase font-medium"
                      >
                        Devices
                      </th> */}
                      <th
                        scope="col-2"
                        className="border-0 text-uppercase font-medium"
                      >
                        Status
                      </th>

                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium text-center"
                      >
                        Manage
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {companyList.map((company, index) => {
                      return (
                        <tr key={index}>
                          <td className="pl-4">
                            {index + 1 + (currentPage - 1) * itemsPerPage}
                          </td>
                          <td>
                            <h5 className="font-medium mb-0">{company.name}</h5>
                            <span className="text-muted">
                              {company.state}
                              {" - "}
                              {company.country}
                            </span>
                          </td>
                          {/* <td>
                            <span className="text-muted">
                              <i
                                className="fa fa-user-circle"
                                aria-hidden="true"
                              ></i>{" "}
                              : {company.person}
                            </span>
                            <br />
                            <span className="text-muted">
                              <i
                                className="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i>{" "}
                              : {company.email}
                            </span>
                          </td> */}
                          {/* <td className="">
                            <span className="text-muted">
                              {company.totalDevices}
                            </span>
                          </td> */}
                          <td>
                            <button
                              type="button"
                              className="btn btn-circle btn-lg btn-circle"
                            >
                              <FaUserAlt
                                color={
                                  company.subscriptionStatus ? "green" : "red"
                                }
                              />
                            </button>
                          </td>

                          <td className="text-center">
                            <Link
                              to={{
                                pathname: "/editcompany",
                                state: {
                                  ...company,
                                },
                              }}
                            >
                              <button type="button" className="btn btn-circle">
                                <FaUserEdit className="" color="grey" />
                              </button>
                            </Link>
                            <Link
                              to={{
                                pathname: "/purchase",
                                state: {
                                  ...company,
                                },
                              }}
                            >
                              <button type="button" className="btn btn-circle">
                                <FaRocket className="" color="grey" />
                              </button>
                            </Link>

                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div style={{ marginLeft: "1070px", marginTop: "20px" }}>
                  <Pagination
                    current={currentPage}
                    total={totalPage}
                    onPageChange={setCurrentPage}
                    maxWidth={5}
                    extraClassName="justify-content-start"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CompanyList;
