const About = (props) => {
  return (
    <>
      <div class="container">
        <div class="card">
          <div class="card-header  ">
            <h2 className="d-flex justify-content-center">About</h2>
          </div>
          <div class="card-body">
            <div>
              <img
                src="GeoStack.png"
                class="rounded float-left"
                alt="GeoStack Logo"
                style={{ height: "20%", width: "30%" }}
              />
            </div>
            <div
              style={{ fontSize: "20px", marginTop: "100px", lineHeight: 1.6 }}
              className="text-justify"
            >
              GeoStack is the unique integration of imaging hardware and
              software—designed for modern Earth specializations. Geostack uses
              the latest technology to give you seamless control of your
              hardware and software for obtaining subsurface measurement data.
              The GeoStack apparatus is designed to help how you work today and
              in the future—not decades ago.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
