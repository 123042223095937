import axios from "axios";

const apiGateway = "https://klumralin1.execute-api.us-east-2.amazonaws.com/dev";
const chargebeeSite = "agiusa-test";

let cbInstance = null;

const initChargebee = async () => {
  cbInstance = window.Chargebee?.init({ site: chargebeeSite });
};

const getCBSession = async (id) => {
  return await axios.post(apiGateway + "/chargebee/all", {
    flag: "get_session",
    customerId: id,
  });
};

const getUpdatePaymentHostedPage = async (id) => {
  return await axios.post(apiGateway + "/chargebee/all", {
    flag: "update_payment",
    customerId: id,
  });
};

const getCheckoutHostedPage = async (id, plan_id, addons, coupon_ids) => {
  return await axios.post(apiGateway + "/chargebee/all", {
    flag: "checkout",
    customerId: id,
    plan_id,
    addons: [{ id: addons }],
    coupon_ids: [coupon_ids],
  });
};

const showChargebeePortal = async (id) => {
  if (!cbInstance) {
    await initChargebee();
  }

  const portalSession = await getCBSession(id);

  if (portalSession.data.statusCode === 200) {
    cbInstance.setPortalSession(portalSession.data.data);

    cbInstance.createChargebeePortal().open({
      visit: (sectionType) => {
        console.log("SectionType:", sectionType);
      },
    });
  } else {
    console.log(portalSession.data);
  }
};

const showUpdatePaymentPortal = async (id) => {
  if (!cbInstance) {
    await initChargebee();
  }

  const portal = await getUpdatePaymentHostedPage(id);
  console.log(portal.data);
  if (portal.data.statusCode === 200) {
    cbInstance.openCheckout({
      hostedPage: async () => portal.data.data,
      close: () => {
        console.log("Close update payment");
      },
    });
  } else {
    console.log(portal.data);
  }
};

const getUserSubscription = async (id) => {
  return await axios.post(apiGateway + "/chargebee/all", {
    flag: "customer",
    customerId: id,
  });
};

const getTotalSubscription = async () => {
  return await axios.post(apiGateway + "/chargebee/all", {
    flag: "total_subscription",
  });
};

const showCheckoutPortal = async (id) => {
  if (!cbInstance) {
    await initChargebee();
  }

  const portal = await getCheckoutHostedPage(id);
  console.log(portal.data);
  if (portal.data.statusCode === 200) {
    cbInstance.openCheckout({
      hostedPage: async () => portal.data.data,
      close: () => {
        console.log("Close checkout");
      },
      success(hostedPageId) {
        console.log("hostedPageId", hostedPageId);
      },
      step(step) {
        console.log("checkout", step);
      },
    });
  } else {
    console.log(portal.data);
  }
};

export {
  cbInstance,
  initChargebee,
  getCheckoutHostedPage,
  getUpdatePaymentHostedPage,
  getCBSession,
  getUserSubscription,
  getTotalSubscription,
};
