import { PubSub } from 'aws-amplify';
import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from 'react-bootstrap/Table';
import { FaCheckCircle, FaFileDownload, FaSpinner, FaTimesCircle, FaTrash } from "react-icons/fa";
import "../../assets/pagination.css";
import { getAttribute } from "../../services/auth";
import { addDecryptionRecord, deleteAllDecryptionFile, deleteDecryptionFile, getDecryptionFileList, getSignedURL, getSignedURLDownLoadFile, uploadFileAsBinary } from "../../services/axios";
import Spinner from "../spinner/Spinner";

import { CONNECTION_STATE_CHANGE } from '@aws-amplify/pubsub';
import Backdrop from '@material-ui/core/Backdrop';
import { Hub } from 'aws-amplify';
import ProgressBar from 'react-bootstrap/ProgressBar';

export const FILE_DECRYPTION_STATUS_UNDEFINED="undefined";
export const FILE_DECRYPTION_STATUS_PROCESS = "process";
export const FILE_DECRYPTION_STATUS_COMPLETED ="completed";
export const TYPE_DECRYPTION = "decryption";

Hub.listen('pubsub', (data) => {
  const { payload } = data;
  if (payload.event === CONNECTION_STATE_CHANGE) {
    const connectionState = payload.data.connectionState;
    console.log("connectionState", connectionState);
  }
});

const DecryptionList = () => {
  const [currentFilelist, setCurrentFilelist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingDownloadProgress, setLoadingDownloadProgress] = useState(false);
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("firstName");
  const [file, setFile] = useState()
  const [totalUser, setTotalUser] = useState(1);
  const [signedURL, setSignedURL] = useState("");
  const [msg, setMsg] = useState(null);
  const [userAttribute, setUserAttribute] = useState(null);
  const [totalFile, setTotalFile] = useState(0);
  const [progress, setProgress] = useState(0);
  // let topicToSubscribe = "myTopic";
  const [topicToSubscribe, setTopicToSubscribe] = useState("fileDecryptionStatus");

  async function fetchFileList() {
    console.log("fetchFileList");
    setLoading(true);
    await getDecryptionFileList(userAttribute["custom:userId"],"decryption").then((response) => {
      setCurrentFilelist(response.data);
      setTotalFile(response.data.length);
      setTopicToSubscribe("fileDecryptionStatus")
      setLoading(false);
    })
  }
  
  async function fetchUserAttribute() {
    // if(userAttribute == {}){
    setLoading(true);
    await getAttribute().then((response) => {
      setUserAttribute(response);
      setLoading(false);
    })
    // }
  }

  useEffect(() => {
    fetchUserAttribute();
  }, []);

  useEffect(() => {
    if (topicToSubscribe && userAttribute) {
      PubSub.subscribe("fileDecryptionStatus").subscribe({
        next: (data) => {
          console.log("subscribe fileDecryptionStatus: ", data);
          if (data.value.user_id == userAttribute["custom:userId"]) {
            console.log(data.value);
            setMsg(data.value.message);
          }
        },
        error: (error) => console.error("Error Occured in mqtt: ", error),
        complete: () => console.log("Done"),
      });
    }
  }, [topicToSubscribe, userAttribute])

  useEffect(() => {
    if (msg) {
      console.log("msg", msg);
      fetchFileList();
    }
  }, [msg]);

  useEffect(() => {
    if (userAttribute) {
      console.log("userAttribute", userAttribute);
      fetchFileList();
    }
  }, [userAttribute]);

  const deleteFile = async (file_name, download_file_name, fileId) => {
    deleteDecryptionFile(download_file_name, file_name, userAttribute["custom:userId"], fileId,"decryption").then((response) => {
      console.log(response);
      fetchFileList();
    })
  };

  function handleChange(event) {
    setFile(event.target.files[0])
  }

  const getBlob = async (fileUri) => {
    const resp = await fetch(fileUri);
    const imageBody = await resp.blob();
    return imageBody;
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const imageBody = await getBlob(URL.createObjectURL(file));
    setLoading(true);
    const signedURL = await getSignedURL(file.name, userAttribute["custom:userId"],"decryption");
    const uploadResult = await uploadFileAsBinary(signedURL.data, imageBody);
    console.log("uploadFileAsBinary", uploadResult);
    const addRecordResult = await addDecryptionRecord(userAttribute["custom:userId"], FILE_DECRYPTION_STATUS_PROCESS, file.name, "-", TYPE_DECRYPTION);
    console.log("addDecryptionRecord", addRecordResult);
    const fileList = await fetchFileList();
  }

  function deleteAllFile() {
    setLoading(true);
    deleteAllDecryptionFile(userAttribute["custom:userId"],"decryption").then((data) => {
      console.log("deleteAllFile data:", data)
      setLoading(false);
      fetchFileList();
    });
  }

  async function handleFileDownload(download_file_name,file_name,id) {
    setLoadingDownloadProgress(true);
    getSignedURLDownLoadFile(download_file_name, userAttribute["custom:userId"],"decryption").then((signedURL) => {


        axios.get(signedURL.data, {
            responseType: 'blob',
            onDownloadProgress: progressEvent => {
              const percentage = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentage);
              if (percentage === 100) {
                setTimeout(() => {
                  setProgress(0);
                  setLoadingDownloadProgress(false);
                }, 400);
              }
            }
          })
       .then(response => {
        debugger;
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', download_file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        deleteFile(file_name,download_file_name,id)
      }).catch((error) => {
        
        debugger;
      });
    })
  }

  return (
    <>
      {
        loading == true ? <Spinner /> : <p></p>
      }
       {loadingDownloadProgress && <Backdrop style={{zIndex: 2}} open={true} ><div style={{width: 500}} ><ProgressBar animated striped variant="success" label={`${progress}%`} now={progress}  /></div></Backdrop>}
      <div className="container">
        <div className="row">
          <div className="mt-2 mb-2">
            <form onSubmit={handleSubmit}>
              <h4>Please upload your file:</h4>
              <input type="file" onChange={handleChange} />
              <button type="submit" className="btn btn-info">
                <span className="mr-2">
                  <i className="fa fa-upload" aria-hidden="true" />
                </span>
                Decrypt
              </button>
            </form>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex bd-highlight mb-n4">
                  <div className="mr-auto p-2">
                    <h5 className="card-title text-uppercase mb-0">
                      File Decryption Status
                    </h5>
                  </div>
                  <div className="p-2 bd-highlight">
                    <label>
                      Total : <b>{totalFile}</b>
                    </label>
                  </div>
                  <div className="p-2 bd-highlight">
                    <button className="btn btn-info" onClick={() => fetchFileList()}>
                      <span className="mr-2">
                        <i className="fa fa-refresh" aria-hidden="true" />
                      </span>
                      Refresh
                    </button>
                    &nbsp;
                    <button className="btn btn-info" onClick={() => deleteAllFile()}>
                      <span className="mr-2">
                        <i className="fa fa-trash" aria-hidden="true" />
                      </span>
                      Delete All
                    </button>
                  </div>
                </div>
              </div>
              <hr />
              {/* <table className="table table-striped no-wrap decryption-table mb-0"> */}
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th
                    >
                      #
                    </th>
                    <th
                    >
                      File Name
                      {/* <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "file_name"}
                        /> */}
                    </th>
                    <th
                    >
                      Decryption Status
                      {/* <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "status"}
                        /> */}
                    </th>
                    <th
                    >
                      Download
                    </th>
                    <th
                    >
                      Delete
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentFilelist && currentFilelist.length > 0 ? currentFilelist.map((file, index) => {
                    return (
                      <tr key={index}>
                        <td
                        >
                          {index + 1}
                        </td>
                        <td>
                          <h5
                          >
                            {file.file_name}
                          </h5>
                        </td>
                        <td>
                          <span className="">
                            {
                              file.status === FILE_DECRYPTION_STATUS_COMPLETED ? <p>Completed <FaCheckCircle className="" color="green" /></p> : <p></p>
                            }

                            {
                              file.status === FILE_DECRYPTION_STATUS_PROCESS ? <p>In-Progress <FaSpinner className="" color="yellow" /></p> : <p></p>
                            }

                            {
                              file.status === FILE_DECRYPTION_STATUS_UNDEFINED ? <p>Failed <FaTimesCircle className="" color="red" /></p> : <p></p>
                            }
                          </span>
                        </td>
                        <td>
                          {file.url != '-' &&
                            <button
                              type="button"
                              className="btn btn-circle ml-2"
                              href={file.url}
                              target="_blank"
                              onClick={() => handleFileDownload(file.download_file_name,file.file_name,file.id)}
                            >
                              <FaFileDownload className="" color="grey" />
                            </button>
                          }
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-circle ml-2"
                            onClick={() => deleteFile(file.file_name, file.download_file_name, file.id)}>
                            <FaTrash className="" color="grey" />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                    : <div>No records found</div>}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DecryptionList;