import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import "../../assets/custom.css";
import { signIn } from "../../services/auth";
import ConfirmUser from "../confirm_user/ConfirmUser";

const Login = () => {
  var isLogin = localStorage.getItem("isLogin");

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginData, setLogin] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [eye, setEye] = useState(true);
  const [userConfirm, setUserConfirm] = useState(false);
  let pattern =
    /^(?=.*[0-9])(?=.*[A-Z])(?=.*[.!@#$%^&*])[a-zA-Z0-9.!@#$%^&*]{8,24}$/;
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("Username is required.")
      .max(50, "No more than 50 characters."),
    password: Yup.string()
      .required("Password is required.")
      .matches(pattern, "Must be valid password."),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    if (isLogin) {
      window.location.href = "/";
    }
  }, [isLogin]);

  const handleConfirmLogin = async (username) => {
    setLoading(true);
    try {
      setUsername(username);
      let user = await signIn(username, password);
      if (user.success) {
        toast.success("Login Successfully.");
        localStorage.setItem("isLogin", true);
        setLogin(true);
      } else {
        console.log("User Not Authorize");
        toast.error(user.message);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error("Invalid username or password");
      setLoading(false);
    }
  };
  const handleLogin = async (e) => {
    // e.preventDefault();
    // if (username.trim().length === 0 && password.trim().length === 0) {
    //   toast.error("Username and password required");
    //   return;
    // }
    // if (username.trim().length === 0) {
    //   toast.error("Username required");
    //   return;
    // }
    // if (password.trim().length === 0) {
    //   toast.error("Password required");
    //   return;
    // }
    setLoading(true);
    try {
      setUsername(e.username);
      setPassword(e.password);
      let user = await signIn(e.username, e.password);
      if (user.success) {
        toast.success("Login Successfully.");
        localStorage.setItem("isLogin", true);
        setLogin(true);
      } else {
        if (user.error === "UserNotConfirmedException") {
          console.log("UserNotConfirmedException");
          toast.info(user.message);
          setUserConfirm(true);
        } else {
          console.log("User Not Authorize");
          toast.error(user.message);
        }
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
      toast.error("Invalid username or password");
      setLoading(false);
    }
  };

  return (
    <div>
      <main>
        <div className="container">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <div className="col-lg-5">
              <div className="card shadow-lg border-0 rounded-lg mt-5">
                <div className="d-flex justify-content-center mt-3">
                  <img
                    src={"GeoStack.png"}
                    style={{ width: "20%" }}
                    alt="Logo"
                  />
                </div>
                <div className="card-header text-center bg-white">
                  <h3 className="font-weight-light my-2">Welcome</h3>
                  <h6 className="font-weight-light mb-3 mt-n1">
                    Login to GeoStack
                  </h6>
                </div>
                <div className="card-body">
                  {userConfirm ? (
                    <ConfirmUser
                      email={username}
                      setUserConfirm={setUserConfirm}
                      handleLogin={handleConfirmLogin}
                    />
                  ) : (
                    <form className="p-2" onSubmit={handleSubmit(handleLogin)}>
                      <div className="form-group mb-3">
                        <label htmlFor="inputEmail">Username</label>
                        <input
                          className={`form-control ${
                            errors.username ? "is-invalid" : ""
                          }`}
                          id="inputEmail"
                          autoFocus
                          type="text"
                          onKeyPress={(e) =>
                            e.key === "Enter" && handleSubmit(handleLogin)
                          }
                          placeholder="Username"
                          onChange={(e) => {
                            if (e.target.value.length < 50) {
                              setUsername(e.target.value);
                            }
                          }}
                          {...register("username")}
                        />
                        <div className="invalid-feedback">
                          {errors.username?.message}
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="inputPassword">Password</label>
                        <div className="input-group">
                          <input
                            className={`form-control ${
                              errors.password ? "is-invalid" : ""
                            }`}
                            id="inputPassword"
                            type={eye ? "password" : "text"}
                            placeholder="Password"
                            onKeyPress={(e) =>
                              e.key === "Enter" && handleSubmit(handleLogin)
                            }
                            onChange={(e) => {
                              if (e.target.value.length < 50) {
                                setPassword(e.target.value);
                              }
                            }}
                            {...register("password")}
                          />

                          <div className="input-group-append">
                            <button
                              className="input-group-text"
                              type="button"
                              onClick={(e) => setEye(!eye)}
                            >
                              {eye ? (
                                <i className="fa fa-eye" aria-hidden="true"></i>
                              ) : (
                                <i
                                  className="fa fa-eye-slash"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </button>
                          </div>
                          <div className="invalid-feedback">
                            {errors.password?.message}
                          </div>
                        </div>
                      </div>
                      <div className="mb-2">
                        <Link to="/forgotpassword">
                          <label style={{ cursor: "pointer" }}>
                            Forgot Password?
                          </label>
                        </Link>
                      </div>
                      <div className="mb-3 mt-n1">
                        <Link to="/signup">
                          <label style={{ cursor: "pointer" }}>
                            New Company Registration?
                          </label>
                        </Link>
                      </div>
                      <div>
                        <button
                          style={{ background: "#228B32", width: "100%" }}
                          className=" btn card-footer text-center py-3"
                          disabled={isLoading}
                          // onClick={handleLogin}
                          type="submit"
                        >
                          <div className="small">
                            <span
                              className="btn"
                              style={{ background: "#228B32" }}
                            >
                              {" "}
                              <b>Login</b>
                              {isLoading ? (
                                <span className="ml-2 spinner-grow spinner-grow-sm text-light" />
                              ) : null}
                            </span>
                          </div>
                        </button>
                      </div>
                    </form>
                  )}
                  <footer
                    className="d-flex justify-content-right align-items-bottom ml-4"
                    style={{ position: "fixed", bottom: 0, left: 0 }}
                  >
                    v{process.env.REACT_APP_VERSION}
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Login;
