import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { registerCompanyAPI } from "../../services/axios";
import FirstPurchase from "../purchase/first_purchase";

const Signup = () => {
  const [formData, setFormData] = useState({});
  const [eye, setEye] = useState(true);
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState(
    "24d2adf0-9333-4ded-aa3d-0199e70091d5"
  );
  const [isPurchase, setPurchase] = useState(false);

  const phoneRegExp = /^\d{10}$/;

  const validationSchema = Yup.object().shape({
    companyName: Yup.string()
      .required("Company Name is required")
      .max(50, "No more than 50 characters."),
    address1: Yup.string()
      .required("Address is required.")
      .max(100, "No more than 100 characters."),
    address2: Yup.string().max(100, "No more than 100 characters."),
    city: Yup.string()
      .required("City is required.")
      .max(50, "No more than 50 characters."),
    state: Yup.string().required("State is required."),
    zipCode: Yup.string().max(50, "No more than 10 numbers."),
    country: Yup.string().required("Country is required."),
    industryType: Yup.string().max(100, "No more than 100 characters."),
    application: Yup.string().max(100, "No more than 100 characters."),
    firstName: Yup.string()
      .required("First Name is required.")
      .max(50, "No more than 50 characters."),
    lastName: Yup.string().max(50, "No more than 50 characters."),
    mobile: Yup.string()
      .required("Mobile Number is required.")
      .matches(phoneRegExp, "Mobile number is invalid."),
    email: Yup.string()
      .email("Must be valid email")
      .max(50, "No more than 50 characters.")
      .required("Email is required."),
    password: Yup.string().required("Password is required."),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const onSubmit = async (data) => {
    let pattern =
      /^(?=.*[0-9])(?=.*[A-Z])(?=.*[.!@#$%^&*])[a-zA-Z0-9.!@#$%^&*]{8,24}$/;
    if (!pattern.test(data.password)) {
      // toast.error("Please, Enter valid password.");
      setPasswordError(true);
      return;
    }
    setLoading(true);
    console.log(data);
    data.subscriptionStatus = true;
    data.status = 1;
    data.roleId = "A";
    data.role = "admin";
    try {
      let resp = await registerCompanyAPI(data);
      if (resp.data.statusCode === 200) {
        toast.success(resp.data.message);
        setCompanyId(resp.data.data.companyId);
        setPurchase(true);
        // toast.info("Login for continue");
        // history.push("/login");
        setLoading(false);
      } else {
        toast.error(resp.data.message);
        console.log(resp.data);
        setLoading(false);
        setPasswordError(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      setPasswordError(false);
    }
  };

  const formChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.trim(),
    });
  };

  return (
    <>
      <main className="mb-3">
        <div className="container">
          <div className="row d-flex flex-column justify-content-center align-items-center">
            <div className="col-lg-9">
              <div className="card shadow-lg border-0 rounded-lg mt-2">
                <div className="d-flex justify-content-center mt-3">
                  <img
                    src={
                      "GeoStack.png"
                    }
                    style={{ width: "10%" }}
                    alt="Logo"
                  />
                </div>
                {isPurchase ? (
                  <FirstPurchase companyId={companyId} />
                ) : (
                  <>
                    <div className="card-header text-center bg-white">
                      <h3 className="font-weight-light my-2">Welcome</h3>
                      <h6 className="font-weight-light mb-3 mt-n1">
                        Sign Up to GeoStack
                      </h6>
                    </div>
                    <div className="card-body">
                      <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                          <label>
                            Company Name <span className="text-danger">*</span>
                          </label>
                          <input
                            name="companyName"
                            type="text"
                            {...register("companyName")}
                            className={`form-control ${
                              errors.companyName ? "is-invalid" : ""
                            }`}
                          />
                          <div className="invalid-feedback">
                            {errors.companyName?.message}
                          </div>
                        </div>
                        <div className="form-group">
                          <label htmlFor="inputAddress">
                            Address<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder="1234 Main St"
                            name="address1"
                            className={`form-control ${
                              errors.address1 ? "is-invalid" : ""
                            }`}
                            {...register("address1")}
                            onChange={formChange}
                          />
                          <div className="invalid-feedback">
                            {errors.address1?.message}
                          </div>
                        </div>
                        <div>
                          <div className="form-group">
                            <label htmlFor="inputAddress2">Address 2</label>
                            <input
                              type="text"
                              placeholder="Apartment, studio, or floor"
                              name="address2"
                              className={`form-control ${
                                errors.address2 ? "is-invalid" : ""
                              }`}
                              {...register("address2")}
                              onChange={formChange}
                            />
                            <div className="invalid-feedback">
                              {errors.address2?.message}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-4">
                            <label htmlFor="inputCity">
                              City<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              name="city"
                              className={`form-control ${
                                errors.city ? "is-invalid" : ""
                              }`}
                              onChange={formChange}
                              {...register("city")}
                            />
                            <div className="invalid-feedback">
                              {errors.city?.message}
                            </div>
                          </div>
                          <div className="form-group col-md-3">
                            <label htmlFor="inputState">
                              State<span className="text-danger">*</span>
                            </label>
                            <select
                              className={`form-control ${
                                errors.state ? "is-invalid" : ""
                              }`}
                              name="state"
                              onChange={formChange}
                              {...register("state")}
                            >
                              <option value="AL">AL</option>
                              <option value="AK">AK</option>
                              <option value="AZ">AZ</option>
                              <option value="AR">AR</option>
                              <option value="CA">CA</option>
                              <option value="CO">CO</option>
                              <option value="CT">CT</option>
                              <option value="DE">DE</option>
                              <option value="DC">DC</option>
                              <option value="FL">FL</option>
                              <option value="GA">GA</option>
                              <option value="HI">HI</option>
                              <option value="ID">ID</option>
                              <option value="IL">IL</option>
                              <option value="IN">IN</option>
                              <option value="IA">IA</option>
                              <option value="KS">KS</option>
                              <option value="KY">KY</option>
                              <option value="LA">LA</option>
                              <option value="ME">ME</option>
                              <option value="MD">MD</option>
                              <option value="MA">MA</option>
                              <option value="MI">MI</option>
                              <option value="MN">MN</option>
                              <option value="MS">MS</option>
                              <option value="MO">MO</option>
                              <option value="MT">MT</option>
                              <option value="NE">NE</option>
                              <option value="NV">NV</option>
                              <option value="NH">NH</option>
                              <option value="NJ">NJ</option>
                              <option value="NM">NM</option>
                              <option value="NY">NY</option>
                              <option value="NC">NC</option>
                              <option value="ND">ND</option>
                              <option value="OH">OH</option>
                              <option value="OK">OK</option>
                              <option value="OR">OR</option>
                              <option value="PA">PA</option>
                              <option value="RI">RI</option>
                              <option value="SC">SC</option>
                              <option value="SD">SD</option>
                              <option value="TN">TN</option>
                              <option value="TX">TX</option>
                              <option value="UT">UT</option>
                              <option value="VT">VT</option>
                              <option value="VA">VA</option>
                              <option value="WA">WA</option>
                              <option value="WV">WV</option>
                              <option value="WI">WI</option>
                              <option value="WY">WY</option>
                            </select>
                            <div className="invalid-feedback">
                              {errors.state?.message}
                            </div>
                          </div>
                          <div className="form-group col-md-3">
                            <label htmlFor="inputZip">Zip</label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.zipCode ? "is-invalid" : ""
                              }`}
                              name="zipCode"
                              onChange={formChange}
                              {...register("zipCode")}
                            />
                            <div className="invalid-feedback">
                              {errors.zipCode?.message}
                            </div>
                          </div>
                          <div className="form-group col-md-2">
                            <label htmlFor="inputState">
                              Country<span className="text-danger">*</span>
                            </label>
                            <select
                              className={`form-control ${
                                errors.country ? "is-invalid" : ""
                              }`}
                              name="country"
                              onChange={formChange}
                              {...register("country")}
                            >
                              <option value="USA">USA</option>
                            </select>
                            <div className="invalid-feedback">
                              {errors.country?.message}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col">
                            <label>
                              Industry Type
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.industryType ? "is-invalid" : ""
                              }`}
                              placeholder="Industry Type"
                              name="industryType"
                              onChange={formChange}
                              {...register("industryType")}
                            />
                            <div className="invalid-feedback">
                              {errors.industryType?.message}
                            </div>
                          </div>
                          <div className="form-group col">
                            <label>
                              Preferred Application
                              {/* <span className="text-danger">*</span> */}
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.application ? "is-invalid" : ""
                              }`}
                              placeholder="Preferred Application"
                              name="application"
                              onChange={formChange}
                              {...register("application")}
                            />
                            <div className="invalid-feedback">
                              {errors.application?.message}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="form-group col">
                            <label>
                              First Name<span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              }`}
                              placeholder="First name"
                              name="firstName"
                              onChange={formChange}
                              {...register("firstName")}
                            />
                            <div className="invalid-feedback">
                              {errors.firstName?.message}
                            </div>
                          </div>
                          <div className="form-group col">
                            <label>Last Name</label>
                            <input
                              type="text"
                              className={`form-control ${
                                errors.lastName ? "is-invalid" : ""
                              }`}
                              placeholder="Last name"
                              name="lastName"
                              onChange={formChange}
                              {...register("lastName")}
                            />
                            <div className="invalid-feedback">
                              {errors.lastName?.message}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label>
                              Mobile Number
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              className={`form-control ${
                                errors.mobile ? "is-invalid" : ""
                              }`}
                              placeholder="Mobile Number"
                              name="mobile"
                              type="tel"
                              // pattern="^\d{10}$"
                              // required
                              // maxLength={15}
                              // minLength={10}
                              onChange={formChange}
                              {...register("mobile")}
                            />
                            <div className="invalid-feedback">
                              {errors.mobile?.message}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="inputEmail4">
                              Email<span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              placeholder="Email"
                              name="email"
                              // maxLength={100}
                              // required
                              onChange={formChange}
                              {...register("email")}
                            />
                            <div className="invalid-feedback">
                              {errors.email?.message}
                            </div>
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="inputPassword4">
                              Password<span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <input
                                type={eye ? "password" : "text"}
                                className={`form-control ${
                                  errors.password || passwordError
                                    ? "is-invalid"
                                    : ""
                                }`}
                                // required
                                // minLength={8}
                                placeholder="Password"
                                // maxLength={50}
                                name="password"
                                onChange={formChange}
                                {...register("password")}
                              />
                              <div className="input-group-append">
                                <button
                                  className="input-group-text"
                                  type="button"
                                  onClick={(e) => setEye(!eye)}
                                >
                                  {eye ? (
                                    <i
                                      className="fa fa-eye"
                                      aria-hidden="true"
                                    ></i>
                                  ) : (
                                    <i
                                      className="fa fa-eye-slash"
                                      aria-hidden="true"
                                    ></i>
                                  )}
                                </button>
                              </div>
                              <div className="invalid-feedback">
                                {errors.password?.message}
                              </div>
                            </div>
                            {passwordError ? (
                              <span>
                                <ul className="text-danger">
                                  <li>
                                    <small>Minimum 8 characters required</small>
                                  </li>
                                  <li>
                                    <small>
                                      Atleast one: Upper-case, Number and
                                      Special Character
                                      {" .!@#$%^&* "}
                                    </small>
                                  </li>
                                </ul>
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="p-2 mt-2">
                          <Link to="/login">
                            <label style={{ cursor: "pointer" }}>
                              Already Registered Company?
                            </label>
                          </Link>
                        </div>
                        <div>
                          <button
                            // type="submit"
                            disabled={isLoading}
                            style={{ background: "#228B32", width: "100%" }}
                            className=" btn card-footer text-center py-3 mt-2"
                          >
                            <div className="small">
                              <span
                                className="btn"
                                style={{ background: "#228B32" }}
                              >
                                {" "}
                                <b>Sign Up</b>
                                {isLoading ? (
                                  <span className="ml-2 spinner-grow spinner-grow-sm text-light" />
                                ) : null}
                              </span>
                            </div>
                          </button>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default Signup;
