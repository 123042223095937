import { createStyles, makeStyles } from "@material-ui/core/styles"

const textEditFieldStyles = makeStyles(theme =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      border: 1,
      borderRadius: 4,
      borderStyle: "solid",
      borderColor: "hsl(0,0%,80%)",
      width: "100%",
      paddingTop: 2,
      paddingBottom: 2,
      height: 20
    },
    textContainer: {
      flex: 1,
      outline: "none",
      border: 0,
      paddingLeft: 8,
      width: "100%",
      backgroundColor: "white"
    },
    cursorPointer: {
      cursor: "pointer"
    }
  })
)

export default textEditFieldStyles
