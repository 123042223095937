import React, { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getAttribute } from "../../services/auth";
import { addSurveyLocationAPI } from "../../services/axios";
import BackButton from "../back_button/back_button";
import HereMap from "./HereMap";
import SurveyLocationFields from "./SurveyLocationFields";

const AddSurveyLocation = () => {
  const data = {
    name: "",
    site: "",
  };

  const [formData, setFormData] = useState({});
  const [mapData, setMapData] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [isValid, setIsValid] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function fetchUserAttribute() {
      let data = await getAttribute();
      console.log(data["custom:companyId"]);
      setCurrentUser(data);
      setIsValid(false);
    }
    fetchUserAttribute();
  }, []);

  const handleFormData = (data, valid) => {
    // console.log("Handle", data);
    setFormData(data);
    setIsValid(valid);
  };

  const handleMapData = (data) => {
    // console.log("Map Data", data.mapData);
    setMapData(data);
  };

  const handleAddSurvey = async (e) => {
    if (isValid) {
      e.preventDefault();

      let temp = formData;
      temp.companyId = currentUser["custom:companyId"];
      temp.createdBy = currentUser["custom:userId"];
      temp.updatedBy = currentUser["custom:userId"];
      // setFormData(temp);
      temp.mapData = mapData.mapData;
      let res = await addSurveyLocationAPI(temp);
      // console.log(res);
      if (res.data.statusCode === 200) {
        toast.success(res.data.message);
        history.push("/surveylocation");
      }
    }
  };

  return (
    <>
      <h4>Add Survey Location</h4>
      <div className="container">
        <div className="row">
          <SurveyLocationFields
            data={data}
            dataChange={handleFormData}
            setFormValid={setIsValid}
          />
        </div>
        <div className="row">
          <HereMap dataChange={handleMapData} />
        </div>
        <div className="row mt-3" style={{ position: "block" }}>
          <div className="col-5">
            <BackButton />
          </div>
          <div className="col text-right">
            <button
              className="btn btn-primary btn-sm"
              onClick={handleAddSurvey}
            >
              <FaSave className="mr-1" />
              <span>Save Survey</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSurveyLocation;
