import { TableSortLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
//confirmation dialogue
import { saveAs } from 'file-saver';
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { FaCheckCircle, FaFileDownload, FaSpinner, FaTimesCircle, FaTrash, FaUserEdit } from "react-icons/fa";
import Pagination from "react-responsive-pagination";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getAttribute } from "../../services/auth";

import Table from 'react-bootstrap/Table';
import { deleteDecryptionFile, deleteSurveyFile, deleteSurveyLocationAPI, getDecryptionFileList, getSignedURL, getSignedURLDownLoadFile, getSignedURLSurvey, getSurveyFileList, surveyLocationListAPI, uploadFileAsBinary } from "../../services/axios";
import SearchBox from "../search_box/SearchBar";
import Spinner from "../spinner/Spinner";

const SurveyLocation = () => {
  const surveyLocation = {
    id: "UUID",
    companyId: "company",
    name: "My Company",
    workOrder: "workOrder",
    site: "site",
    area: "area",
    notes: "notes",
    temperatureDegC: 23.23,
    humidityPercent: 50.2,
    weather: "weather",
    soilType: "soilType",
    soilCondition: "soilCondition",
    soil: "soil",
    fill: "fill",
    latitude: 23,
    longitude: 23,
    createdAt: new Date().toISOString(),
    createdBy: "UUID",
    updatedAt: new Date().toISOString(),
    updatedBy: "UUID",
    mapData: {
      dotPointsData: [],
      linePointsData: [],
    },
  };

  const history = useHistory();
  const [isActiveIni, setActiveIni] = useState(null);
  const [isActiveStg, setActiveStg] = useState(null);

  const [locations, setLocations] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalLocation, setTotalLocation] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("updatedAt");
  const [direction, setDirection] = useState("asc");
  const [tableSort, setTableSort] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalFile, setTotalFile] = useState(0);
  const [totalFileIni, setTotalFileIni] = useState(0);
  const [userAttribute, setUserAttribute] = useState(null);
  const [currentFilelist, setCurrentFilelist] = useState([]);
  const [currentFilelistIni, setCurrentFilelistIni] = useState([]);
  const [file, setFile] = useState()

  async function fetchFileList() {
    setLoading(true);
    await getDecryptionFileList(userAttribute["custom:userId"],"surveylocation").then((response) => {
        setCurrentFilelist(response.data);
        setTotalFile(response.data.length);
        setLoading(false);
    })

    await getSurveyFileList(userAttribute["custom:userId"]).then((response) => {
      setCurrentFilelistIni(response.data);
      setTotalFileIni(response.data.length);
      setLoading(false);
  })
  }

  async function fetchUserAttribute() {
    // if(userAttribute == {}){
      setLoading(true);
        await getAttribute().then((response) => {
          setUserAttribute(response);
          setLoading(false);
        })
    // }
  }
  
  async function handleFileDownload(download_file_name) {
    setLoading(true);
        getSignedURLDownLoadFile(download_file_name,userAttribute["custom:userId"],"surveylocation").then((signedURL) => {
            console.log(signedURL.data);
            saveAs(signedURL.data, download_file_name);
            //window.open(signedURL.data, "_blank");
            setLoading(false);
        })
  }

  useEffect(() => {
    fetchUserAttribute();
  }, []);

  useEffect(() => {
    if(userAttribute){
    fetchFileList();
    }
  },[userAttribute]);

  useEffect(() => {
    async function fetchUserAttribute() {
      setLoading(true);
      let data = await getAttribute();
      console.log("data: ", data);
      if (data["custom:role"] === "superadmin") {
        history.push("/home");
        return;
      }
      setCurrentUser(data);
      let survey = await surveyLocationListAPI(
        data["custom:companyId"],
        currentPage,
        searchString,
        sortBy,
        orderBy
      );
      if (survey.data.statusCode === 200) {
        setLocations(survey.data.data);
        setTotalLocation(survey.data.total);
        setTotalPage(Math.ceil(survey.data.total / 10));
      } else {
        toast.error(survey.data.message);
      }
      setLoading(false);
    }
    fetchUserAttribute();
  }, [currentPage]);


  const deleteFile = async (file_name,download_file_name,fileId) => {
    deleteDecryptionFile(download_file_name,file_name, userAttribute["custom:userId"],fileId,"surveylocation").then((response) => {
        console.log(response);
        fetchFileList();
    })
  };

  const deleteFileIni = async (fileId) => {
    deleteSurveyFile(userAttribute["custom:userId"],fileId).then((response) => {
        console.log(response);
        fetchFileList();
    })
  };
    
  const handleDeleteLocation = async (id) => {
    setLoading(true);
    let res = await deleteSurveyLocationAPI(
      id,
      currentUser["custom:companyId"]
    );
    if (res.data.statusCode === 200) {
      let temp = locations.filter((location) => location.id !== id);
      setLocations(temp);
      setTotalLocation(totalLocation - 1);
      if (temp.length === 0) {
        setCurrentPage(currentPage - 1);
        // setTotalPage(totalPage - 1);
      }
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
    setLoading(false);
  };

  const showConfirmationDialogue = (sid) => {
    confirmAlert({
      title: "Remove Location",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteLocation(sid),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const fetchLocations = async (query, sortBy, orderBy) => {
    setLoading(true);
    let survey = await surveyLocationListAPI(
      currentUser["custom:companyId"],
      currentPage,
      query,
      sortBy,
      orderBy
    );
    if (survey.data.statusCode === 200) {
      setLocations(survey.data.data);
      setTotalLocation(survey.data.total);
      setTotalPage(Math.ceil(survey.data.total / 10));
    } else {
      toast.error(survey.data.message);
    }
    setLoading(false);
  };

  const handleSearch = async (text) => {
    setSearchString(text);
    await fetchLocations(text, sortBy, orderBy);
  };

  const handleSortByChange = async (e) => {
    e.preventDefault();
    setSortBy(e.target.value);
    await fetchLocations(searchString, e.target.value, orderBy);
  };

  const handleOrderChange = async (e) => {
    e.preventDefault();
    setOrderBy(e.target.value);
    await fetchLocations(searchString, sortBy, e.target.value);
  };

  const handleSort = async (name, e) => {
    e.preventDefault();
    // let temp = direction === "asc" && tableSort === name ? "desc" : "asc";
    // setDirection(temp);
    // setTableSort(name);
    let temp = orderBy === "asc" && sortBy === name ? "desc" : "asc";
    setSortBy(name);
    setOrderBy(temp);
    // console.log(name + ":" + temp);
    await fetchLocations(searchString, name, temp);
  };

  function handleChange(event) {
    setFile(event.target.files[0])
  }

  const getBlob = async (fileUri) => {
    const resp = await fetch(fileUri);
    const imageBody = await resp.blob();
    return imageBody;
};

  async function handleSubmitINI(event) {
    event.preventDefault();
    // const file = dataObj?.assets[0];
    const imageBody = await getBlob(URL.createObjectURL(file))
    setLoading(true);
    // const url = 'http://localhost:3000/uploadFile';
    // const formData = new FormData();
    // formData.append('data', file);
    // formData.append('file_name', file.name);
        getSignedURLSurvey(file.name,userAttribute["custom:userId"]).then((signedURL) => {
            uploadFileAsBinary(signedURL.data,imageBody,"surveylocation").then((response) => {
                console.log(response);
                fetchFileList();
            });
        })
  }

  const toggleActiveIni = i => {
    //Remove the if statement if you don't want to unselect an already selected item
    if (i === isActiveIni) {
      setActiveIni(null);
    } else {
      setActiveIni(i);
    }
  };

  const toggleActiveStg = i => {
    //Remove the if statement if you don't want to unselect an already selected item
    if (i === isActiveStg) {
      setActiveStg(null);
    } else {
      setActiveStg(i);
    }
  };
  
  async function handleSubmitSTG(event) {
    event.preventDefault();
    // const file = dataObj?.assets[0];
    const imageBody = await getBlob(URL.createObjectURL(file))
    setLoading(true);
    // const url = 'http://localhost:3000/uploadFile';
    // const formData = new FormData();
    // formData.append('data', file);
    // formData.append('file_name', file.name);
        getSignedURL(file.name,userAttribute["custom:userId"],"surveylocation").then((signedURL) => {
            uploadFileAsBinary(signedURL.data,imageBody,"surveylocation").then((response) => {
                console.log(response);
                fetchFileList();
            });
        })
  }

  var selectRowProp = {
    mode: "checkbox",
    clickToSelect: true,
  };

  return (
    <>
      {
      loading==true ? <Spinner />: <p></p>
      } 
      <div className="container">
        <div className="mt-2 mb-2 text-right">
          <Link to="/addsurveylocation">
            <button type="button" className="btn btn-info">
              <span className="mr-2">
                <i className="fa fa-plus" aria-hidden="true" />
              </span>
              Add Location
            </button>
          </Link>
        </div>
        <div className="row mb-3 mt-1 ml-1">
          <div className="col-4">
            <div className="input-group">
              <div className="input-group-append">
                <label className="input-group-text">Sort By</label>
              </div>
              <select
                className="form-control"
                defaultValue={sortBy}
                onChange={handleSortByChange}
              >
                <option value="updatedAt">Updated At</option>
                <option value="createdAt">Created At</option>
                <option value="name">Name</option>
                <option value="wordOrder">Work Order</option>
                <option value="site">Site</option>
                <option value="area">Area</option>
                <option value="notes">Notes</option>
                <option value="weather">Weather</option>
                <option value="soilType">Soil Type</option>
                <option value="soilCondition">Soil Condition</option>
                <option value="soil">Soil</option>
                <option value="fill">Fill</option>
                <option value="temperatureDegC">Temperature</option>
                <option value="humidityPercent">Humidity</option>
                <option value="latitude">Latitude</option>
                <option value="longitude">Longitude</option>
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="input-group">
              <div className="input-group-append">
                <label className="input-group-text">Order</label>
              </div>
              <select
                className="form-control"
                defaultValue={orderBy}
                onChange={handleOrderChange}
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>
          <div className="col-5">
            <SearchBox defaultValue={searchString} onSearch={handleSearch} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex bd-highlight mb-n4">
                  <div className="mr-auto p-2">
                    <h5 className="card-title text-uppercase mb-0">
                      Survey Locations
                    </h5>
                  </div>
                  <div className="p-2 bd-highlight">
                    <label>
                      Total : <b>{totalLocation}</b>
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="table-responsive">
                <table className="table table-striped no-wrap user-table mb-0">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium pl-4"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Name
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "name"}
                          onClick={(e) => handleSort("name", e)}
                        />
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Site
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "site"}
                          onClick={(e) => handleSort("site", e)}
                        />
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Area
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "area"}
                          onClick={(e) => handleSort("area", e)}
                        />
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Latitude
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "latitude"}
                          onClick={(e) => handleSort("latitude", e)}
                        />
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Longitude
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "longitude"}
                          onClick={(e) => handleSort("longitude", e)}
                        />
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Weather
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "weather"}
                          onClick={(e) => handleSort("weather", e)}
                        />
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Manage
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {locations.map((location, index) => {
                      return (
                        <tr key={index}>
                          <td className="pl-4">
                            {index + 1 + 10 * (currentPage - 1)}
                          </td>
                          <td>
                            <h6 className="font-medium mb-0">
                              {location.name}
                            </h6>
                          </td>
                          <td>
                            <span className="text-muted">{location.site}</span>
                          </td>
                          <td>
                            <span className="text-muted">{location.area}</span>
                          </td>
                          <td>
                            <span className="text-muted">
                              {location.latitude}
                            </span>
                          </td>
                          <td>
                            <span className="text-muted">
                              {location.longitude}
                            </span>
                          </td>
                          <td>
                            <span className="text-muted">
                              {location.weather}
                            </span>
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: "/editsurveylocation",
                                state: {
                                  location: location,
                                },
                              }}
                            >
                              <button type="button" className="btn btn-circle">
                                <FaUserEdit className="" color="grey" />
                              </button>
                            </Link>
                            <button
                              type="button"
                              className="btn btn-circle ml-2"
                              onClick={(e) =>
                                showConfirmationDialogue(location.id)
                              }
                            >
                              <FaTrash className="" color="grey" />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div style={{ marginLeft: "1070px", marginTop: "20px" }}>
                  <Pagination
                    current={currentPage}
                    total={totalPage}
                    onPageChange={setCurrentPage}
                    maxWidth={5}
                    extraClassName="justify-content-start"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <br/><br/><br/><hr/>
        <div className="row">
          <div className="mt-2 mb-2">
            <form onSubmit={handleSubmitINI}>
              <h4>Please upload your file INI:</h4>
              <input type="file" onChange={handleChange}/>
              <button type="submit" className="btn btn-info">
                <span className="mr-2">
                  <i className="fa fa-upload" aria-hidden="true" />
                </span>
                Upload
              </button>
            </form>
          </div>
        </div>

        <br/>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex bd-highlight mb-n4">
                  <div className="mr-auto p-2">
                    <h5 className="card-title text-uppercase mb-0">
                      INI Files
                    </h5>
                  </div>
                  <div className="p-2 bd-highlight">
                    <label>
                      Total : <b>{totalFileIni}</b>
                    </label>
                  </div>
                  <div className="p-2 bd-highlight">
                    <button className="btn btn-info" onClick={() => fetchFileList()}>
                        <span className="mr-2">
                            <i className="fa fa-refresh" aria-hidden="true" />
                        </span>
                    Refresh
                    </button>
                    &nbsp;
                    <button className="btn btn-info">
                        <span className="mr-2">
                            <i className="fa fa-trash" aria-hidden="true" />
                        </span>
                    Delete All
                    </button>
                  </div>
                </div>
              </div>
              <hr />
                {/* <table className="table table-striped no-wrap decryption-table mb-0"> */}
                <Table striped bordered hover>
                  <thead>
                    <tr>
                    <th
                      >
                        Select
                      </th>
                      <th
                      >
                        #
                      </th>
                      <th
                      >
                        File Name
                        {/* <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "file_name"}
                        /> */}
                      </th>
                        <th
                        >
                          Delete
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                  { currentFilelistIni && currentFilelistIni.length > 0 ?currentFilelistIni.map((file, index) => {
                      return (
                        <tr
                        style={
                          isActiveIni === index
                            ? { background: '#d3d3d3' }
                            : { background: 'white' }
                        }
                        key={index}
                        onClick={() => toggleActiveIni(index)}
                      >
                        <td>
                        <input type="checkbox" checked={ isActiveIni === index}/> 
                        </td>
                          <td 
                          >
                            {index + 1}
                          </td>
                          <td>
                            <h5 
                            >
                              {file.file_name}
                            </h5>
                          </td>
                           
                            <td>
                                <button
                                type="button"
                                className="btn btn-circle ml-2"
                                onClick={() => deleteFileIni(file.id)}>
                                <FaTrash className="" color="grey" />
                                </button>
                            </td>
                        </tr>
                      );
                    })
                    : <div>No records found</div>}
                  </tbody>
                </Table>
            </div>
            <div className="mt-2 mb-2">
            <form onSubmit={handleSubmitSTG}>
              <h4>Please upload your file STG:</h4>
              <input type="file" onChange={handleChange}/>
              <button type="submit" className="btn btn-info">
                <span className="mr-2">
                  <i className="fa fa-upload" aria-hidden="true" />
                </span>
                Upload
              </button>
            </form>
          </div>
            <div className="card">
              <div className="card-body">
                <div className="d-flex bd-highlight mb-n4">
                  <div className="mr-auto p-2">
                    <h5 className="card-title text-uppercase mb-0">
                      STG Files
                    </h5>
                  </div>
                  <div className="p-2 bd-highlight">
                    <label>
                      Total : <b>{totalFile}</b>
                    </label>
                  </div>
                  <div className="p-2 bd-highlight">
                    <button className="btn btn-info" onClick={() => fetchFileList()}>
                        <span className="mr-2">
                            <i className="fa fa-refresh" aria-hidden="true" />
                        </span>
                    Refresh
                    </button>
                    &nbsp;
                    <button className="btn btn-info">
                        <span className="mr-2">
                            <i className="fa fa-trash" aria-hidden="true" />
                        </span>
                    Delete All
                    </button>
                  </div>
                </div>
              </div>
              <hr />
                {/* <table className="table table-striped no-wrap decryption-table mb-0"> */}
                <Table striped bordered hover>
                  <thead>
                    <tr>
                    <th
                      >
                        Select
                      </th>
                      <th
                      >
                        #
                      </th>
                      <th
                      >
                        File Name
                        {/* <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "file_name"}
                        /> */}
                      </th>
                      <th
                      >
                        Upload Status
                        {/* <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "status"}
                        /> */}
                      </th>
                        <th
                        >
                          Download
                        </th>
                        <th
                        >
                          Delete
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                  { currentFilelist && currentFilelist.length > 0 ?currentFilelist.map((file, index) => {
                      return (
                        <tr
                        style={
                          isActiveStg === index
                            ? { background: '#d3d3d3' }
                            : { background: 'white' }
                        }
                        key={index}
                        onClick={() => toggleActiveStg(index)}
                      >
                        <td>
                        <input type="checkbox" checked={ isActiveStg === index}/> 
                        </td>
                          <td>
                            {index + 1}
                          </td>
                          <td>
                            <h5 
                            >
                              {file.file_name}
                            </h5>
                          </td>
                            <td>
                                <span className="">
                                {
                                    file.status ==='completed' ? <p>Completed <FaCheckCircle className="" color="green" /></p> : <p></p>
                                } 
                                
                                {
                                    file.status ==='process' ? <p>In-Progress <FaSpinner className="" color="yellow" /></p> : <p></p>
                                } 
                                
                                {
                                    file.status ==='undefined' ? <p>Failed <FaTimesCircle className="" color="red" /></p> : <p></p>
                                } 
                                </span>
                            </td>
                            <td>
                                {file.url !='-' &&
                                <button
                                type="button"
                                className="btn btn-circle ml-2"
                                href={file.url}
                                target="_blank"
                                onClick={() => handleFileDownload(file.download_file_name)}
                                >
                                <FaFileDownload className="" color="grey" />
                                </button>
                                }
                            </td>
                            <td>
                                <button
                                type="button"
                                className="btn btn-circle ml-2"
                                onClick={() => deleteFile(file.file_name,file.download_file_name,file.id)}>
                                <FaTrash className="" color="grey" />
                                </button>
                            </td>
                        </tr>
                      );
                    })
                    : <div>No records found</div>}
                  </tbody>
                </Table>
            </div>

            
          </div>
        </div>

      </div>
    </>
  );
};

export default SurveyLocation;
