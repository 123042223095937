import React, { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { changePassword, getAttribute } from "../../services/auth";
import { userChangePassword } from "../../services/axios";
import BackButton from "../back_button/back_button";


const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldEye, setOldEye] = useState(true);
  const [newEye, setNewEye] = useState(true);
  const [currentUser, setCurrentUser] = useState({});
  const history = useHistory();
  let pattern = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[.!@#$%^&*])[a-zA-Z0-9.!@#$%^&*]{8,24}$/;
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required').matches(pattern, "Enter a Valid Password"),
    newPassword:Yup.string().required('New Password is required').matches(pattern,"Enter a Valid Password")
  })
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register,
         handleSubmit,
  formState:{errors},}=useForm(formOptions)

  useEffect(() => {
    async function fetchUserAttribute() {
      let data = await getAttribute();
      setCurrentUser(data);
    }
    fetchUserAttribute();
  }, []);

  const handleOldPasswordChange = (e) => {
    e.preventDefault();
    setOldPassword(e.target.value);
  };

  const handleNewPasswordChange = (e) => {
    e.preventDefault();
    setNewPassword(e.target.value);
  };

  const handlePasswordChange = async (e) => {
    // e.preventDefault();
    // if (newPassword.trim().length < 6 || oldPassword.trim().length < 6) {
    //   toast.error("Password must be greater then 6 characters.");
    //   return;
    // }
    if (e.newPassword.trim() === e.oldPassword.trim()) {
      toast.error("New password cannot be old password.");
      return;
    }
    // if ((e.newPassword.trim().length === e.oldPassword.trim().length) === 0) {
    //   toast.error("Password cannot be empty.");
    //   return;
    // }
    console.log('oldPassword ', e.oldPassword);
    console.log('newPassword', e.newPassword);
    let res = await changePassword(e.oldPassword, e.newPassword);

    if (res.success) {
      let passData = await userChangePassword(
        currentUser["email"],
        e.oldPassword,
        e.newPassword
      );
      if (passData.data.statusCode === 200) {
        toast.success("Password has been changed.");
        goToPreviousPath();
      } else {
        toast.error(passData.data.message);
      }
    } else {
      toast.error(res.message);
    }
  };

  const goToPreviousPath = (e) => {
    history.goBack();
  };

  return (
    <>
      <div className="container">
        <form onSubmit={handleSubmit(handlePasswordChange)}>
          <div className="row">
            <div className="col-md-6 p-5">
              <div>
                <label>Old Password<span className="text-danger">*</span></label>
                <div className="input-group">
                  <input
                    type={oldEye ? "password" : "text"}
                    
                    placeholder="Enter old password"
                    onChange={handleOldPasswordChange}
                    {...register("oldPassword")}
                    className={`form-control ${
                      errors.oldPassword? "is-invalid" : ""
                    }`}
                  />
                  <div className="input-group-append">
                    <button
                      className="input-group-text"
                      type="button"
                      onClick={(e) => setOldEye(!oldEye)}
                    >
                      {oldEye ? (
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>
                      )}
                    </button>
                  </div>
                  <div className="invalid-feedback">
                            {errors.oldPassword?.message}
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>New Password<span className="text-danger">*</span></label>
                <div className="input-group">
                  <input
                    type={newEye ? "password" : "text"}
                    {...register("newPassword")}
                    placeholder="Enter new password"
                    onChange={handleNewPasswordChange}
                    className={`form-control ${
                      errors.newPassword ? "is-invalid" : ""
                    }`}
                  />
                  <div className="input-group-append">
                    <button
                      className="input-group-text"
                      type="button"
                      onClick={(e) => setNewEye(!newEye)}
                    >
                      {newEye ? (
                        <i className="fa fa-eye" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-eye-slash" aria-hidden="true"></i>
                      )}
                    </button>
                  </div>
                  <div className="invalid-feedback">
                            {errors.newPassword?.message}
                  </div>
                </div>
               
              </div>
              <div className="form-group mt-2 row">
                <div className="col">
                  <BackButton />
                </div>
                <div className="col text-right">
                  <button className="btn btn-primary btn-sm" type="submit">
                    <FaSave className="mr-1 mb-1" />
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
