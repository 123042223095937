import { useEffect, useState } from "react";
import { BiPurchaseTag } from "react-icons/bi";
import { MdManageAccounts, MdPayments } from "react-icons/md";
import { Link, useHistory } from "react-router-dom";
import { getAttribute } from "../../services/auth";
import {
  cbInstance,
  getCBSession,
  getUpdatePaymentHostedPage,
  initChargebee,
} from "../../services/chargebee";

const Subscription = () => {
  const [currentUser, setCurrentUser] = useState({});
  const history = useHistory();
  useEffect(() => {
    async function fetchAttribute() {
      let data = await getAttribute();
      setCurrentUser(data);
    }
    fetchAttribute();
  }, []);

  if (currentUser["custom:role"] === "technician") {
    history.push("/home");
  }
  const handleOpenChargebee = async (e) => {
    e.preventDefault();
    await showChargebeePortal(currentUser["custom:companyId"]);
  };

  const handleUpdatePaymentChargebee = async (e) => {
    e.preventDefault();
    await showUpdatePaymentPortal(currentUser["custom:companyId"]);
  };

  const showUpdatePaymentPortal = async (id) => {
    if (!cbInstance) {
      await initChargebee();
    }

    const portal = await getUpdatePaymentHostedPage(id);
    console.log(portal.data);
    if (portal.data.statusCode === 200) {
      cbInstance.openCheckout({
        hostedPage: async () => portal.data.data,
        close: () => {
          console.log("Close update payment");
        },
      });
    } else {
      console.log(portal.data);
    }
  };

  const showChargebeePortal = async (id) => {
    if (!cbInstance) {
      await initChargebee();
    }

    const portalSession = await getCBSession(id);

    if (portalSession.data.statusCode === 200) {
      cbInstance.setPortalSession(portalSession.data.data);

      cbInstance.createChargebeePortal().open({
        visit: (sectionType) => {
          console.log("SectionType:", sectionType);
        },
      });
    } else {
      console.log(portalSession.data);
    }
  };
  return (
    <>
      {/* <Link to="/plan" className="btn btn-sm btn-secondary m-2">
        <button className="btn btn-sm">
          <MdSubscriptions /> <span className="pt-2">Add Subscription</span>
        </button>
      </Link> */}

      <Link to="/purchase" className="btn btn-sm btn-info m-2">
        <button className="btn btn-sm">
          <BiPurchaseTag /> <span className="pt-2">Purchase</span>
        </button>
      </Link>

      <button
        className="btn btn-sm btn-success m-2 p-2"
        onClick={handleOpenChargebee}
      >
        <MdManageAccounts className="mr-1 mb-1" />
        <span>Manage Account</span>
      </button>
      <button
        className="btn btn-sm btn-primary m-2 p-2"
        onClick={handleUpdatePaymentChargebee}
      >
        <MdPayments className="mr-1 mb-1" />
        <span>Manage Payment</span>
      </button>
      {/* <button
        className="btn btn-sm btn-info m-2"
        onClick={handleCheckoutChargebee}
      >
        Checkout
      </button> */}
    </>
  );
};

export default Subscription;
