import { createStyles, makeStyles } from "@material-ui/core/styles"

const measurementControlStyles = makeStyles(theme =>
  createStyles({
    container: {
      maxWidth: 600,
      width: "100%",
      marginLeft: 50,
      marginBottom: 10,
      marginTop: 10
    },
    readyForUserText: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: 10,
      paddingBottom: 10
    },
    circleStatus: {
      width: 5,
      height: 5,
      borderRadius: 5,
      backgroundColor: "gray",
      display: "block"
    },
    statusText: {
      fontSize: 10,
      paddingLeft: 5
    },
    customContainerTopBorder: {
      borderTopWidth: "1px",
      borderLeftWidth: 0,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      borderStyle: "solid",
      borderColor: "gray"
    },
    paddingTop15: {
      paddingTop: 15
    },
    paddingBottom15: {
      paddingBottom: 15
    },
    customContainerRightBorder: {
      borderRightWidth: 1,
      borderTopWidth: 0,
      borderLeftWidth: 0,
      borderBottomWidth: 0,
      borderStyle: "solid",
      borderColor: "gray"
    }
  })
)

export default measurementControlStyles
