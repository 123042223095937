import { Grid, ListItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { MdManageAccounts, MdPayments } from "react-icons/md";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getAttribute } from "../../services/auth";
import { getAllChargebeePlans } from "../../services/axios";
import {
  cbInstance,
  getCBSession,
  getCheckoutHostedPage,
  getUpdatePaymentHostedPage,
  initChargebee,
  getUserSubscription,
} from "../../services/chargebee";
import CompanyList from "../company_list/company_list";
import Spinner from "../spinner/Spinner";
import BackButton from "../back_button/back_button";

const Purchase = () => {
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [activeSub, setActiveSub] = useState([]);
  const [cancelSub, setCancelSub] = useState([]);
  var d = new Date(0);

  useEffect(() => {
    fetchAttribute();
    fetchPlans();
    console.log("history: ", history.location);
  }, []);

  async function fetchAttribute() {
    let data = await getAttribute();

    if (data["custom:role"] == "technician") {
      history.push("/home");
      return;
    } else if (data["custom:role"] == "admin") {
      handleSubscriptionList(data["custom:companyId"]);
    } else {
      handleSubscriptionList(history.location.state.id);
    }
    setCurrentUser(data);
  }

  const fetchPlans = async () => {
    setLoading(true);
    let data = await getAllChargebeePlans();
    if (data.data.statusCode === 200) {
      setItems(data.data.data.subscription);
    } else {
    }
    setLoading(false);
  };

  const handleSubscriptionList = async (id) => {
    const result = await getUserSubscription(id);
    console.log("result: ", result.data.data);
    setActiveSub(result.data.data.activeSub);
    setCancelSub(result.data.data.cancelSub);
  };

  const handleSubscribe = async (e, item) => {
    e.preventDefault();
    // alert(JSON.stringify(item, null, 2));
    // toast.info("To Do");
    await showCheckoutPortal(
      currentUser["custom:companyId"],
      item.item_price.id
    );
  };

  const showCheckoutPortal = async (id, plan_id) => {
    if (!cbInstance) {
      await initChargebee();
    }
    // let addons = currentAddon.item_price ? currentAddon.item_price.id : null;
    // let coupon_id = currentCoupon.coupon ? currentCoupon.coupon.id : null;

    const portal = await getCheckoutHostedPage(id, plan_id);
    console.log(portal.data);
    if (portal.data.statusCode === 200) {
      cbInstance.openCheckout({
        hostedPage: async () => portal.data.data,
        close: () => {
          console.log("Close checkout");
        },
        success(hostedPageId) {
          console.log("hostedPageId", hostedPageId);
        },
        step(step) {
          console.log("checkout", step);
        },
      });
    } else {
      toast.error(portal.data.error.error_msg);
    }
  };

  const showChargebeePortal = async (id) => {
    if (!cbInstance) {
      await initChargebee();
    }

    const portalSession = await getCBSession(id);

    if (portalSession.data.statusCode === 200) {
      cbInstance.setPortalSession(portalSession.data.data);

      cbInstance.createChargebeePortal().open({
        visit: (sectionType) => {
          console.log("SectionType:", sectionType);
        },
      });
    } else {
      console.log(portalSession.data);
    }
  };

  const showUpdatePaymentPortal = async (id) => {
    if (!cbInstance) {
      await initChargebee();
    }

    const portal = await getUpdatePaymentHostedPage(id);
    console.log(portal.data);
    if (portal.data.statusCode === 200) {
      cbInstance.openCheckout({
        hostedPage: async () => portal.data.data,
        close: () => {
          console.log("Close update payment");
        },
      });
    } else {
      console.log(portal.data);
    }
  };

  const handleOpenChargebee = async (e) => {
    e.preventDefault();
    await showChargebeePortal(currentUser["custom:companyId"]);
  };

  const handleUpdatePaymentChargebee = async (e) => {
    e.preventDefault();
    await showUpdatePaymentPortal(currentUser["custom:companyId"]);
  };

  return (
    <>
      {
      isLoading==true ? <Spinner />: <p></p>
      } 
      <div className="text-right p-2">
        <button
          className="btn btn-sm btn-success m-2 p-2"
          onClick={handleOpenChargebee}
        >
          <MdManageAccounts className="mr-1 mb-1" />
          <span>Manage Subscriptions</span>
        </button>
        <button
          className="btn btn-sm btn-secondary m-2 p-2"
          onClick={handleUpdatePaymentChargebee}
        >
          <MdPayments className="mr-1 mb-1" />
          <span>Manage Payment</span>
        </button>
      </div>
      <h4 className="card-title text-center">Active Plans</h4>

      {activeSub.length === 0 ? (
        <div className="card-body">
          <p className="card-title text-center">N/A</p>
        </div>
      ) : (
        <Grid container>
          {activeSub.map((item, index) => {
            let d1 = new Date(0);
            d1.setUTCSeconds(item.subscription.current_term_start);
            let d2 = new Date(0);

            d2.setUTCSeconds(item.subscription.current_term_end);
            return (
              <ListItem>
                <div className="card shadow" style={{ width: "100%" }}>
                  <div className="card-body">
                    {item.subscription.subscription_items.map((child) => {
                      return (
                        <p>
                          {child.item_price_id}{" "}
                          <span
                            className="text-right"
                            style={{ float: "right" }}
                          >
                            ${child.unit_price}
                          </span>
                        </p>
                      );
                    })}
                    <b>Starts Date:</b>
                    <span className="ml-2">{d1.toUTCString()}</span>
                    <br />
                    <b>End Date:</b>
                    <span className="ml-2">{d2.toUTCString()}</span>
                  </div>
                </div>
              </ListItem>
            );
          })}
        </Grid>
      )}

      <h4 className="card-title text-center">Subscription Completed</h4>
      {cancelSub.length === 0 ? (
        <div className="card-body">
          <p className="card-title text-center">N/A</p>
        </div>
      ) : (
        <Grid container>
          {cancelSub.map((item, index) => {
            let d1 = new Date(0);
            d1.setUTCSeconds(item.subscription.current_term_start);
            let d2 = new Date(0);

            d2.setUTCSeconds(item.subscription.current_term_end);
            return (
              <ListItem>
                <div className="card shadow" style={{ width: "100%" }}>
                  <div className="card-body">
                    {item.subscription.subscription_items.map((child) => {
                      return (
                        <p>
                          {child.item_price_id}{" "}
                          <span
                            className="text-right"
                            style={{ float: "right" }}
                          >
                            ${child.unit_price}
                          </span>
                        </p>
                      );
                    })}
                    <b>Starts From:</b>
                    <span className="ml-2">{d1.toUTCString()}</span>
                    <br />
                    <b>End Date:</b>
                    <span className="ml-2">{d2.toUTCString()}</span>
                  </div>
                </div>
              </ListItem>
            );
          })}
        </Grid>
      )}
      {currentUser["custom:role"] === "superadmin" ? null : (
        <>
          <h4 className="card-title text-center">Available Plans</h4>
          <Grid container>
            {items.map((item, index) => {
              return (
                <Grid item xs={6} md={4} xl={3} key={index}>
                  <ListItem>
                    <div className="card shadow">
                      <img
                        src="GeoStack.png"
                        className="card-img-top p-4"
                        alt="..."
                      />
                      <div className="card-body text-center">
                        <h5 className="card-title">
                          {item.item_price.external_name}
                        </h5>
                        <h6 className="card-title">${item.item_price.price}</h6>
                        <p className="card-text">
                          {item.item_price.description}
                        </p>
                        <button
                          className="btn btn-primary"
                          onClick={(e) => handleSubscribe(e, item)}
                        >
                          <i className="fa fa-rocket mr-2" aria-hidden="true" />
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </ListItem>
                </Grid>
              );
            })}
          </Grid>{" "}
        </>
      )}

      <div className="row">
        <div className="col">
          <BackButton />
        </div>
      </div>
    </>
  );
};

export default Purchase;
