import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaArrowLeft, FaSave } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { getAttribute } from "../../services/auth";
import { userUpdate } from "../../services/axios";

const EditProfile = (props) => {
  const location = useLocation();
  const history = useHistory();
  const userData = location.state;
  // const localUser = JSON.parse(localStorage.getItem("user"));
  const [firstName, setFirstName] = useState(userData.firstName.trim());
  const [lastName, setLastName] = useState(userData.lastName.trim());
  const [mobile, setMobile] = useState(userData.mobile.trim());
  const [status, setStatus] = useState(userData.status);
  const [lastStatus, setLastStatus] = useState(userData.status);
  const [currentUser, setCurrentUser] = useState({});
  const phoneRegExp = /^\d{10}$/;

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("FirstName is required")
      .max(50, "No more than 50 characters."),
    mobile: Yup.string()
      .required("Mobile number  is required")
      .matches(phoneRegExp, "Mobile number is invalid."),
    lastName: Yup.string().max(50, "No more than 50 characters."),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: userData,
  };
  const {
    register,
    setValue,
    handleSubmit,

    formState: { errors },
  } = useForm(formOptions);

  useEffect(() => {
    async function fetchUserAttribute() {
      let data = await getAttribute();
      setCurrentUser(data);
    }
    setValue("firstName", firstName, { shouldValidate: true });
    setValue("lastName", lastName, { shouldValidate: true });
    setValue("mobile", mobile);

    fetchUserAttribute();
  }, []);

  const handleFirstName = (e) => {
    e.preventDefault();
    setFirstName(e.target.value);
  };

  const handleLastName = (e) => {
    e.preventDefault();
    setLastName(e.target.value);
  };

  const handleMobile = (e) => {
    e.preventDefault();
    setMobile(e.target.value);
  };

  const handleStatus = (e) => {
    e.preventDefault();
    setStatus(parseInt(e.target.value));
  };

  const handleUpdate = (e) => {
    let payload = {
      updatedBy: userData.updatedBy,
      userId: userData.id,
      email: userData.email,
      firstName: e.firstName,
      lastName: e.lastName,
      mobile: e.mobile,
      status,
      lastStatus,
      flag: "update",
      companyId: userData.companyId,
    };
    userUpdate(payload)
      .then((data) => {
        if (data.data.statusCode === 200) {
          toast.success(data.data.message);
          goToPreviousPath();
        } else {
          toast.error(data.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const goToPreviousPath = (e) => {
    history.goBack();
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="p-5">
            <form onSubmit={handleSubmit(handleUpdate)}>
              <div className="row">
                <div className="col">
                  <label>
                    Firstname <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.firstName ? "is-invalid" : ""
                    }`}
                    placeholder="Enter First Name"
                    onChange={handleFirstName}
                    defaultValue={firstName}
                    {...register("firstName")}
                  />
                  <div className="invalid-feedback">
                    {errors.firstName?.message}
                  </div>
                </div>
                <div className="col">
                  <label>Lastname</label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.lastName ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Last Name"
                    defaultValue={lastName}
                    onChange={handleLastName}
                    {...register("lastName")}
                  />
                  <div className="invalid-feedback">
                    {errors.lastName?.message}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>
                    Mobile <span className="text-danger">*</span>
                  </label>
                  <input
                    // type="text"
                    className={`form-control ${
                      errors.mobile ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Mobile"
                    defaultValue={mobile}
                    type="tel"
                    onChange={handleMobile}
                    {...register("mobile")}
                  />
                  <div className="invalid-feedback">
                    {errors.mobile?.message}
                  </div>
                </div>
                <div className="col">
                  <label>Status</label>
                  <select
                    className="form-control"
                    defaultValue={status}
                    disabled={currentUser.email === userData.email.trim()}
                    onChange={handleStatus}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Deactivate</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter lastname"
                    defaultValue={userData.email}
                    disabled
                  />
                </div>
              </div>
              <div className="mt-3 row">
                <div className="col">
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={goToPreviousPath}
                    type="button"
                  >
                    <FaArrowLeft className="mr-1" />
                    Back
                  </button>
                </div>
                <div className="col text-right">
                  <button className="btn btn-primary btn-sm" type="submit">
                    <FaSave className="mr-1 mb-1" />
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfile;
