import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const SurveyLocationFields = ({ data, dataChange, setFormValid }) => {
  const [formData, setFormData] = useState(data ? data : {});

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(" Name is required")
      .max(50, "No more than 50 characters."),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: formData,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    clearErrors,
    reset,
    setValue,
  } = useForm(formOptions);

  const formChange = (event) => {
    console.log("event: ", event.target.value);
    setFormData({
      ...formData,
      [event.target.name]: event.target.value.trim(),
    });
    setFormValid(isValid);
  };

  useEffect(() => {
    dataChange(formData, isValid);
    setValue("name", formData.name, { shouldValidate: true });
  }, [formData, isValid]);

  const newFields = () => {
    return (
      <>
        <div className="container">
          <form className="p-3">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Name<span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className={`form-control form-control-sm ${
                        errors.name ? "is-invalid" : ""
                      }`}
                      defaultValue={data.name}
                      placeholder="Name"
                      name="name"
                      autoFocus
                      {...register("name")}
                      onChange={formChange}
                    />
                    <div className="invalid-feedback">
                      {errors.name?.message}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Work Order
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Work Order"
                      defaultValue={data.workOrder}
                      name="workOrder"
                      {...register("workOrder")}
                      onChange={formChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Site
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      defaultValue={data.site}
                      placeholder="Site"
                      name="site"
                      maxLength={3}
                      {...register("site")}
                      onChange={formChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Area
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Area"
                      defaultValue={data.area}
                      name="area"
                      {...register("area")}
                      onChange={formChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Notes
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Notes"
                      defaultValue={data.notes}
                      name="notes"
                      {...register("notes")}
                      onChange={formChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Weather
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Weather"
                      defaultValue={data.weather}
                      name="weather"
                      {...register("weather")}
                      onChange={formChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Temperature °C
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Temperature"
                      defaultValue={data.temperatureDegC}
                      name="temperatureDegC"
                      maxLength={3}
                      {...register("temperatureDegC")}
                      onChange={formChange}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 3))
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Humidity %
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      // maxLength={3}
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Humidity"
                      defaultValue={data.humidityPercent}
                      name="humidityPercent"
                      {...register("humidityPercent")}
                      onChange={formChange}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 3))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Soil Type
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Soil Type"
                      defaultValue={data.soilType}
                      name="soilType"
                      {...register("soilType")}
                      onChange={formChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Soil Condition
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Soil Condition"
                      defaultValue={data.soilCondition}
                      name="soilCondition"
                      {...register("soilCondition")}
                      onChange={formChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Fill
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Fill"
                      defaultValue={data.fill}
                      name="fill"
                      {...register("fill")}
                      onChange={formChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Soil
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Soil"
                      defaultValue={data.soil}
                      name="soil"
                      {...register("soil")}
                      onChange={formChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Latitude
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      step="any"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Latitude"
                      defaultValue={data.latitude}
                      name="latitude"
                      {...register("latitude")}
                      onChange={formChange}
                      // maxLength={20}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 20))
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Longitude
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      step="any"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Longitude"
                      name="longitude"
                      defaultValue={data.longitude}
                      {...register("longitude")}
                      // maxLength={20}
                      onChange={formChange}
                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 20))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Number of Electrode Used
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Number of Electrode Used"
                      defaultValue={data.noOfElectrodeUsed}
                      name="noOfElectrodeUsed"
                      // {...register("latitude")}
                      // maxLength={4}

                      onInput={(e) =>
                        (e.target.value = e.target.value.slice(0, 4))
                      }
                      {...register("noOfElectrodeUsed")}
                      onChange={formChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group row">
                  <label
                    htmlFor="colFormLabelSm"
                    className="col-sm-3 col-form-label col-form-label-sm"
                  >
                    Tags
                  </label>
                  <div className="col-sm-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Tags"
                      defaultValue={data.tags}
                      name="tags"
                      // {...register("latitude")}
                      {...register("tags")}
                      onChange={formChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="card">
                <div class="card-header text-left">Project Lead Details:</div>
                <div class="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          First Name
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder="First Name"
                            name="PLFirstName"
                            defaultValue={data.PLFirstName}
                            {...register("PLFirstName")}
                            onChange={formChange}
                            maxLength={100}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          Last Name
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder="Last Name"
                            name="PLLastName"
                            defaultValue={data.PLLastName}
                            {...register("PLLastName")}
                            onChange={formChange}
                            maxLength={100}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          Email
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="email"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder="Email"
                            name="PLEmail"
                            maxLength={100}
                            defaultValue={data.PLEmail}
                            {...register("PLEmail")}
                            onChange={formChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          Company
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder="Company"
                            name="PLCompanyName"
                            defaultValue={data.PLCompanyName}
                            {...register("PLCompanyName")}
                            onChange={formChange}
                            maxLength={100}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div class="card">
                <div class="card-header text-left">Field Operator Details:</div>
                <div class="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          First Name
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder="First Name"
                            name="FOFirstName"
                            defaultValue={data.FOFirstName}
                            {...register("FOFirstName")}
                            onChange={formChange}
                            maxLength={100}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          Last Name
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder="Last Name"
                            name="FOLastName"
                            defaultValue={data.FOLastName}
                            {...register("FOLastName")}
                            onChange={formChange}
                            maxLength={100}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          Email
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="email"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder="Email"
                            name="FOEmail"
                            maxLength={100}
                            defaultValue={data.FOEmail}
                            {...register("FOEmail")}
                            onChange={formChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          Company
                        </label>
                        <div className="col-sm-8">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder="Company"
                            name="FOCompanyName"
                            defaultValue={data.FOCompanyName}
                            {...register("FOCompanyName")}
                            onChange={formChange}
                            maxLength={100}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          {/* <div className="row">
            <div className="col-md-6">
              <div className="form-group row">
                <label htmlFor="colFormLabelSm" className="col-sm-12 col-form-label col-form-label-sm">Address</label>
                <div className="col-sm-9">
                  <input type="text" className="form-control form-control-sm" id="colFormLabelSm" placeholder="address"
                    onChange={event => {
                      let { value } = event.target
                      setInitialStreetAddress(value)
                    }} />
                </div>
                <div className="col-sm-3">
                  <button className="btn btn-primary btn-sm" onClick={handleAddressSearch}><i className="fa fa-search" aria-hidden="true" ></i><span className="ml-1">Search</span></button>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </>
    );
  };

  return <>{newFields()}</>;
};

export default SurveyLocationFields;
