import { Auth } from "aws-amplify";
import React, { useEffect, useState, useCallback } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import About from "../components/about/about";
import AddUser from "../components/add_user/add_user";
import BackButton from "../components/back_button/back_button";
import ChangePassword from "../components/changepassword/changepassword";
import CompanyList from "../components/company_list/company_list";
import Dashboard from "../components/dashboard/dashboard";
import Device from "../components/device/device";
import DeviceInfo from "../components/deviceInfo/deviceInfo";
import EditCompany from "../components/edit_company/edit_company";
import EditProfile from "../components/edit_profile/edit_profile";
import ForgotPassword from "../components/forgotpassword/ForgotPassword";
import Header from "../components/header/header";
import Login from "../components/login/login";
import MQTTComponent from "../components/mqtt/mqtt";
import Profile from "../components/profile/profile";
import Purchase from "../components/purchase/purchase";
import Sidebar from "../components/sidebar/sidebar";
import Signup from "../components/signup/signup";
import AddSubscription from "../components/subscription/AddSubscription";
import Subscription from "../components/subscription/Subscription";
import AddSurveyLocation from "../components/surveylocation/AddSurveyLocation";
import EditSurveyLocation from "../components/surveylocation/EditSurveyLocation";
import SurveyLocation from "../components/surveylocation/surveylocation";
import UserList from "../components/user-list/user_list";
import AddWhiteListDevice from "../components/whitelistDevice/AddWhiteListDevice";
import WhiteListDevice from "../components/whitelistDevice/WhiteListDevice";
import { getAttribute } from "../services/auth";

import Decryption from "../components/decryption/decryption";
import { Container } from "react-bootstrap";

const MainRoute = () => {
  const isLogin = localStorage.getItem("isLogin");
  const [userAttribute, setUserAttribute] = useState({});
  useEffect(() => {
    async function fetchUserAttribute() {
      let data = await getAttribute();
      setUserAttribute(data);
    }
    fetchUserAttribute();
  }, []);
  const [showSidebar, setShowSidebar] = useState(true);
  const toggleSidebar = useCallback(() => setShowSidebar(value => !value));

  return (
    <Router>
    {isLogin ? (
      <div className="">
       {showSidebar ? ( <Sidebar />) : null}
      </div>
    ) : null}
      <div className="container-fluid">
        {isLogin ? (
          <div className="row">
            <div className="col-sm-12 shadow-sm p-1 bg-light rounded">
              <Header onClick={toggleSidebar} showSideBar={showSidebar} />
            </div>
          </div>
        ) : null}
        <div className="">
          <div className="row p-2 bg-light vh-100">
            <div className={isLogin ? "col-sm-12 bg-white" : "col-sm-12"}>
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) =>
                    isLogin ? <Redirect to="/home" /> : <Redirect to="/login" />
                  }
                />
                <ProtectedRoute exact path="/home" component={Dashboard} />
                <Route
                  path="/login"
                  render={(props) =>
                    isLogin ? <Redirect to="/home" /> : <Login {...props} />
                  }
                />

                <Route
                  path="/signup"
                  render={(props) =>
                    isLogin ? <Redirect to="/home" /> : <Signup {...props} />
                  }
                />
                <Route
                  path="/forgotpassword"
                  render={(props) =>
                    isLogin ? (
                      <Redirect to="/home" />
                    ) : (
                      <ForgotPassword {...props} />
                    )
                  }
                />
                <ProtectedRoute path="/deviceInfo" component={DeviceInfo} />
                <ProtectedRoute exact path="/user" component={UserList} />

                <ProtectedRoute exact path="/profile" component={Profile} />

                <ProtectedRoute
                  exact
                  path="/changepassword"
                  component={ChangePassword}
                />

                <ProtectedRoute
                  exact
                  path="/edituser"
                  component={EditProfile}
                />

                <ProtectedRoute exact path="/adduser" component={AddUser} />

                <ProtectedRoute
                  exact
                  path="/surveylocation"
                  component={SurveyLocation}
                />

                <ProtectedRoute
                  exact
                  path="/addsurveylocation"
                  component={AddSurveyLocation}
                />

                <ProtectedRoute
                  exact
                  path="/editsurveylocation"
                  component={EditSurveyLocation}
                />

                <ProtectedRoute
                  exact
                  path="/plan"
                  component={AddSubscription}
                />

                <ProtectedRoute exact path="/myplan" component={Subscription} />

                <ProtectedRoute exact path="/device" component={Device} />
                <ProtectedRoute exact path="/company" component={CompanyList} />
                <ProtectedRoute
                  exact
                  path="/whitelistdevice"
                  component={WhiteListDevice}
                />
                <ProtectedRoute
                  exact
                  path="/addwhitelistdevice"
                  component={AddWhiteListDevice}
                />
                <ProtectedRoute
                  exact
                  path="/notification"
                  component={Notification}
                />

                <ProtectedRoute
                  exact
                  path="/editcompany"
                  component={EditCompany}
                />
                <ProtectedRoute exact path="/purchase" component={Purchase} />
                <ProtectedRoute
                  exact
                  path="/msgInfo"
                  component={MQTTComponent}
                />
                <ProtectedRoute exact path="/decryption" component={Decryption} />
                <Route path="/about" render={About} />
                <Route
                  path="*"
                  render={(props) => (
                    <div>
                      <div className="p-5">
                        <BackButton />
                      </div>
                      <h1 style={{ margin: "10%" }}>
                        Error 404 <br />
                        <i
                          className="fa fa-exclamation-triangle mr-3"
                          aria-hidden="true"
                        ></i>
                        Page Not Found
                      </h1>
                    </div>
                  )}
                />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

const ProtectedRoute = (props) => {
  const { component: Component, ...restProps } = props;

  const [auth, setAuth] = useState(false);
  const history = useHistory();
  const isLogin = localStorage.getItem("isLogin");

  const isAuthenticated = () => {
    setAuth(false);

    Auth.currentSession()
      .then((response) => {
        if (response.isValid()) {
          setAuth(true);
        } else {
          redirectToLogin();
        }
      })
      .catch(() => {
        redirectToLogin();
      });
  };

  const redirectToLogin = () => {
    if (isLogin) {
      localStorage.removeItem("isLogin");
      // window.location.href = "/login";
    }
    window.location.href = "/login";
    // history.push("/login");
  };

  useEffect(() => {
    isAuthenticated();
  }, []);

  return (
    <Route
      {...restProps}
      render={(props) =>
        auth && isLogin ? (
          <Component {...restProps} {...props} />
        ) : (
          <div className="p-5">
            <p>Something Went Wrong. Please Login Again</p>
            <a href="/login">Login</a>
          </div>
        )
      }
    />
  );
};

export default MainRoute;
