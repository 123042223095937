import { Logger } from "aws-amplify"
import React, { useState } from "react"
import { Box, Button, Paper } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"

import textEditFieldStyles from "../../styles/TextEditFieldStyles"

import agiStyles from "../../styles"
import cardStyles from "../../styles/CardStyles"
import measurementControlStyles from "../../styles/MeasurementControlStyles"
import { Col, Container, Row } from "react-grid-system"
import { useForm } from "react-hook-form"
import { PubSub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub";



export const defaultData = {
  
  measurementId: 1,
  filename: "default_1",
  ax: 0.0,
  ay: 0.0,
  az: 0.0,
  bx: 0.0,
  by: 0.0,
  bz: 0.0,
  mx: 0.0,
  my: 0.0,
  mz: 0.0,
  nx: 0.0,
  ny: 0.0,
  nz: 0.0,
  cycles: 2,
  measureTimeS: 0.2,
  maxError: 2.0,
  maxRepeat: 1,
  txMaxHV: 400,
  txMaxCurrentmA: 2000,
  commandNumber: 1,
  abmnConfig: "1A2B3M4N",
  ipWindowCount: 0
}

const MeasurementSettings = props => {
  // eslint-disable-next-line react/prop-types
  

  const styles = measurementControlStyles()
  const stylesAGI = agiStyles()
  const stylesCard = cardStyles()
  const stylesEditText = textEditFieldStyles()

  let topicToPublish = "devices/sn01231BF5A00ED84A01/commands";
  let topicToSubscribe = "devices/sn01231BF5A00ED84A01/responses";
  let payload =
  {
    "header": {
    "type": "startMeasurement",
    "apiVersion": 1.0,
    "datetime": "2021-11-11T15:26:06.076Z",
    "source": {
      "id": "webApp",
      "serialNumber": "0123456789"
    },
    "target": {
      "id": "MMReceiver",
      "serialNumber": "DFA01"
    }
    },
    "payload":{}
   
  }

  const measurementTypeOptions = [
    {
      key: "res",
      value: "res",
      label: "Res"
    },
    {
      key: "resIp",
      value: "resIp",
      label: "Res-IP"
    },
    {
      key: "sp",
      value: "sp",
      label: "SP"
    }
  ]

  const tenCount = [
    {
      key: 1,
      value: 1,
      label: "1"
    },
    {
      key: 2,
      value: 2,
      label: "2"
    },
    {
      key: 3,
      value: 3,
      label: "3"
    },
    {
      key: 4,
      value: 4,
      label: "4"
    },
    {
      key: 5,
      value: 5,
      label: "5"
    },
    {
      key: 6,
      value: 6,
      label: "6"
    },
    {
      key: 7,
      value: 7,
      label: "7"
    },
    {
      key: 8,
      value: 8,
      label: "8"
    },
    {
      key: 9,
      value: 9,
      label: "9"
    },
    {
      key: 10,
      value: 10,
      label: "10"
    }
  ]

  const measureTimeResSp = [
    {
      key: 0.2,
      value: 0.2,
      label: "0.2"
    },
    {
      key: 0.4,
      value: 0.4,
      label: "0.4"
    },
    {
      key: 0.8,
      value: 0.8,
      label: "0.8"
    },
    {
      key: 1.2,
      value: 1.2,
      label: "1.2"
    },
    {
      key: 3.6,
      value: 3.6,
      label: "3.6"
    },
    {
      key: 7.2,
      value: 7.2,
      label: "7.2"
    }
  ]

  const measureTimeResIp = [
    {
      key: 0.5,
      value: 0.5,
      label: "0.5"
    },
    {
      key: 1.0,
      value: 1.0,
      label: "1.0"
    },
    {
      key: 2.0,
      value: 2.0,
      label: "2.0"
    },
    {
      key: 4.0,
      value: 4.0,
      label: "4.0"
    },
    {
      key: 8.0,
      value: 8.0,
      label: "8.0"
    }
  ]

  const { setValue, handleSubmit, register, errors } = useForm({
    defaultValues: defaultData
  })

  const [measurementType, setMeasurementType] = useState("res")
  const [measureTimePlaceholder, setMeasureTimePlaceholder] = useState("0.2")
  const [msg, setMsg] = useState({});
  const [messages, setMessages] = useState([]);

  const onSubmit = async (data) => {
    props.getSettings(data)
    payload.payload = data
    PubSub.publish(topicToPublish , payload);
  }



  return (
    <Card className={stylesCard.card}>
      <Paper className={stylesCard.header}>
        
        <Typography
          className={stylesCard.headerText}
          variant="h5"
          component="h2"
          align="left"
        >
          Measurement Control Center - Advanced Settings
        </Typography>
      </Paper>

      <Box border={1}>
        <Container className={styles.paddingTop15}>
          <Box className={styles.paddingBottom15}>
            <Row>
              <Col>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={5}>
                      <Typography align="left" variant="body2" noWrap>
                        Measurement Type
                      </Typography>
                    </Col>
                    <Col md={5}>
                      {/* <ThingSelect
                        {...register("measurementType")}
                        options={measurementTypeOptions}
                        placeholder="Res"
                        onChange={e => {
                          setValue("measurementType", e.value)
                          setMeasurementType(e.value)
                          if (e.value === "resIp") {
                            setMeasureTimePlaceholder("0.5")
                            setValue("measureTimeS", 0.5)
                          } else {
                            setMeasureTimePlaceholder("0.2")
                            setValue("measureTimeS", 0.2)
                          }
                        }}
                      /> */}
                    </Col>
                    <Col md={2}>
                      <Typography
                        align="left"
                        variant="body2"
                        color="textSecondary"
                        noWrap
                      ></Typography>
                    </Col>
                  </Row>
                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={5}>
                      <Typography align="left" variant="body2" noWrap>
                        Filename
                      </Typography>
                    </Col>
                    <Col md={5}>
                      <input
                        type="text"
                        name="filename"
                        defaultValue={defaultData.filename}
                        {...register("filename")}
                        onChange={e => setValue("filename", e.target.value)}
                        className={stylesEditText.container}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row justify="start" className={stylesAGI.rowItem}>
                        <Col md={3}>
                          <Typography align="left" variant="body2" noWrap>
                            AX
                          </Typography>
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            name="ax"
                            min={0}
                            defaultValue={defaultData.ax}
                            step={0.01}
                            {...register("ax")}
                            onChange={e =>
                              setValue("ax", parseFloat(e.target.value))
                            }
                            className={stylesEditText.container}
                          />
                        </Col>
                        <Col md={2}>
                          <Typography
                            align="left"
                            variant="body2"
                            color="textSecondary"
                            noWrap
                          ></Typography>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="start" className={stylesAGI.rowItem}>
                        <Col md={3}>
                          <Typography align="left" variant="body2" noWrap>
                            AY
                          </Typography>
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            name="ay"
                            min={0}
                            step={0.01}
                            defaultValue={defaultData.ay}
                            {...register("ay")}
                            onChange={e =>
                              setValue("ay", parseFloat(e.target.value))
                            }
                            className={stylesEditText.container}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="start" className={stylesAGI.rowItem}>
                        <Col md={3}>
                          <Typography align="left" variant="body2" noWrap>
                            AZ
                          </Typography>
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            name="az"
                            min={0}
                            step={0.01}
                            defaultValue={defaultData.az}
                            {...register("az")}
                            onChange={e =>
                              setValue("az", parseFloat(e.target.value))
                            }
                            className={stylesEditText.container}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row justify="start" className={stylesAGI.rowItem}>
                        <Col md={3}>
                          <Typography align="left" variant="body2" noWrap>
                            BX
                          </Typography>
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            name="bx"
                            min={0}
                            step={0.01}
                            defaultValue={defaultData.bx}
                            {...register("bx")}
                            onChange={e =>
                              setValue("bx", parseFloat(e.target.value))
                            }
                            className={stylesEditText.container}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="start" className={stylesAGI.rowItem}>
                        <Col md={3}>
                          <Typography align="left" variant="body2" noWrap>
                            BY
                          </Typography>
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            name="by"
                            min={0}
                            step={0.01}
                            defaultValue={defaultData.by}
                            {...register("by")}
                            onChange={e =>
                              setValue("by", parseFloat(e.target.value))
                            }
                            className={stylesEditText.container}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="start" className={stylesAGI.rowItem}>
                        <Col md={3}>
                          <Typography align="left" variant="body2" noWrap>
                            BZ
                          </Typography>
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            name="bz"
                            min={0}
                            step={0.01}
                            defaultValue={defaultData.bz}
                            {...register("bz")}
                            onChange={e =>
                              setValue("bz", parseFloat(e.target.value))
                            }
                            className={stylesEditText.container}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row justify="start" className={stylesAGI.rowItem}>
                        <Col md={3}>
                          <Typography align="left" variant="body2" noWrap>
                            MX
                          </Typography>
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            name="mx"
                            min={0}
                            step={0.01}
                            defaultValue={defaultData.mx}
                            {...register("mx")}
                            onChange={e =>
                              setValue("mx", parseFloat(e.target.value))
                            }
                            className={stylesEditText.container}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="start" className={stylesAGI.rowItem}>
                        <Col md={3}>
                          <Typography align="left" variant="body2" noWrap>
                            MY
                          </Typography>
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            name="my"
                            min={0}
                            step={0.01}
                            defaultValue={defaultData.my}
                            {...register("my")}
                            onChange={e =>
                              setValue("my", parseFloat(e.target.value))
                            }
                            className={stylesEditText.container}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="start" className={stylesAGI.rowItem}>
                        <Col md={3}>
                          <Typography align="left" variant="body2" noWrap>
                            MZ
                          </Typography>
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            name="mz"
                            min={0}
                            step={0.01}
                            defaultValue={defaultData.mz}
                            {...register("mz")}
                            onChange={e =>
                              setValue("mz", parseFloat(e.target.value))
                            }
                            className={stylesEditText.container}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row justify="start" className={stylesAGI.rowItem}>
                        <Col md={3}>
                          <Typography align="left" variant="body2" noWrap>
                            NX
                          </Typography>
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            name="nx"
                            min={0}
                            step={0.01}
                            defaultValue={defaultData.nx}
                            {...register("nx")}
                            onChange={e =>
                              setValue("nx", parseFloat(e.target.value))
                            }
                            className={stylesEditText.container}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="start" className={stylesAGI.rowItem}>
                        <Col md={3}>
                          <Typography align="left" variant="body2" noWrap>
                            NY
                          </Typography>
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            name="ny"
                            min={0}
                            step={0.01}
                            defaultValue={defaultData.ny}
                            {...register("ny")}
                            onChange={e =>
                              setValue("ny", parseFloat(e.target.value))
                            }
                            className={stylesEditText.container}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row justify="start" className={stylesAGI.rowItem}>
                        <Col md={3}>
                          <Typography align="left" variant="body2" noWrap>
                            NZ
                          </Typography>
                        </Col>
                        <Col md={6}>
                          <input
                            type="number"
                            name="nz"
                            min={0}
                            step={0.01}
                            defaultValue={defaultData.nz}
                            {...register("nz")}
                            onChange={e =>
                              setValue("nz", parseFloat(e.target.value))
                            }
                            className={stylesEditText.container}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={5}>
                      <Typography align="left" variant="body2" noWrap>
                        Cycle Count
                      </Typography>
                    </Col>
                    <Col md={5}>
                      {/* <ThingSelect
                        {...register("cycles")}
                        placeholder={defaultData.cycles}
                        options={tenCount}
                        onChange={e => {
                          setValue("cycles", e.value)
                        }}
                      /> */}
                    </Col>
                  </Row>

                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={5}>
                      <Typography align="left" variant="body2" noWrap>
                        Measure Time
                      </Typography>
                    </Col>
                    <Col md={5}>
                      {/* <ThingSelect
                        {...register("measureTimeS")}
                        placeholder={measureTimePlaceholder}
                        options={
                          measurementType === "resIp"
                            ? measureTimeResIp
                            : measureTimeResSp
                        }
                        onChange={e => {
                          setValue("measureTimeS", e.value)
                        }}
                      /> */}
                    </Col>
                  </Row>

                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={5}>
                      <Typography align="left" variant="body2" noWrap>
                        Max Error percentage
                      </Typography>
                    </Col>
                    <Col md={5}>
                      <input
                        type="number"
                        name="maxError"
                        min={0}
                        step={0.1}
                        max={100}
                        defaultValue={defaultData.maxError}
                        {...register("maxError")}
                        onChange={e =>
                          setValue("maxError", parseFloat(e.target.value))
                        }
                        className={stylesEditText.container}
                      />
                    </Col>
                    <Col md={2}>
                      <Typography
                        align="left"
                        variant="body2"
                        color="textSecondary"
                        noWrap
                      >
                        %
                      </Typography>
                    </Col>
                  </Row>
                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={5}>
                      <Typography align="left" variant="body2" noWrap>
                        Max Repeats
                      </Typography>
                    </Col>
                    <Col md={5}>
                      {/* <ThingSelect
                        {...register("maxRepeat")}
                        options={tenCount}
                        placeholder={defaultData.maxRepeat}
                        onChange={e => {
                          setValue("maxRepeat", e.value)
                        }}
                      /> */}
                    </Col>
                  </Row>
                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={5}>
                      <Typography align="left" variant="body2" noWrap>
                        Transmitter Max Current
                      </Typography>
                    </Col>
                    <Col md={5}>
                      <input
                        type="number"
                        name="txMaxCurrentmA"
                        min={1}
                        max={2000}
                        defaultValue={defaultData.txMaxCurrentmA}
                        {...register("txMaxCurrentmA")}
                        onChange={e =>
                          setValue("txMaxCurrentmA", parseFloat(e.target.value))
                        }
                        className={stylesEditText.container}
                      />
                    </Col>
                    <Col md={2}>
                      <Typography
                        align="left"
                        variant="body2"
                        color="textSecondary"
                        noWrap
                      >
                        mA
                      </Typography>
                    </Col>
                  </Row>
                  <Row justify="start" className={stylesAGI.rowItem}>
                    <Col md={5}>
                      <Typography align="left" variant="body2" noWrap>
                        Transmitter Max Voltage
                      </Typography>
                    </Col>
                    <Col md={5}>
                      <input
                        type="number"
                        name="txMaxHV"
                        min={4.8}
                        step={0.1}
                        max={400}
                        defaultValue={defaultData.txMaxHV}
                        {...register("txMaxHV")}
                        onChange={e =>
                          setValue("txMaxHV", parseFloat(e.target.value))
                        }
                        className={stylesEditText.container}
                      />
                    </Col>
                    <Col md={2}>
                      <Typography
                        align="left"
                        variant="body2"
                        color="textSecondary"
                        noWrap
                      >
                        V
                      </Typography>
                    </Col>
                  </Row>
                  <Row justify="end" className={stylesAGI.rowItem} />
                  <Row justify="end" className={stylesAGI.rowItem}>
                    <Col>
                      <Button
                        variant="contained"
                        size="small"
                        color="inherit"
                        className={stylesAGI.standardButton}
                        type="submit"
                      >
                        Start Measurement
                      </Button>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Box>
        </Container>
      </Box>
    </Card>
  )
}

export default MeasurementSettings
