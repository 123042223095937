import React, { useState } from "react";

const SearchBox = (props) => {
  const [searchText, setSearchText] = useState("");

  const handleSearchTextChange = (e) => {
    e.preventDefault();
    setSearchText(e.target.value);
  };
  const handleSearch = (e) => {
    e.preventDefault();
    props.onSearch(searchText);
  };
  return (
    <>
      <div className="container">
        <div className="input-group">
          <input
            type={"text"}
            className="form-control"
            placeholder="Search..."
            onChange={handleSearchTextChange}
            defaultValue={props.defaultValue}
            onKeyPress={(e) => e.key === "Enter" && handleSearch(e)}
          />
          <div className="input-group-append">
            <button
              className="input-group-text"
              type="button"
              onClick={handleSearch}
            >
              <i className="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SearchBox;
