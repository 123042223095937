import React, { useEffect, useState } from "react";
import { FaIndustry, FaUserEdit } from "react-icons/fa";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getAttribute } from "../../services/auth";
import { userProfile } from "../../services/axios";
import Spinner from "../spinner/Spinner";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Profile = () => {
  const [user, setUser] = useState({});
  const [userAttribute, setUserAttribute] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // let userData = JSON.parse(localStorage.getItem("user"));
    async function fetchUserAttribute() {
      setLoading(true);
      let data = await getAttribute();
      setUserAttribute(data);
      userProfile(data["custom:userId"], data["custom:companyId"])
        .then((profile) => {
          if (profile.data.statusCode === 200) {
            setUser(profile.data.data);
            setLoading(false);
          } else {
            toast.error(profile.data.message);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
    fetchUserAttribute();
  }, []);

  return (
    <>
      {
      loading==true ? <Spinner />: <p></p>
      } 
      <section>
        <div className="container py-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="card mb-4 shadow">
                <div className="card-body text-center mb-3">
                  <img
                    src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: 150 }}
                  />
                  <h5 className="my-3">{user.firstName}</h5>
                  <p className="text-muted mb-4">
                    {userAttribute["custom:role"] === "admin"
                      ? "Admin"
                      : userAttribute["custom:role"] === "superadmin"
                      ? "Super Admin"
                      : "Technician"}
                  </p>
                  <div className="d-flex justify-content-center mb-2">
                    <Link
                      to={{
                        pathname: "/edituser",
                        state: { ...user, updatedBy: user.id },
                      }}
                    >
                      <button type="button" className="btn btn-primary">
                        Update Profile{" "}
                        <span>
                          <FaUserEdit />
                        </span>
                      </button>
                    </Link>
                  </div>
                  {userAttribute["custom:role"] === "admin" ? (
                    <div className="d-flex justify-content-center mb-2">
                      <Link
                        to={{
                          pathname: "/editcompany",
                          state: { ...user, edit: true },
                        }}
                      >
                        <button type="button" className="btn btn-info">
                          Update Company{" "}
                          <span>
                            <FaIndustry />
                          </span>
                        </button>
                      </Link>
                    </div>
                  ) : null}
                  <Link to="/changepassword">
                    <button
                      type="button"
                      className="btn btn-outline-primary ms-1"
                    >
                      Change Password{" "}
                      <span>
                        {/* <Fa500Px /> */}
                        <i className="fa fa-key" aria-hidden="true"></i>
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card mb-4 shadow">
                <div className="card-body">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Full Name</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {user.firstName} {user.lastName}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Email</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{user.email}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Mobile</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{user.mobile}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Company Name</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">{user.name}</p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Company Address</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {user.address1}
                        {", "}
                        {user.city}
                        {", "}
                        {user.state}
                        {", "}
                        {user.country}
                        {" - "}
                        {user.zipCode}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Created At</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {new Date(user.createdAt).toString()}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="mb-0">Updated At</p>
                    </div>
                    <div className="col-sm-9">
                      <p className="text-muted mb-0">
                        {new Date(user.updatedAt).toString()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
