import React, { useState } from "react"
import { GoCheck, GoPencil, GoX } from "react-icons/go"
import textEditFieldStyles from "../../styles/TextEditFieldStyles"

/**
 * Not in edit mode:
 *  Text value
 *  Edit icon (clickable)
 * When in edit mode:
 *  TextField input
 *  Cancel button
 *  Save button
 */
const TextEditField = ({ textValue = "", setTextValue }) => {
  const styles = textEditFieldStyles()
  const [isEditing, setIsEditing] = useState(false)
  const [localValue, setLocalValue] = useState(textValue)

  const onClickEdit = () => {
    setIsEditing(true)
    setLocalValue(textValue)
  }

  const onClickCancel = () => {
    setIsEditing(false)
  }

  const onClickSave = () => {
    setIsEditing(false)
    if (setTextValue) {
      setTextValue(localValue)
    }
  }

  const onChangeTextValue = e => {
    setLocalValue(e.target.value)
  }

  return (
    <div className={styles.container}>
      {!isEditing && (
        <>
          <input
            type="text"
            className={styles.textContainer}
            disabled
            value={textValue}
          />

          <div style={{ width: 20, display: "flex" }}>
            <GoPencil
              size={15}
              color={"gray"}
              className={styles.cursorPointer}
              onClick={onClickEdit}
            />
          </div>
        </>
      )}
      {isEditing && (
        <>
          <input
            type="text"
            className={styles.textContainer}
            onChange={onChangeTextValue}
            value={localValue}
            autoFocus
          />
          <GoX
            size={15}
            color={"red"}
            className={styles.cursorPointer}
            onClick={onClickCancel}
          />
          <GoCheck
            size={15}
            color={"green"}
            className={styles.cursorPointer}
            onClick={onClickSave}
          />
        </>
      )}
    </div>
  )
}

export default TextEditField
