import { createStyles, makeStyles } from "@material-ui/core/styles"
import Common from "./CommonStyles"

const cardStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexGrow: 1
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2)
    },
    form: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: theme.spacing(2),
      margin: "auto",
      maxWidth: 500
    },
    image: {
      width: "100%",
      height: "auto"
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%"
    },
    card: {
      maxWidth: 600,
      width: "100%",
      marginLeft: 50,
      marginTop: 10
    },
    header: {
      backgroundColor: Common.primary.main,
      color: Common.black,
      margin: 0,
      padding: 0,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0
    },
    headerText: {
      marginLeft: 10,
      fontSize: 15,
      paddingTop: 5,
      paddingBottom: 5
    },
    button: {
      margin: 10
    },
    media: {
      height: 0,
      margin: 10,
      paddingTop: "56.25%" // 16:9
    }
  })
)

export default cardStyles
