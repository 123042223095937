import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getAttribute } from "../../services/auth";
import { getAllChargebeePlans } from "../../services/axios";
import {
  cbInstance,
  getCheckoutHostedPage,
  initChargebee,
} from "../../services/chargebee";
import BackButton from "../back_button/back_button";
import Spinner from "../spinner/Spinner";

const AddSubscription = () => {
  const [subsPlans, setSubsPlans] = useState([]);
  const [currentSubsPlan, setCurrentSubsPlan] = useState({});
  const [addon, setAddon] = useState([]);
  const [currentAddon, setCurrentAddon] = useState({});
  const [coupons, setCoupons] = useState([]);
  const [currentCoupon, setCurrentCoupon] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const history = useHistory();

  useEffect(() => {
    async function fetchAttribute() {
      let data = await getAttribute();
      if (data["custom:role"] !== "admin") {
        history.push("/home");
        return;
      }
      setCurrentUser(data);
    }
    fetchAttribute();
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    try {
      setLoading(true);
      let data = await getAllChargebeePlans();
      console.log(data.data);
      if (data.data.statusCode === 200) {
        setSubsPlans(data.data.data.subscription);
        setAddon(data.data.data.addon);
        setCoupons(data.data.data.coupon);
      } else {
        toast.error(data.data.message);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const handleSubscriptionPlanChange = async (e) => {
    e.preventDefault();
    console.log(e.target.value);
    if (e.target.value) {
      let temp = subsPlans.find(
        (value) => value.item_price.id === e.target.value
      );
      console.log(temp);
      setCurrentSubsPlan(temp);
    } else {
      setCurrentSubsPlan({});
    }
  };

  const handleAddonPlanChange = async (e) => {
    e.preventDefault();
    console.log(e.target.value);
    if (e.target.value) {
      let temp = addon.find((value) => value.item_price.id === e.target.value);
      console.log(temp);
      setCurrentAddon(temp);
    } else {
      setCurrentAddon({});
    }
  };

  const handleCouponChange = async (e) => {
    e.preventDefault();
    console.log(e.target.value);
    if (e.target.value) {
      let temp = coupons.find((value) => value.coupon.id === e.target.value);
      console.log(temp);
      setCurrentCoupon(temp);
    } else {
      setCurrentCoupon({});
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  const grandTotal = () => {
    let total = currentSubsPlan.item_price
      ? currentAddon.item_price
        ? currentSubsPlan.item_price.price + currentAddon.item_price.price
        : currentSubsPlan.item_price.price
      : -1;
    let grand =
      total -
      total *
        (currentCoupon.coupon
          ? currentCoupon.coupon.discount_percentage / 100
          : 0);
    if (
      currentCoupon.coupon &&
      currentCoupon.coupon.discount_type === "fixed_amount"
    ) {
      grand = total - currentCoupon.coupon.discount_amount;
    }
    return grand;
  };

  const handleCheckout = async (e) => {
    e.preventDefault();
    if (!currentSubsPlan.item_price) {
      toast.info("Please Select Valid Plan");
      return;
    }
    await showCheckoutPortal(currentUser["custom:companyId"]);
  };

  const showCheckoutPortal = async (id) => {
    if (!cbInstance) {
      await initChargebee();
    }
    let plan_id = currentSubsPlan.item_price
      ? currentSubsPlan.item_price.id
      : null;
    let addons = currentAddon.item_price ? currentAddon.item_price.id : null;
    let coupon_id = currentCoupon.coupon ? currentCoupon.coupon.id : null;

    const portal = await getCheckoutHostedPage(id, plan_id, addons, coupon_id);
    console.log(portal.data);
    if (portal.data.statusCode === 200) {
      cbInstance.openCheckout({
        hostedPage: async () => portal.data.data,
        close: () => {
          console.log("Close checkout");
        },
        success(hostedPageId) {
          console.log("hostedPageId", hostedPageId);
        },
        step(step) {
          console.log("checkout", step);
        },
      });
    } else {
      toast.error(portal.data.error.error_msg);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row p-2">
          <div className="col-md-2">
            <label>Plans</label>
          </div>
          <div className="col-md-6">
            <select
              className="form-select"
              onChange={handleSubscriptionPlanChange}
            >
              <option value="">Choose Plan</option>
              {subsPlans.map((plan, index) => {
                return (
                  <option
                    key={plan.item_price.id + index}
                    value={plan.item_price.id}
                  >
                    {plan.item_price.name}
                    {" - "}
                    {plan.item_price.price} {plan.item_price.currency_code}
                    {" per "}
                    {plan.item_price.period > 1
                      ? plan.item_price.period
                      : null}{" "}
                    {plan.item_price.period_unit}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        {/* <div className="row p-2">
          <div className="col-md-2">
            <label>Add-on</label>
          </div>
          <div className="col-md-6">
            <select className="form-select" onChange={handleAddonPlanChange}>
              <option value="">Choose Addon</option>
              {addon.map((plan, index) => {
                return (
                  <option
                    key={plan.item_price.id + index}
                    value={plan.item_price.id}
                  >
                    {plan.item_price.name}
                    {" - "}
                    {plan.item_price.price} {plan.item_price.currency_code}
                    {" per "}
                    {plan.item_price.period > 1
                      ? plan.item_price.period
                      : null}{" "}
                    {plan.item_price.period_unit}
                  </option>
                );
              })}
            </select>
          </div>
        </div> */}

        <div className="row p-2">
          <div className="col-md-2">
            <label>Coupon Code</label>
          </div>
          <div className="col-md-6">
            {/* <input type="text" autoCapitalize="true" className="text" /> */}
            <select className="form-select" onChange={handleCouponChange}>
              <option value="">Choose Coupon</option>
              {coupons.map((plan, index) => {
                return (
                  <option key={plan.coupon.id + index} value={plan.coupon.id}>
                    {plan.coupon.name}
                    {/* {" - "}
                    {plan.coupon.price} {plan.coupon.currency_code}
                    {" per "}
                    {plan.coupon.period > 1 ? plan.coupon.period : null}{" "}
                    {plan.coupon.period_unit} */}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="row p-2">
          <div className="col-md-2">
            <label>Payment Info</label>
          </div>
          <div className="col-md-6">
            {currentSubsPlan.item_price
              ? `$` + currentSubsPlan.item_price.price + ` plan`
              : null}

            {currentAddon.item_price
              ? ` + $` + currentAddon.item_price.price + ` addon`
              : null}
            {currentCoupon.coupon
              ? ` - ` +
                (currentCoupon.coupon.discount_percentage
                  ? currentCoupon.coupon.discount_percentage + ` % `
                  : `$` + currentCoupon.coupon.discount_amount) +
                ` discount`
              : null}
          </div>
        </div>
        <div className="row p-2">
          <div className="col-md-2">
            <label>Grand Total</label>
          </div>
          <div className="col-md-4">
            <label>{grandTotal() >= 0 ? `$` + grandTotal() : null}</label>
          </div>
        </div>
        <div className="row p-3">
          <div className="col-md-6">
            <BackButton path="/myplan" />
          </div>
          <div className="col-md-6 text-right">
            <button className="btn btn-primary btn-sm" onClick={handleCheckout}>
              <span>
                <i className="fa fa-shopping-cart mr-2" aria-hidden="true" />
              </span>
              Checkout
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSubscription;
