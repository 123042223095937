import './App.css';
import MainRoute from './global/main.routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Amplify, Auth } from "aws-amplify";

import { AWSIoTProvider } from "@aws-amplify/pubsub";
import { MqttOverWSProvider } from "@aws-amplify/pubsub/lib/Providers";
import { PubSub } from "aws-amplify";

Amplify.configure({
  Auth: {
    userPoolId: "us-east-2_1vc4v9ObN",
    userPoolWebClientId: "4jded1mn8v09l82lkhs5dfefhb",
    identityPoolId: "us-east-2:8eaf7527-6453-4def-bd8a-f0aebd0a3a4c",
    region: "us-east-2",
  },
});
Amplify.addPluggable(
  new AWSIoTProvider({
    aws_pubsub_region: "us-east-2",
    aws_pubsub_endpoint:
      "wss:a3sye2st5vvnt-ats.iot.us-east-2.amazonaws.com/mqtt",
  })
);

Auth.currentCredentials().then((info) => {
  const cognitoIdentityId = info.identityId;
  console.log('cognitoId: ', cognitoIdentityId)
}).catch(err => {
  console.log("Error in identity pool", err);
  return null;
});


function App() {
  return (
    <div className="App wrapper">
      <ToastContainer />
      <MainRoute />
    </div>
  );
}

export default App;
