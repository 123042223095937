/* eslint-disable no-case-declarations */
import { Box, Typography } from "@material-ui/core"
import { Logger } from "aws-amplify"
import React, { useEffect } from "react"
import { Col, Container, Row } from "react-grid-system"
import measurementControlStyles from "../../styles/MeasurementControlStyles"

const MeasurementResultRes = ({ measurementData }) => {
  const measurementDataRes = measurementData
  
 
 
 

  const styles = measurementControlStyles()

  const widthCol0 = 4
  const widthCol1 = 4
  const widthCol2 = 2

  return (
    <div className="MeasurementResultRes">
      <Container className={styles.paddingTop15}>
        <Box className={styles.paddingBottom15}>
          <Row>
            <Col>
              <Row>
                <Col md={widthCol0}>
                  <Typography align="left" variant="body2">
                    Measurement ID
                  </Typography>
                </Col>
                <Col md={widthCol1}>
                  <Typography
                    align="right"
                    variant="body2"
                    color="textSecondary"
                  >
                     {/* {measurementDataRes?.payload.measurementId} */}
                    {measurementDataRes.measurementId}
                  </Typography>
                </Col>
                <Col md={widthCol2}>
                  <Typography
                    align="left"
                    variant="body2"
                    color="textSecondary"
                  ></Typography>
                </Col>
              </Row>
              <Row>
                <Col md={widthCol0}>
                  <Typography align="left" variant="body2">
                    Apparent Resistivity
                  </Typography>
                </Col>
                <Col md={widthCol1}>
                  <Typography
                    align="right"
                    variant="body2"
                    color="textSecondary"
                  >
                    {/* {measurementDataRes?.payload.appResOhmM} */}
                    {measurementDataRes.appResOhmM}
                  </Typography>
                </Col>
                <Col md={widthCol2}>
                  <Typography
                    align="left"
                    variant="body2"
                    color="textSecondary"
                    noWrap
                  >
                    Ω-m
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md={widthCol0}>
                  <Typography align="left" variant="body2">
                    Current
                  </Typography>
                </Col>
                <Col md={widthCol1}>
                  <Typography
                    align="right"
                    variant="body2"
                    color="textSecondary"
                  >
                    {/* {measurementDataRes?.payload.currentmA} */}
                    {measurementDataRes.currentmA}

                  </Typography>
                </Col>
                <Col md={widthCol2}>
                  <Typography
                    align="left"
                    variant="body2"
                    color="textSecondary"
                  >
                    mA
                  </Typography>
                </Col>
              </Row>
              <Row>
                <Col md={widthCol0}>
                  <Typography align="left" variant="body2">
                    Voltage
                  </Typography>
                </Col>
                <Col md={widthCol1}>
                  <Typography
                    align="right"
                    variant="body2"
                    color="textSecondary"
                  >
                    {/* {measurementDataRes?.payload.txVoltageV} */}
                    {measurementDataRes.txVoltageV}
                  </Typography>
                </Col>
                <Col md={widthCol2}>
                  <Typography
                    align="left"
                    variant="body2"
                    color="textSecondary"
                  >
                    V
                  </Typography>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={widthCol0}>
              <Typography align="left" variant="body2">
                Raw Resistance
              </Typography>
            </Col>
            <Col md={widthCol1}>
              <Typography align="right" variant="body2" color="textSecondary">
                {/* {measurementDataRes?.payload.rawResistanceOhm} */}
                {measurementDataRes.rawResistanceOhm}
              </Typography>
            </Col>
            <Col md={widthCol2}>
              <Typography
                align="left"
                variant="body2"
                color="textSecondary"
                noWrap
              >
                Ω-m
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col md={widthCol0}>
              <Typography align="left" variant="body2">
                Error Percentage
              </Typography>
            </Col>
            <Col md={widthCol1}>
              <Typography align="right" variant="body2" color="textSecondary">
                {/* {measurementDataRes?.payload.errorPercentage} */}
                {measurementDataRes.errorPercentage}

              </Typography>
            </Col>
            <Col md={widthCol2}>
              <Typography
                align="left"
                variant="body2"
                color="textSecondary"
                noWrap
              >
                %
              </Typography>
            </Col>
          </Row>
        </Box>
      </Container>
    </div>
  )
}

export default MeasurementResultRes
