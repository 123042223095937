import { useState,useRef} from "react";
import { Amplify, Auth } from "aws-amplify";
import { PubSub } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import { MqttOverWSProvider } from "@aws-amplify/pubsub/lib/Providers";
import { useEffect } from "react";
import { getCognitoIdentity } from "../../services/auth";
import { every } from "lodash";

// Amplify.configure({
//   Auth: {
//     userPoolId: "us-east-2_1vc4v9ObN",
//     userPoolWebClientId: "4jded1mn8v09l82lkhs5dfefhb",
//     identityPoolId: "us-east-2:8eaf7527-6453-4def-bd8a-f0aebd0a3a4c",
//     region: "us-east-2",
//   },
// });
// Amplify.addPluggable(
//   new AWSIoTProvider({
//     aws_pubsub_region: "us-east-2",
//     aws_pubsub_endpoint:
//       "wss:a3sye2st5vvnt-ats.iot.us-east-2.amazonaws.com/mqtt",
//     clientId: "us-east-2:8eaf7527-6453-4def-bd8a-f0aebd0a3a4c",
//   })
// );

const MQTTComponent = (props) => {
  const [msg, setMsg] = useState("");
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const inputRef = useRef(null);
  let topic =props.topic||"deviceinfo";

  PubSub.subscribe(topic).subscribe({
    next: (data) => setMsg(data.value.msg),
    error: (error) => console.error("Error Occured in mqtt: ", error),
    complete: () => console.log("Done"),
  });

  useEffect(() => {
    console.log("useEffect Cognito Identity: ", getCognitoIdentity());
  }, []);

  const publishMsg = async (e) => {
    e.preventDefault();
    await PubSub.publish(topic, { msg: inputText });
    inputRef.current.value = " ";
  };

  useEffect(() => {
    console.log("useEffect msg: ", msg);
    let temp = [msg, ...messages];
    console.log("Array: ", temp);
    setMessages(temp);
  }, [msg]);

  const getMsgHandler = (e) => {
    e.preventDefault();
    
    setInputText(inputRef.current.value);
  };
  
  
 
 
  

  return (
    <>
      <div className="row">
        <div className="container">
          <div className="card">
            <div className="card-header">
              <h4 className="d-flex justify-content-center">MQTT</h4>
            </div>
            <div className="card-body d-flex justify-content-center">
              
              <label id="msg">Enter Msg to Publish</label>
              <input type="text" className="ml-2" onChange={getMsgHandler} ref={inputRef}/>
              
              <button className="ml-2  btn btn-primary" onClick={ publishMsg}>
                Publish
                </button>
              
            </div>
            <div>
              <h4 className="justify-content-start">Topic - {topic}:</h4>
              <p>{messages.map((data, index) => {
                // return ({data.length === 0 ?null : <p className="mt-2 card" key={index}>{data}</p>})
                return <p className="mt-2 " key={index}>{data}</p>;
              })}</p>
            
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MQTTComponent;
