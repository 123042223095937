import React, { useEffect, useState } from "react";
import { FaUsers, FaMapMarker, FaDesktop, FaRocket, FaUnlock, FaInfoCircle, FaIndustry } from "react-icons/fa";
import { getAttribute } from "../../services/auth";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";

const SideBar = () => {
  const [userAttribute, setUserAttribute] = useState({});
  useEffect(() => {
    async function fetchUserAttribute() {
      let data = await getAttribute();
      setUserAttribute(data);
    }
    fetchUserAttribute();
  }, []);

    return (
      <div className="sidebar-wrapper">
        <div className="sidebar-header">
          {/* <Link to={"/home"}>
            <img src={"GeoStack.png"} style={{ width: "10%", background: "white", margin: "0 auto" }} alt="Logo" />
          </Link> */}
          <img src={"GeoStack.png"} style={{ width: "40%", background: "#e8ecef", margin: "0 auto", display: "block" }} alt="Logo" />
        </div>
        <Nav className="flex-column pt-2">
          {/*<p className="ml-3">Heading</p>*/}
          <Nav.Item>
            <Link to="/user" className="nav-link">
              <FaUsers className="mr-2" />
              Users
              </Link>
          </Nav.Item>
          {userAttribute["custom:role"] === "superadmin" ? (
            <Nav.Item>
              <Link to="/company" className="nav-link">
                <FaIndustry className="mr-2" />
                Company
              </Link>
            </Nav.Item>     
          ) : null}
          <Nav.Item>
            <Link to="/surveylocation" className="nav-link">
              <FaMapMarker className="mr-2" />
              Survey Locations
              </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/device" className="nav-link">
              <FaDesktop className="mr-2" />
              Devices
              </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/purchase" className="nav-link">
              <FaRocket className="mr-2" />
              Subscriptions
              </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/decryption" className="nav-link">
              <FaUnlock className="mr-2" />
              Decryption
              </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/about" className="nav-link">
              <FaInfoCircle className="mr-2" />
              About
            </Link>
          </Nav.Item>
        </Nav>
      </div>
    );
}

export default SideBar;