import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { addWhiteListDeviceAPI } from "../../services/axios";
import BackButton from "../back_button/back_button";
const AddWhiteListDevice = () => {
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [invalidArray, setInvalidArray] = useState([]);
  const [successFullDevice, setSuccessFullDevice] = useState([]);
  const fileReader = new FileReader();
  const history = useHistory();
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);
  };

  const csvFileToArray = async (string) => {
    const csvRows = string.replaceAll("\n", "").split(",");

    let validArray = csvRows.map((i) => {
      if (i.length > 0 && i.length <= 16 && i !== undefined) {
        return i;
      }
    });
    const newValidArr = validArray.filter((a) => a);
    setArray(newValidArr);
    let array1 = csvRows.map((i) => {
      if (i.length > 16) {
        return i;
      }
    });
    const newArr1 = array1.filter((a) => a);
    let response = await addWhiteListDeviceAPI(newValidArr);

    if (response.data.statusCode === 200 && response.data.data.length === 0) {
      // history.push("/whitelistdevice");
      let temp = newArr1.concat(response.data.data);
      var difference = csvRows.filter((x) => temp.indexOf(x) === -1);
      let spaceRemovedDifference = difference.filter((x) => x);

      setSuccessFullDevice(spaceRemovedDifference);
      setInvalidArray(temp);
      toast.success(response.data.message);
    } else {
      let temp = newArr1.concat(response.data.data);
      var difference = csvRows.filter((x) => temp.indexOf(x) === -1);
      let spaceRemovedDifference = difference.filter((x) => x);

      setSuccessFullDevice(spaceRemovedDifference);
      setInvalidArray(temp);

      toast.error(response.data.message);
    }
    if (response.data.statusCode === 400) {
      toast.error(response.data.message);
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (file) {
      fileReader.onload = async function (event) {
        const text = event.target.result;
        await csvFileToArray(text);
      };

      fileReader.readAsText(file);
    }
  };

  const headerKeys = Object.keys(Object.assign({}, ...array));

  return (
    <div style={{ textAlign: "center" }}>
      <h1>DEVICE CSV IMPORT </h1>
      <form>
        <input
          type={"file"}
          id={"csvFileInput"}
          accept={".csv"}
          onChange={handleOnChange}
        />

        <button
          className="btn btn-primary"
          onClick={(e) => {
            handleOnSubmit(e);
          }}
        >
          IMPORT CSV
        </button>
      </form>

      <br />
      <div className="row">
        <div className="col-6">
          <table className="justify-content-center  table table-bordered table table-striped ">
            <thead>
              <tr>
                <th>Successfull</th>
              </tr>
            </thead>

            <tbody>
              {successFullDevice.map((item) => (
                <tr>
                  <td>{item}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-6">
          <table className="justify-content-center table table-striped">
            <thead>
              <tr>
                <th>Unsuccessfull</th>
              </tr>
            </thead>

            <tbody>
              {invalidArray.map((item) => (
                <tr>
                  <td>{item}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-5 " style={{ position: "fixed", bottom: 0, left: 0 }}>
        <BackButton />
      </div>
    </div>
  );
};
export default AddWhiteListDevice;
