import { Grid, ListItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getAllChargebeePlans } from "../../services/axios";
import {
  cbInstance,
  getCheckoutHostedPage,
  initChargebee,
} from "../../services/chargebee";
import Spinner from "../spinner/Spinner";

const FirstPurchase = (props) => {
  const history = useHistory();
  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    fetchPlans();
  }, []);

  const fetchPlans = async () => {
    setLoading(true);
    let data = await getAllChargebeePlans();
    if (data.data.statusCode === 200) {
      setItems(data.data.data.subscription);
    } else {
      console.log("Error in getting plans");
    }
    setLoading(false);
  };

  const handleSubscribe = async (e, item) => {
    e.preventDefault();
    await showCheckoutPortal(props.companyId, item.item_price.id);
  };

  const showCheckoutPortal = async (id, plan_id) => {
    if (!cbInstance) {
      await initChargebee();
    }

    const portal = await getCheckoutHostedPage(id, plan_id);
    console.log(portal.data);
    if (portal.data.statusCode === 200) {
      cbInstance.openCheckout({
        hostedPage: async () => portal.data.data,
        close: () => {
          console.log("Close checkout");
        },
        success(hostedPageId) {
          console.log("hostedPageId", hostedPageId);
          toast.success("Subscription Purchased");
          toast.info("Login for continue");
          history.push("/login");
        },
        step(step) {
          console.log("checkout", step);
        },
      });
    } else {
      toast.error(portal.data.error.error_msg);
    }
  };

  const handleSkip = (e) => {
    e.preventDefault();
    history.push("/login");
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="card-header text-center bg-white">
        <h3 className="font-weight-light my-3">Purchase Plan</h3>
        <h6 className="font-weight-light mb-3 mt-n1">Select one of the plan</h6>
      </div>
      <Grid container className="mt-4">
        {items.map((item, index) => {
          return (
            <Grid item xs={6} md={4} xl={3} key={index}>
              <ListItem>
                <div className="card shadow">
                  <img
                    src="GeoStack.png"
                    className="card-img-top p-4"
                    alt="..."
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">
                      {item.item_price.external_name}
                    </h5>
                    <h6 className="card-title">${item.item_price.price}</h6>
                    <p className="card-text">{item.item_price.description}</p>
                    <button
                      className="btn btn-primary"
                      onClick={(e) => handleSubscribe(e, item)}
                    >
                      <i className="fa fa-rocket mr-2" aria-hidden="true" />
                      Subscribe
                    </button>
                  </div>
                </div>
              </ListItem>
            </Grid>
          );
        })}
      </Grid>
      <button className="btn m-4 btn-secondary" onClick={handleSkip}>
        Skip
      </button>
    </>
  );
};

export default FirstPurchase;
