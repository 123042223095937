import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from '@material-ui/core/Backdrop';
const Spinner = () => {
  return (
    <>
      <Backdrop
        style={{zIndex: 2}}
        open={true}
      >
        <CircularProgress size={80} />
      </Backdrop>
    </>
  );
};

export default Spinner;
