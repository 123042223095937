import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { confirmSignUp } from "../../services/auth";

const ConfirmUser = (props) => {
  const [code, setCode] = useState(0);
  const [email, setEmail] = useState(props.email);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be valid email.")
      .required("Email is required")
      .max(50, "No more than 50 characters"),
    code: Yup.string()
      .required("Code is required")
      .max(10, "No more than 10 Characters"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const handleCodeChange = async (e) => {
    e.preventDefault();
    setCode(e.target.value);
  };

  const handleEmailChange = async (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };
  const handleCodeSubmit = async (e) => {
    // e.preventDefault();
    if (await confirmSignUp(e.email, e.code)) {
      toast.success("Account Successfully Confirm.");
      await props.handleLogin(e.email);
      // props.setUserConfirm(false);
    } else {
      toast.error("Error in Confirming Account");
    }
  };
  return (
    <>
      <form className="p-2" onSubmit={handleSubmit(handleCodeSubmit)}>
        <div className="form-group mb-3">
          <label htmlFor="inputEmail">Email</label>
          <input
            className={`form-control ${errors.email ? "is-invalid" : ""}`}
            id="inputEmail"
            autoFocus
            type="text"
            onChange={handleEmailChange}
            defaultValue={email}
            placeholder="Email"
            {...register("email")}
          />
          <div className="invalid-feedback">{errors.email?.message}</div>
        </div>
        <div className="form-group mb-3">
          <label htmlFor="inputCode">Confirmation Code</label>
          <input
            className={`form-control ${errors.code ? "is-invalid" : ""}`}
            id="inputCode"
            type="text"
            onChange={handleCodeChange}
            placeholder="Enter code"
            {...register("code")}
          />
          <div className="invalid-feedback">{errors.code?.message}</div>
        </div>
        <div>
          <button
            style={{ background: "#228B32", width: "100%" }}
            className=" btn card-footer text-center py-3"
            // onClick={handleCodeSubmit}
            type="submit"
          >
            Verify
          </button>
        </div>
      </form>
    </>
  );
};

export default ConfirmUser;
