import { createStyles, makeStyles } from "@material-ui/core/styles";

const HereMapStyle = makeStyles((theme) =>
  createStyles({
    hereMapContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      "@media (max-width:424px)": {
        position: "relative",
        display: "block",
        width: "800px",
        marginBottom: "300px",
      },
    },
    buttonContainer: {
      paddingTop: 40,
      paddingLeft: 10,
      paddingRight: 10,
      display: "flex",
      flexDirection: "column",
    },
    addPointButton: {
      width: 80,
      height: 80,
      borderRadius: 80,
      color: "white",
      fontSize: 17,
      background: "#68d6f9",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      cursor: "pointer",
      userSelect: "none",
    },
    addLineButton: {
      marginTop: 30,
      width: 80,
      height: 80,
      borderRadius: 80,
      color: "white",
      fontSize: 17,
      background: "#68d6f9",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      cursor: "pointer",
      userSelect: "none",
    },
    selectedControlButton: {
      backgroundColor: "#929292",
    },
    controlContainer: {
      backgroundColor: "#494748",
      width: "100px",
      top: "15px",
      position: "absolute",
      right: 10,
    },
    modalContainer: {
      position: "absolute",
      zIndex: 999,
      width: "100%",
      height: "100%",
      overflow: "hidden",
      outline: 0,
      top: 0,
      opacity: 1,
      transitionProperty: "opacity",
      transitionDuration: "0.15s",
      transitionTimingFunction: "linear",
      transitionDelay: "0s",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalSubContainer: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,.2)",
      borderRadius: 18,
      outline: 0,
      padding: "30px 28px",
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        maxWidth: 500,
        width: 500,
      },
      [theme.breakpoints.up("md")]: {
        maxWidth: 700,
        width: 700,
      },
    },
    backDropContainer: {
      position: "absolute",
      width: "100%",
      height: "100%",
      opacity: 0.5,
      backgroundColor: "black",
      zIndex: 100,
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    },
    modalLabel: {
      fontSize: 15,
      lineHeight: "15px",
      textAlign: "left",
      paddingTop: 10,
      paddingBottom: "calc(0.375rem + 1px)",
      marginBottom: 0,
    },
    modalInput: {
      display: "block",
      width: "100%",
      padding: "0.375rem 0.75rem",
      fontSize: "1rem",
      lineHeight: 1.5,
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: 10,
      transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    },
    modalInputContainer: {
      display: "flex",
      alignItems: "center",
    },
    button: {
      marginTop: 0,
      "&.btn-default, &.btn-default.open, &.btn-default:hover": {
        backgroundColor: "#337ab7",
      },
    },
    btn: {
      display: "inline-block",
      padding: "0px",
      marginBottom: 0,
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "1.42857143",
      textAlign: "center",
      whiteSpace: "nowrap",
      verticalAlign: "middle",
      touchAction: "manipulation",
      cursor: "pointer",
      userSelect: "none",
      backgroundImage: "none",
      border: "0px solid transparent",
      outline: "none",
      borderRadius: "6px",
      justifyContent: "center",
    },
    btnDefault: {
      color: "#fff",
      backgroundColor: "#337ab7",
      borderColor: "#ccc",
      width: "100%",
      display: "flex",
      alignItems: "center",
      height: 35,
    },
    topInputContainer: {
      width: 450,
      backgroundColor: "white",
      position: "absolute",
      top: 15,
      right: 115,
      paddingTop: 10,
      paddingBottom: 10,
      paddingLeft: 15,
      paddingRight: 15,
    },
    topInputLabel: {
      fontSize: 12,
      paddingTop: 3,
    },
    topInput: {
      display: "block",
      width: "100%",
      padding: "1px",
      fontSize: "13px",
      lineHeight: 1.5,
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    },
    modalOrText: {
      fontSize: 17,
      lineHeight: "15px",
      textAlign: "center",
      paddingTop: 10,
      paddingBottom: "calc(0.375rem + 1px)",
      marginBottom: 15,
    },
    startButton: {
      background: "#494748 !important",
    },
    tabContainer: {
      backgroundColor: "white",
      width: "400px",
      // top: "15px",
      // position: "absolute",
      // right: 10,
      borderRadius: 20,
    },
    tabHeadContainer: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
    },
    tabHeadSubContainer: {
      fontSize: 17,
      fontWeight: 500,
      color: "#7b7b7b",
      flex: 1,
      height: 50,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "white",
      cursor: "pointer",
      userSelect: "none",
    },
    tabHeadLeftSubContainer: {
      borderTopLeftRadius: 20,
    },
    tabHeadRightSubContainer: {
      borderTopRightRadius: 20,
    },
    tabHeadSelectedSubContainer: {
      backgroundColor: "#ededed",
    },
    tabLatLngSelectContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      paddingTop: 20,
      paddingBottom: 20,
      fontSize: 16,
    },
    tabLatLngSelectTypeContainer: {
      paddingLeft: 10,
      paddingRight: 10,
      width: 100,
      textAlign: "center",
      userSelect: "none",
      cursor: "pointer",
      fontWeight: 500,
    },
    tabLatLngSelectedType: {
      textDecoration: "underline",
    },
    tabLatLngDivider: {
      height: 30,
      width: 2,
      backgroundColor: "#7b7b7b",
    },
    inputLatLngDivider: {
      height: 30,
      width: 1,
      backgroundColor: "#7b7b7b",
    },
    tableContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      flex: 1,
      borderBottomLeftRadius: 20,
      borderBottomRightRadius: 20,
    },
    tableHeader: {
      display: "flex",
      flexDirection: "row",
      height: 30,
      backgroundColor: "#e1e1e1",
      borderTop: "1px solid #b8b8b8",
      borderBottom: "1px solid #b8b8b8",
    },
    tableSmallHeader: {
      width: 40,
      borderRight: "1px solid #b8b8b8",
      fontSize: 15,
      lineHeight: "15px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    tableBigWithBorderHeader: {
      flex: 1,
      borderRight: "1px solid #b8b8b8",
      fontSize: 13,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    tableBigHeader: {
      flex: 1,
      fontSize: 13,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    tableBody: {
      display: "flex",
      flexDirection: "column",
      flex: "1 1 auto",
      height: "0px",
      overflowY: "auto",
      paddingBottom: 10,
    },
    tableBodyRow: {
      display: "flex",
      flexDirection: "row",
      height: 30,
      borderBottom: "1px solid #b8b8b8",
    },
    tableSmallTableBodyRow: {
      width: 40,
      borderRight: "1px solid #b8b8b8",
      fontSize: 15,
      lineHeight: "15px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: 30,
    },
    tableBodyBigWithBorderRow: {
      flex: 1,
      borderRight: "1px solid #b8b8b8",
      fontSize: 13,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    tableBodyBigRow: {
      flex: 1,
      fontSize: 13,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    sideBarInput: {
      maxWidth: "159px",
      height: "100%",
      paddingLeft: 5,
      border: 0,
      backgroundColor: "white",
      color: "black",
    },
    dataLineUTM: {
      maxWidth: "79px",
      fontSize: "13px",
      overflow: "hidden",
      minWidth: "79px",
    },
    lineModeTopHeader: {
      display: "flex",
      flexDirection: "row",
      height: 30,
    },
    lineModeTopSmallHeader: {
      width: 40,
      fontSize: 13,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
    },
    lineModeTopBigWithBorderHeader: {
      flex: 1,
      borderRight: "1px solid #b8b8b8",
      fontSize: 13,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      backgroundColor: "#f4f2f2",
    },
    lineModeTopBigHeader: {
      flex: 1,
      fontSize: 13,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      backgroundColor: "#f4f2f2",
    },
    truncate: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    utmValueContainer: {
      width: "100%",
      paddingLeft: 5,
      fontSize: 14,
      lineHeight: "14px",
    },
    addDeleteButton: {
      height: 24,
      zoom: "0.875",
      cursor: "pointer",
    },
    enterLocationLabel: {
      fontSize: 20,
      fontWeight: 400,
    },
    tableFullContainer: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
    },
    startEndIcon: {
      height: 15,
      marginLeft: 10,
      marginTop: -8,
    },
    fullWidth: {
      width: "100%",
    },
    cursorPointer: {
      cursor: "pointer",
      userSelect: "none",
    },
  })
);

export default HereMapStyle;
