import { createStyles, makeStyles } from "@material-ui/core/styles"

const agiStyles = makeStyles(theme =>
  createStyles({
    standardButton: {
      fontSize: 13,
      textTransform: "none",
      width: "fit-content",
      alignContent: "center",
      backgroundColor: "#c2c2c2",
      color: "white",
      borderRadius: 10
    },
    rowItem: {
      paddingBottom: 10
    }
  })
)

export default agiStyles