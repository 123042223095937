import Badge from "@material-ui/core/Badge";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import NotificationsIcon from "@material-ui/icons/Notifications";
import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getAttribute, signOut } from "../../services/auth";
import
  {
    adminNotification,
    superAdminNotification,
    technicianNotification
  } from "../../services/axios";
let packageJson = require('../../../package.json')

const Header = (props) => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationData, setNotificationData] = useState([]);
  const [countData, setCountData] = useState(null);

  const handleLogout = async (e) => {
    localStorage.removeItem("isLogin");
    await signOut();
    toast.success("Successfully Logout.");
    window.location.reload(true);
    window.location.href = "/";
  };
  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    async function fetchUserAttribute() {
      let data = await getAttribute();

      setCurrentUser(data);
      if (data["custom:role"] === "superadmin") {
        const superAdmin = await superAdminNotification();

        setNotificationData(superAdmin.data.data);
        setCountData(superAdmin.data.count[0].count);
      }
      if (data["custom:role"] === "admin") {
        const admin = await adminNotification(data["custom:companyId"]);

        setNotificationData(admin.data.data);
        setCountData(admin.data.count[0].count);
      }
      if (data["custom:role"] === "technician") {
        const technician = await technicianNotification(
          data["custom:companyId"]
        );
        setNotificationData(technician.data.data);
        setCountData(technician.data.count[0].count);
      }
    }
    fetchUserAttribute();
  }, []);

  return (
    <>
      <div>
        <div className="row">
          <div className="col-md-1">
            <FaBars onClick={props.onClick} color="black" />
            <Link to={"/home"}>
              <img src={"GeoStack.png"} style={{ width: "60%"}} alt="Logo" />
            </Link>
          </div>
          <div className="col">
          <h5 style={{ paddingTop: "10px" }}>GeoStack Electronic Imaging App</h5>
          <h7>v{packageJson.version} {process.env.NODE_ENV === "production" ? "" : process.env.NODE_ENV}</h7>
          </div>
          <div className="col-md-1 text-right mt-3">
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Badge badgeContent={countData} color="secondary">
                <NotificationsIcon />
              </Badge>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {notificationData.map((notification) => {
                return (
                  <MenuItem onClick={handleClose}>
                    {notification.title}
                  </MenuItem>
                );
              })}
            </Menu>

            {/* </Stack> */}
            {/* <IconButton
              aria-label="show 11 new notifications"
              color="inherit"
              style={{ marginLeft: "780px" }}
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? "composition-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <Badge badgeContent={11} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
          </div>

          <div className="col-md-1 text-right mt-2">
            <div className="dropdown">
              <button
                className="btn dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src="https://github.com/favicon.ico"
                  className="rounded-circle"
                />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a className="dropdown-item" href="/profile">
                    My Profile
                    <span className="ml-2">
                      <i className="fa fa-user" aria-hidden="true" />
                    </span>
                  </a>
                </li>
                <li>
                  <button
                    className="btn btn-primary btn-sm mt-2 dropdown-item"
                    onClick={handleLogout}
                  >
                    Logout
                    <span className="ml-2">
                      <i className="fa fa-sign-out" aria-hidden="true" />
                    </span>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
