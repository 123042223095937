import { TableSortLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { FaTrash } from "react-icons/fa";
import Pagination from "react-responsive-pagination";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getAttribute } from "../../services/auth";
import {
  deleteWhiteListDeviceAPI,
  whiteListDeviceListAPI,
} from "../../services/axios";
import SearchBox from "../search_box/SearchBar";
import Spinner from "../spinner/Spinner";
const WhiteListDevice = () => {
  const [deviceData, setDeviceData] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalDevice, setTotalDevice] = useState(0);
  const [searchString, setSearchString] = useState("");
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("createdAt");
  const [direction, setDirection] = useState("asc");
  const [tableSort, setTableSort] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    async function fetchUserAttribute() {
      setLoading(true);
      let data = await getAttribute();

      setCurrentUser(data);
      let device = await whiteListDeviceListAPI(
        currentPage,
        searchString,
        sortBy,
        orderBy
      );

      // console.log("deviceStatus: ", device.data.statusCode);
      if (device.data.statusCode === 200) {
        setDeviceData(device.data.data.deviceList);
        setTotalDevice(device.data.data.count);
        setTotalPage(Math.ceil(device.data.data.count / 10));
      } else {
        toast.error(device.data.message);
      }
      setLoading(false);
    }
    fetchUserAttribute();
  }, [currentPage]);

  const handleDeleteDevice = async (srNo) => {
    setLoading(true);
    let res = await deleteWhiteListDeviceAPI(srNo);
    if (res.data.statusCode === 200) {
      let temp = deviceData.filter((device) => device.srNo !== srNo);
      setDeviceData(temp);
      setTotalDevice(totalDevice - 1);
      if (temp.length === 0) {
        setCurrentPage(currentPage - 1);
        // setTotalPage(totalPage - 1);
      }
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
    setLoading(false);
  };
  const showConfirmationDialogue = (srNo) => {
    confirmAlert({
      title: "Remove Device",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteDevice(srNo),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const fetchDevice = async (query, sortBy, orderBy) => {
    setLoading(true);
    let device = await whiteListDeviceListAPI(
      currentPage,
      query,
      sortBy,
      orderBy
    );

    if (device.data.statusCode === 200) {
      setDeviceData(device.data.data.deviceList);
      setTotalDevice(device.data.data.count);
      setTotalPage(Math.ceil(device.data.data.count / 10));
    } else {
      toast.error(device.data.message);
    }
    setLoading(false);
  };
  const handleSearch = async (text) => {
    setSearchString(text);
    await fetchDevice(text, sortBy, orderBy);
  };

  const handleSortByChange = async (e) => {
    e.preventDefault();
    setSortBy(e.target.value);
    await fetchDevice(searchString, e.target.value, orderBy);
  };

  const handleOrderChange = async (e) => {
    e.preventDefault();
    setOrderBy(e.target.value);
    await fetchDevice(searchString, sortBy, e.target.value);
  };
  const handleSort = async (name, e) => {
    e.preventDefault();
    // let temp = direction === "asc" && tableSort === name ? "desc" : "asc";
    // setDirection(temp);
    // setTableSort(name);
    let temp = orderBy === "asc" && sortBy === name ? "desc" : "asc";
    setSortBy(name);
    setOrderBy(temp);
    // console.log(name + ":" + temp);
    await fetchDevice(searchString, name, temp);
  };

  return (
    <>
      {
      loading==true ? <Spinner />: <p></p>
      } 
      <div className="container">
        <div className="mt-2 mb-2 text-right">
          <Link to="/addwhitelistdevice">
            <button type="button" className="btn btn-info">
              <span className="mr-2">
                <i className="fa fa-plus" aria-hidden="true" />
              </span>
              Add Whitelist Device
            </button>
          </Link>
        </div>
        <div className="row mb-3 mt-1 ml-1">
          <div className="col-4">
            <div className="input-group">
              <div className="input-group-append">
                <label className="input-group-text">Sort By</label>
              </div>
              <select
                className="form-control"
                defaultValue={sortBy}
                onChange={handleSortByChange}
              >
                <option value="srNo">Device</option>
                <option value="used"> Provisioned</option>
                <option value="createdAt">CreatedAt</option>
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="input-group">
              <div className="input-group-append">
                <label className="input-group-text">Order</label>
              </div>
              <select
                className="form-control"
                defaultValue={orderBy}
                onChange={handleOrderChange}
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>
          <div className="col-5">
            <SearchBox defaultValue={searchString} onSearch={handleSearch} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex bd-highlight mb-n4">
                  <div className="mr-auto p-2">
                    <h5 className="card-title text-uppercase mb-0">
                      WhiteList
                    </h5>
                  </div>
                  <div className="p-2 bd-highlight">
                    <label>
                      Total : <b>{totalDevice}</b>
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="table-responsive">
                <table className="table table-striped no-wrap user-table mb-0">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium pl-4"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Device
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "srNo"}
                          onClick={(e) => handleSort("srNo", e)}
                        />
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Provisioned
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "used"}
                          onClick={(e) => handleSort("used", e)}
                        />
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Manage
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {deviceData.map((device, index) => {
                      return (
                        <tr key={index}>
                          <td className="pl-4">
                            {index + 1 + 10 * (currentPage - 1)}
                          </td>
                          <td>
                            <h6 className="font-medium mb-0">{device.srNo}</h6>
                          </td>
                          <td>
                            {device.used === false ? (
                              <span className="text-danger text-center ml-5">
                                No
                              </span>
                            ) : (
                              <span className="text-success text-center ml-5">
                                Yes
                              </span>
                            )}
                          </td>
                          <td>
                            <Link
                              to={{
                                pathname: "/editsurveylocation",
                                state: {
                                  deviceData: deviceData,
                                },
                              }}
                            >
                              {/* <button type="button" className="btn btn-circle">
                                <FaUserEdit className="" color="grey" />
                              </button> */}
                            </Link>
                            {device.used === false ? (
                              <button
                                type="button"
                                className="btn btn-circle ml-2"
                                onClick={(e) =>
                                  showConfirmationDialogue(device.srNo)
                                }
                              >
                                <FaTrash className="" color="grey" />
                              </button>
                            ) : (
                              <span
                                // style={{ marginLeft: "25px" }}
                                className="text-right ml-4"
                              >
                                -
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div style={{ marginLeft: "1070px", marginTop: "20px" }}>
                  <Pagination
                    current={currentPage}
                    total={totalPage}
                    onPageChange={setCurrentPage}
                    maxWidth={5}
                    extraClassName="justify-content-start"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhiteListDevice;
