import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getDeviceList } from "../../services/axios";
import Spinner from "../spinner/Spinner";

const Device = () => {
  const data = [
    {
      id: "akhahfalkhfadalkf",
      serialNumber: "abcd1",
      rpiSerialNumber: "efgh1234",
      esp32SerialNumber: "ijk1234",
      boardPartNumber: "lmn123",
      boardVersion: "v2",
      manufacturingDate: "01-01-2022",
      deviceType: "MM",
      apiVersion: "2",
      firmwareVersion: "12.34",
      createdAt: new Date().toISOString(),
      createdBy: "sdfksjsfsfs",
      updatedAt: new Date().toISOString(),
      updatedBy: "asfjfajfaf",
    },
    {
      id: "asgshsfalkhfadalkf",
      serialNumber: "abcd1234",
      rpiSerialNumber: "efgh1234",
      esp32SerialNumber: "ijk1234",
      boardPartNumber: "lmn123",
      boardVersion: "v2",
      manufacturingDate: "01-01-2022",
      deviceType: "MM",
      apiVersion: "1",
      firmwareVersion: "12.34",
      createdAt: new Date().toISOString(),
      createdBy: "sdfksjsfsfs",
      updatedAt: new Date().toISOString(),
      updatedBy: "asfjfajfaf",
    },
  ];
  const [devices, setDevices] = useState([]);
  const [isLoading, setLoading] = useState(false);
  let history = useHistory();

  useEffect(() => {
    fetchDevices();
  }, []);

  const fetchDevices = async () => {
    setLoading(true);
    console.log("Fetch Devices");
    let deviceList = await getDeviceList();
    console.log("deviceList: ", deviceList.data.data);
    console.log("deviceList: ", deviceList.data.data);
    if (deviceList.data.statusCode === 200) {
      setDevices(deviceList.data.data);
      // let temp = deviceList.map((device) => {
      //   device.open = false;
      //   return device;
      // });
    } else {
      console.log(deviceList.data);
      toast.error(deviceList.data.message);
    }

    // setDevices(temp);
    setLoading(false);
  };

  const showConfirmationDialogue = (deviceId, companyId) => {
    confirmAlert({
      title: "Remove User",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleRemoveDevice(deviceId, companyId),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleRemoveDevice = async (deviceId, companyId) => {
    console.log("Remove Device");
    setLoading(true);
    let temp = devices.filter((device) => device.id !== deviceId);
    setDevices(temp);
    setLoading(false);
  };

  const handleRouteChange = (data) => {
    let path = {
      pathname: `/deviceInfo`,
      state: {
        data: data.serialNumber,
        status: data.status,
        id: data.id,
      },
    };
    console.log("path", path);
    history.push(path);
  };

  return (
    <>
      {
      isLoading==true ? <Spinner />: <p></p>
      } 
      <div className="p-2">
        <h4>Device List</h4>
        <div className="p-2">
          {devices.map((device, index) => {
            return (
              <div className="card m-3" key={index}>
                <div className="card-header">
                  <div className="row">
                    <h5
                      className="card-title col-12"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRouteChange(device);
                      }}
                    >
                      <span style={{ cursor: "pointer" }}>
                        Device Name: {device.serialNumber}
                      </span>
                      {device.status === true ? (
                        <span
                          className="text-right ml-5"
                          style={{ float: "right" }}
                        >
                          Active
                        </span>
                      ) : (
                        <span
                          className="text-right ml-5"
                          style={{ float: "right" }}
                          // style={{ marginLeft: "920px" }}
                        >
                          Inactive
                        </span>
                      )}
                      <span className="ml-4"></span>
                    </h5>
                    <div className="card-tools col-2 text-right"></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Device;
