import { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { getAttribute } from "../../services/auth";
import { updateSurveyLocationAPI } from "../../services/axios";
import BackButton from "../back_button/back_button";
import HereMap from "./HereMap";
import SurveyLocationFields from "./SurveyLocationFields";

const calculateAverageLatitude = (data) => {
  let latitude = [];
  let longitude = [];
  data.dotPointsData.forEach((element) => {
    latitude.push(element.lat);
    longitude.push(element.lng);
  });

  data.linePointsData.forEach((element) => {
    latitude.push(element.lineFirstPoint.lat);
    latitude.push(element.lineSecondPoint.lat);
    longitude.push(element.lineFirstPoint.lng);
    longitude.push(element.lineSecondPoint.lng);
  });

  return {
    lat: {
      min: Math.min.apply(null, latitude),
      max: Math.max.apply(null, latitude),
    },
    lng: {
      min: Math.min.apply(null, longitude),
      max: Math.max.apply(null, longitude),
    },
  };
};

const EditSurveyLocation = () => {
  const location = useLocation();
  const history = useHistory();
  const [isValid, setIsValid] = useState(false);
  const mapPoints = location.state.location.mapData
    ? location.state.location.mapData
    : { dotPointsData: [], linePointsData: [] };
  const avgData =
    mapPoints.dotPointsData.length || mapPoints.linePointsData.length
      ? calculateAverageLatitude(mapPoints)
      : {};
  const [formData, setFormData] = useState(location.state.location);
  const [mapData, setMapData] = useState({});

  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    async function fetchUserAttribute() {
      let data = await getAttribute();
      setCurrentUser(data);
    }
    fetchUserAttribute();
    calculateAverageLatitude(mapPoints);
  }, []);

  const handleFormData = async (data, valid) => {
    setFormData(data);
    setIsValid(valid);
  };

  const handleMapData = (data) => {
    setMapData(data);
  };

  const handleUpdateSurvey = async () => {
    if (isValid) {
      let temp = formData;
      temp.updatedBy = currentUser["custom:userId"];
      temp.mapData = mapData.mapData;
      let res = await updateSurveyLocationAPI(temp);
      if (res.data.statusCode === 200) {
        toast.success(res.data.message);
        history.push("/surveylocation");
      } else {
        toast.error(res.data.message);
      }
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <SurveyLocationFields
            data={formData}
            dataChange={handleFormData}
            setFormValid={setIsValid}
          />
        </div>
        <div className="row">
          <HereMap
            dataChange={handleMapData}
            dotPoints={mapPoints.dotPointsData}
            linePoints={mapPoints.linePointsData}
            avglat={avgData.lat}
            avglng={avgData.lng}
          />
        </div>

        <div className="row mt-3" style={{ position: "block" }}>
          <div className="col-5">
            <BackButton />
          </div>
          <div className="col text-right">
            <button
              className="btn btn-primary btn-sm"
              onClick={handleUpdateSurvey}
            >
              <FaSave className="mr-1 mb-1" />
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSurveyLocation;
