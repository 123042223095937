import { TableSortLabel } from "@material-ui/core";
import React, { useEffect, useState } from "react";
//confirmation dialogue
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { FaTrash, FaUserAlt, FaUserEdit } from "react-icons/fa";
import Pagination from "react-responsive-pagination";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../../assets/pagination.css";
import { getAttribute } from "../../services/auth";
import { getUserList, userRemove } from "../../services/axios";
import SearchBox from "../search_box/SearchBar";
import Spinner from "../spinner/Spinner";

const UserList = () => {
  const data = [
    {
      userId: "1",
      firstName: "Daniel",
      lastName: "Kristeen",
      email: "daniel@website.com",
      mobile: "999 - 444 - 555",
      createdAt: "15 Mar 1988 10: 55 AM",
      status: 0,
    },
  ];

  const itemsPerPage = 10;

  const [userlist, setUserlist] = useState(data);
  const [currentUserlist, setCurrentUserlist] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalUser, setTotalUser] = useState(1);
  const [userAttribute, setUserAttribute] = useState({});
  const [lastKey, setLastKey] = useState({});
  const [prevKeys, setPrevKeys] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [orderBy, setOrderBy] = useState("desc");
  const [sortBy, setSortBy] = useState("firstName");
  const [direction, setDirection] = useState("asc");
  const [tableSort, setTableSort] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchUserAttribute() {
      let data = await getAttribute();
      setUserAttribute(data);
    }
    fetchUserAttribute();
  }, []);

  useEffect(() => {
    fetchUsers(searchString, sortBy, orderBy);
  }, [currentPage, totalUser]);

  const handleDeleteUser = (userId, email) => {
    if (userId === userAttribute["custom:userId"]) {
      toast.error("Can not delete this user.");
      return;
    }
    setLoading(true);
    userRemove(userId, userAttribute["custom:companyId"], email).then(
      (data) => {
        if (data.data.statusCode === 200) {
          let tempUserList = currentUserlist.filter(
            (user) => user.userId !== userId
          );
          setCurrentUserlist(tempUserList);
          setTotalUser(totalUser - 1);
          if (tempUserList.length === 0) {
            setCurrentPage(currentPage - 1);
            // setTotalPage(totalPage - 1);
          }
          toast.success(data.data.message);
        } else {
          toast.error(data.data.message);
        }
      }
    );
    setLoading(false);
  };

  const showConfirmationDialogue = (userId, email) => {
    confirmAlert({
      title: "Remove User",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteUser(userId, email),
        },
        {
          label: "No",
        },
      ],
    });
  };

  const fetchUsers = async (query, sortBy, orderBy) => {
    setLoading(true);
    let userList = await getUserList(
      userAttribute["custom:companyId"],
      currentPage,
      query,
      sortBy,
      orderBy
    );
    if (userList.data.statusCode === 200) {
      setCurrentUserlist(userList.data.data.user);
      setTotalUser(userList.data.data.total);
      setTotalPage(Math.ceil(userList.data.data.total / itemsPerPage));
      setLoading(false);
    } else {
      toast.error(userList.data.message);
      setLoading(false);
    }
  };

  const handleSearch = async (text) => {
    setSearchString(text);
    await fetchUsers(text, sortBy, orderBy);
  };

  const handleSortByChange = async (e) => {
    e.preventDefault();
    setSortBy(e.target.value);
    console.log(e.target.value);
    await fetchUsers(searchString, e.target.value, orderBy);
  };

  const handleOrderChange = async (e) => {
    e.preventDefault();
    setOrderBy(e.target.value);
    console.log(e.target.value);
    await fetchUsers(searchString, sortBy, e.target.value);
  };

  const handleSort = async (name, e) => {
    e.preventDefault();
    // let temp = direction === "asc" && tableSort === name ? "desc" : "asc";
    // setDirection(temp);
    // setTableSort(name);
    let temp = orderBy === "asc" && sortBy === name ? "desc" : "asc";
    setSortBy(name);
    setOrderBy(temp);
    // console.log(name + ":" + temp);
    await fetchUsers(searchString, name, temp);
  };

  return (
    <>
      {
      loading==true ? <Spinner />: <p></p>
      } 
      <div className="container">
        <div className="row">
          {userAttribute["custom:role"] !== "technician" ? (
            <div className="mt-2 mb-2 text-right">
              <Link to="/adduser">
                <button type="button" className="btn btn-info">
                  <span className="mr-2">
                    <i className="fa fa-plus" aria-hidden="true" />
                  </span>
                  Add User
                </button>
              </Link>
            </div>
          ) : null}
        </div>
        <div className="row mb-3 mt-1 ml-1">
          <div className="col-4">
            <div className="input-group">
              <div className="input-group-append">
                <label className="input-group-text">Sort By</label>
              </div>
              <select
                className="form-control"
                defaultValue={sortBy}
                onChange={handleSortByChange}
              >
                <option value="updatedAt">Updated At</option>
                <option value="createdAt">Created At</option>
                <option value="firstName">First Name</option>
                <option value="lastName">Last Name</option>
                <option value="email">Email</option>
                <option value="status">Status</option>
                <option value="mobile">Mobile</option>
              </select>
            </div>
          </div>
          <div className="col-3">
            <div className="input-group">
              <div className="input-group-append">
                <label className="input-group-text">Order</label>
              </div>
              <select
                className="form-control"
                defaultValue={orderBy}
                onChange={handleOrderChange}
              >
                <option value="asc">Ascending</option>
                <option value="desc">Descending</option>
              </select>
            </div>
          </div>
          <div className="col-5">
            <SearchBox defaultValue={searchString} onSearch={handleSearch} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex bd-highlight mb-n4">
                  <div className="mr-auto p-2">
                    <h5 className="card-title text-uppercase mb-0">
                      {userAttribute["custom:role"] === "admin"
                        ? "Manage Users"
                        : "Users"}
                    </h5>
                  </div>
                  <div className="p-2 bd-highlight">
                    <label>
                      Total : <b>{totalUser}</b>
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="table-responsive">
                <table className="table table-striped no-wrap user-table mb-0">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium pl-4"
                      >
                        #
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Name
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "firstName"}
                          onClick={(e) => handleSort("firstName", e)}
                        />
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Contact
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "email"}
                          onClick={(e) => handleSort("email", e)}
                        />
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Role
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "role"}
                          onClick={(e) => handleSort("role", e)}
                        />
                      </th>
                      <th
                        scope="col"
                        className="border-0 text-uppercase font-medium"
                      >
                        Status
                        <TableSortLabel
                          direction={orderBy}
                          active={sortBy === "status"}
                          onClick={(e) => handleSort("status", e)}
                        />
                      </th>
                      {userAttribute["custom:role"] !== "technician" ? (
                        <th
                          scope="col"
                          className="border-0 text-uppercase font-medium"
                        >
                          Manage
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {currentUserlist.map((user, index) => {
                      return (
                        <tr key={index}>
                          <td className="pl-4">
                            {index + 1 + 10 * (currentPage - 1)}
                          </td>
                          <td>
                            <h5 className="font-medium mb-0">
                              {user.firstName} {user.lastName}
                            </h5>
                          </td>
                          <td>
                            <span className="text-muted">
                              <i
                                className="fa fa-envelope-o"
                                aria-hidden="true"
                              ></i>{" "}
                              : {user.email}
                            </span>
                            <br />
                            <span className="text-muted">
                              <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                              : {user.mobile}
                            </span>
                          </td>
                          <td>
                            <span className="text-muted">
                              {user.roleId ===
                              "ed0d540a-fbf4-11eb-9a03-0242ac130003"
                                ? "Admin"
                                : user.roleId ===
                                  "ed0d5158-fbf4-11eb-9a03-0242ac130003"
                                ? "Super Admin"
                                : "Technician"}
                            </span>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-circle btn-lg btn-circle"
                            >
                              <FaUserAlt
                                color={user.status ? "green" : "red"}
                              />
                            </button>
                          </td>

                          {userAttribute["custom:role"] !== "technician" ? (
                            <td>
                              <Link
                                to={{
                                  pathname: "/edituser",
                                  state: {
                                    ...user,
                                    updatedBy: userAttribute["custom:userId"],
                                  },
                                }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-circle"
                                >
                                  <FaUserEdit className="" color="grey" />
                                </button>
                              </Link>
                              <button
                                type="button"
                                className="btn btn-circle ml-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  showConfirmationDialogue(
                                    user.userId,
                                    user.email
                                  );
                                }}
                              >
                                <FaTrash className="" color="grey" />
                              </button>
                            </td>
                          ) : null}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div style={{marginLeft:"1070px",marginTop:"20px"}}>
                  <Pagination
                    current={currentPage}
                    total={totalPage}
                    onPageChange={setCurrentPage}
                    maxWidth={5}
                    extraClassName="justify-content-start"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserList;
