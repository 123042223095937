import { FaArrowLeft } from "react-icons/fa";
import { useHistory } from "react-router-dom";

const BackButton = (props) => {
  const history = useHistory();
  const goToPreviousPath = (e) => {
    e.preventDefault();
    if (props.path) {
      history.push(props.path);
    } else {
      history.goBack();
    }
  };
  return (
    <button
      className="btn btn-warning btn-sm"
      onClick={goToPreviousPath}
      type="button"
    >
      <FaArrowLeft className="mr-1 mb-1" color="" />
      <span>Back</span>
    </button>
  );
};

export default BackButton;
