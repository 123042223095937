import { Auth } from "aws-amplify";

Auth.configure({
  userPoolId: "us-east-2_1vc4v9ObN",
  userPoolWebClientId: "4jded1mn8v09l82lkhs5dfefhb",
});

async function signUp(email, password) {
  try {
    const { user } = await Auth.signUp({
      username: "test3",
      password: "Volansys.123",
      attributes: {
        email: "dalilihu@onekisspresave.com",
      },
    });
    console.log(user);
  } catch (error) {
    console.log("error signing up:", error);
  }
}

async function signIn(email, password) {
  try {
    await Auth.signIn(email, password);
    // console.log((await Auth.currentSession()).getAccessToken().getJwtToken())
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error: error.code,
      message: error.message,
    };
  }
}

async function confirmSignUp(username, code) {
  try {
    await Auth.confirmSignUp(username, code);
    return true;
  } catch (error) {
    console.log("error confirming sign up", error);
    return false;
  }
}

async function signOut() {
  try {
    let data = await Auth.signOut({ global: true });
    console.log("SignOut", data);
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

async function getCurrentUser() {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (error) {
    console.log("error in getting user");
    return null;
  }
}

async function getAttribute() {
  try {
    return (await Auth.currentAuthenticatedUser()).attributes;
  } catch (error) {
    console.log("error in getting user");
    return null;
  }
}

function isAuthenticated() {
  Auth.currentAuthenticatedUser()
    .then((user) => {
      console.log("Authenticated");
      return true;
    })
    .catch((err) => {
      console.log("Unauthenticated", err);
      return false;
    });
}

async function sendForgotPasswordCode(username) {
  try {
    await Auth.forgotPassword(username);
    return {
      success: true,
    };
  } catch (error) {
    console.log(error);

    return {
      success: false,
      code: error.code,
      message: error.message,
    };
  }
}

async function forgotPasswordSubmit(username, code, new_password) {
  try {
    await Auth.forgotPasswordSubmit(username, code, new_password);
    return {
      success: true,
    };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      code: error.code,
      message: error.message,
    };
  }
}

async function getAuthToken() {
  try {
    return (await Auth.currentSession()).getAccessToken().getJwtToken();
  } catch (error) {
    return null;
  }
}

async function isValidSession() {
  await Auth.currentSession()
    .then((res) => {
      // console.log(res.isValid());
      const auth = res.isValid();
      return auth;
    })
    .then((err) => {
      console.log("isValidSession Error", err);
      return false;
    });
}

async function changePassword(oldPassword, newPassword) {
  try {
    let user = await Auth.currentAuthenticatedUser();
    try {
      await Auth.changePassword(user, oldPassword, newPassword);
      return {
        success: true,
      };
    } catch (error) {
      return {
        success: false,
        code: error.code,
        message: error.message,
      };
    }
  } catch (error) {
    return {
      success: false,
      code: error.code,
      message: error.message,
    };
  }
  // Auth.currentAuthenticatedUser()
  //   .then((user) => {
  //     Auth.changePassword(user, oldPassword, newPassword)
  //       .then((data) => {
  //         return {
  //           success: true,
  //         };
  //       })
  //       .catch((error) => {

  //       });
  //   })
  //   .then((data) => console.log(data))
  //   .catch((err) => console.log(err));
}

const getCognitoIdentity = () => {
  Auth.currentCredentials().then((info) => {
    const cognitoIdentityId = info.identityId;
    console.log('cognitoId: ', cognitoIdentityId)
    return cognitoIdentityId;
  }).catch(err => {
    console.log("Error in identity pool", err);
    return null;
  });
}

export {
  signIn,
  signUp,
  confirmSignUp,
  signOut,
  getCurrentUser,
  getAttribute,
  isAuthenticated,
  sendForgotPasswordCode,
  forgotPasswordSubmit,
  changePassword,
  getAuthToken,
  isValidSession,
  getCognitoIdentity
};
